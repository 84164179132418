<template>
	<div>
		<div v-for="(item, index) in stack" :key="item.id" class="bl-snackbar" :class="{animateOut: item.animateOut, error: item.options.error, mobile: mobile}" :style="{marginBottom: (index * 60) + 'px'}" @click="remove(item)">
			<component v-if="item.options.component" :is="item.options.component" v-bind="item.options.componentProps" />
			<template v-else>
				<span class="snackbarText">{{ item.options.text }}</span>
				<BlButton v-if="item.options.actionLabel" :label="item.options.actionLabel" class="outlined dense" style="margin: -10px -11px -10px 0" @click="actionClick(item, $event)" />
			</template>
		</div>
	</div>
</template>

<script>

import { Snackbar, Variables } from 'InterfaceBundle'

export default {
	name: 'BlSnackbarContainer',
	data() {
		return {
			stack: [],
			sub: null,
			mobile: Variables.mobile
		}
	},
	created() {
		this.stack = Snackbar.stack
		this.sub = Snackbar._stackChange.subscribe(() => {
			this.stack = Snackbar.stack
			this.$forceUpdate()
		})
	},
	unmounted() {
		this.sub.unsubscribe()
	},
	methods: {
		remove(item) {
			if(item.options.clickToClose) Snackbar._remove(item.id)
		},
		actionClick(item, event) {
			event.stopPropagation()
			if(item.options.actionClick) item.options.actionClick(event)
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-snackbar {
		position: fixed;
		bottom: 20px;
		left: 20px;
		background-color: rgba(0, 0, 0, .8);
		min-width: 250px;
		transition: margin-bottom .1s;
		backdrop-filter: blur(10px);
		z-index: 2000;
		color: white;
		border-radius: var(--bl-border-radius);
		padding: 15px 20px;
		color: rgba(255, 255, 255, .9);
		font-family: Product sans;
		font-weight: bold;
		animation: bl-snackbar-animation .1s;
		display: flex;
		align-items: center;

		span.snackbarText {
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.bl-snackbar.mobile {
		bottom: 90px;
		width: calc(100% - 80px);
		max-width: calc(100% - 80px);
	}

	.bl-snackbar.error {
		color: white;
		background-color: var(--bl-error);
	}

	.bl-snackbar.animateOut {
		animation: bl-snackbar-animation-out .1s;
		opacity: 0;
	}

	@keyframes bl-snackbar-animation {
		0% {
			opacity: 0;
			transform: scale(.92);
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes bl-snackbar-animation-out {
		0% {
			opacity: 1;
			transform: scale(1);
		}
		100% {
			opacity: 0;
			transform: scale(.92);
		}
	}
</style>