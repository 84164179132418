<template>
	<div>
		<icon class="folderSmallIcon" v-if="item.type == 1">folder_open</icon>
		<img v-else-if="item.icon" :src="'https://static.mixsuite.fr/file_icons/' + item.icon + '.svg'" />
		<svg v-if="item.uploading" width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="margin: 10px 5px 10px 5px;">
			<circle cx="50" cy="50" r="30" stroke="var(--bl-surface)" stroke-width="10" fill="none"></circle>
			<circle cx="50" cy="50" r="30" stroke="var(--bl-primary)" stroke-width="10" fill="none" transform="rotate(144 50 50)">
				<animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
				<animate attributeName="stroke-dasharray" calcMode="linear" values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1" dur="1" begin="0s" repeatCount="indefinite"></animate>
			</circle>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'BlFileExplorerElementIcon',
	props: ['item']
}
</script>

<style scoped lang="scss">
.folderSmallIcon {
	display: block;
	margin: 11px;
	width: 18px;
	height: 18px;
	font-size: 14px;
	text-align: center;
	line-height: 18px;
	background-color: #767676;
	color: white;
	border-radius: 4px;
}

img {
	margin: 11px 11px 7px 11px;
	width: 18px;
	height: 18px;
	border-radius: 4px;
}
</style>