<template>
	<template v-for="(item, index) in internalList.value" :key="item.uid">
		<BlStandaloneSortableItem :index="index"><slot :item="item.val"></slot></BlStandaloneSortableItem>
	</template>
</template>

<script>
export default {
	name: 'BlStandaloneSortable',
	props: ['modelValue', 'index'],
	emits: ['update:modelValue'],
	data() {
		return {
			internalList: {
				value: [],
				dataChange: () => {}
			}
		}
	},
	watch: {
		modelValue() {
			this.setInternal()
		}
	},
	created() {
		this.setInternal()
	},
	methods: {
		setInternal() {
			if(this.internalList.value.length == this.modelValue.length) return
			this.internalList.value = []
			let i = 1
			for(let item of this.modelValue) {
				let parsed = {
					uid: i,
					position: i,
					val: item
				}
				this.internalList.value.push(parsed)
				i++
			}
		},
		swap(i1, i2) {
			const i1Position = this.internalList.value[i1].position
			this.internalList.value[i1].position = this.internalList.value[i2].position
			this.internalList.value[i2].position = i1Position
			this.internalList.value.sort((a, b) => a.position > b.position ? 1 : -1)
			this.$emit('update:modelValue', this.internalList.value.map(i => i.val))
			this.$emit('change')
		}
	},
	provide() {
		return {
			blFormCollectionValue: this.internalList,
			blStandaloneSwapCallback: this.swap
		}
	}
}
</script>

<style scoped lang="scss">
</style>