import { Registry } from 'InterfaceBundle'

/**
 * Style
 */
import './styles/list-filter-select-workflow.scss'

/**
 * Components
 */
import BlFlowForm from './components/BlFlowForm.vue'
import BlWorkflowView from './components/BlWorkflowView.vue'
import BlListFilterSelectWorkflow from './components/BlListFilterSelectWorkflow.vue'

Registry.components(BlFlowForm, BlWorkflowView, BlListFilterSelectWorkflow)

/**
 * Directives
 */

/**
 * Services
 */
import { Bridge as BridgeService } from './services/Bridge.js'
import { Flow as FlowService } from './services/Flow.js'

export const Bridge = BridgeService
export const Flow = FlowService