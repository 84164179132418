import { Registry } from 'InterfaceBundle'

/**
 * Style
 */
import './styles/model-exporter.scss'

/**
 * Components
 */
import BlModelExporter from './components/BlModelExporter.vue'
import BlModelExporterTree from './components/BlModelExporterTree.vue'
import BlListExport from './components/BlListExport.vue'
import BlModelExporterSaveModel from './components/BlModelExporterSaveModel.vue'

Registry.components(BlModelExporter, BlModelExporterTree, BlListExport, BlModelExporterSaveModel)

/**
 * Directives
 */

/**
 * Services
 */
import { ModelExporter as ModelExporterService } from './services/ModelExporter.js'

export const ModelExporter = ModelExporterService