<template>
	<div class="fileView" :style="{'background-image': previewSrc}" @click.stop="openFullscreen()" style="padding: 0;">
		<div class="previewContainer" />
		<div class="details">
			<div class="name">
				<img :src="'https://static.mixsuite.fr/file_icons/' + icon + '.svg'" />
				<b>
					{{ name }}
					<em><br />{{ size }}</em>
				</b>
			</div>
			<div class="actions">
				<button class="bl-icon-button" v-bl-tooltip="$t('file.actions.preview')" @click.stop="openFullscreen()">fullscreen</button>
				<button class="bl-icon-button" v-bl-tooltip="$t('file.actions.download')" @click.stop="download()">file_download</button>
				<button class="bl-icon-button" v-bl-tooltip="$t('file.actions.open')" @click.stop="open()">folder_open</button>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'BlFileView',
	props: ['preview', 'name', 'icon', 'id', 'size', 'embededViewerUrl', 'navigation'],
	data() {
		return {
			previewSrc: ''
		}
	},
	created() {
		if(this.preview) this.previewSrc = 'data:image/jpeg;base64,' + this.preview
		else this.previewSrc = 'https://static.mixsuite.fr/blocks/file_preview_fallback.png'
		this.previewSrc = 'url(\'' + this.previewSrc + '\')'
	},
	methods: {
		openFullscreen() {
			Dialog.custom({
				fullScreen: true,
				closeButton: false,
				component: 'BlFileViewDialog',
				componentProps: {id: this.id, name: this.name, icon: this.icon, embededViewerUrl: this.embededViewerUrl, navigation: this.navigation}
			}).then(() => {}).catch(() => {})
		},
		download() {
			Api.openWindow('file/' + this.id)
		},
		open() {
			console.log('open')
		}
	}
}
</script>

<style scoped lang="scss">
	.fileView {
		width: 200px;
		height: 140px;
		display: flex;
		flex-direction: column;
		border-radius: var(--bl-border-radius);
		overflow: hidden;
		border: 1px solid var(--bl-border);
		background-position: start;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 0;

		.previewContainer {
			flex: 1;
		}

		.details {
			display: flex;
			flex-direction: column;
			border-top: 1px solid var(--bl-border);
			color: var(--bl-legend);
			background-color: var(--bl-surface);

			.name {
				display: flex;
				align-items: flex-start;
				max-width: 100%;
				padding-right: 5px;

				img {
					margin: 7px;
					width: 18px;
					height: 18px;
				}

				b {
					padding-top: 8px;
					white-space: nowrap;
					overflow: hidden;
					flex: 1;
					text-overflow: ellipsis;
					display: block;

					em {
						display: none;
					}
				}
			}

			.actions {
				display: none;
			}
		}
	}

	div.fileView:hover {
		.previewContainer {
			display: none;
		}

		.details {
			flex: 1;
			border-top: none;

			.actions {
				align-items: flex-end;
				justify-content: flex-end;
				display: flex;
				flex: 1 100%;
			}

			.name {
				b {
					white-space: break-spaces;

					em {
						display: block;
						font-weight: normal;
						line-height: 8px;
						font-style: normal;
						font-size: 12px;
						opacity: .5;
					}
				}
			}
		}
	}
</style>