<template>
	<div class="bl-phone-field">
		<div class="flag" v-if="country" :style="{backgroundImage: 'url(\'https://api.mixsystems.fr/flagimage?country=' + country + '\')'}" />
		<a :href="'tel:' + model" v-if="model"><span class="prefix">{{ prefix }}</span>{{ number }}</a>
	</div>
</template>

<script>
export default {
	name: 'BlViewPhone',
	props: ['model', 'country'],
	data() {
		return {
			prefix: '',
			number: ''
		}
	},
	created() {
		this.setData()
	},
	methods: {
		setData() {
			if(this.model) {
				if(this.model.substr(0, 1) == '+') {
					this.prefix = this.model.split(' ')[0]
					this.number = this.model.substr(this.prefix.length)
				}
				else {
					this.number = this.model
					this.prefix = ''
				}
			}
		}
	},
	watch: {
		model() {
			this.setData()
		}
	}
}
</script>

<style scoped lang="scss">
	div {
		display: flex;
		align-items: center;
	}

	.flag {
		background-position: center;
		background-size: cover;
		border-radius: 2px;
		height: 14px;
		width: 18px;
		margin-right: 5px;
		margin-top: -1px;
	}
</style>