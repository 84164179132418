<template>
	<ul>
		<li v-for="item in items" :key="item.id">
			<div @click="$emit('open', item)">
				<icon>{{ item.opened ? 'folder_open' : 'folder' }}</icon>
				<span>{{ item.name }}</span>
				<button @click="$event.stopPropagation(); $emit('select', item);" class="bl-icon-button">drive_file_move</button>
			</div>
			<BlFileMoveDialogTree v-if="item.children && item.opened" :items="item.children" @open="$emit('open', $event)" @select="$emit('select', $event)" />
		</li>
	</ul>
</template>

<script>

export default {
	name: 'BlFileMoveDialogTree',
	props: ['items'],
	emits: ['open', 'select']
}
</script>

<style scoped lang="scss">
	ul {
		margin: 0 0 0 20px;
		padding: 0;

		li {
			list-style: none;
			padding: 0;
			margin: 0;

			> div {
				padding: 4px 6px;
				user-select: none;
				cursor: pointer;
				border-radius: var(--bl-border-radius);
				transition: background-color .2s;
				gap: 5px;
				display: flex;
				align-items: center;

				icon {
					color: var(--bl-legend);
					font-size: 16px;
				}

				span {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				button {
					font-size: 22px;
					padding: 0;
					line-height: 22px;
				}
			}

			> div:hover {
				background-color: var(--bl-background);
			}
		}
	}
</style>