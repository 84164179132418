<template>
	<BlForm name="documentsignaturerecipient" ref="form" url="internals.documentsignaturerecipient" @submitted="save()" :context="{recipient: recipient}">
		<BlFormField name="name" @change="autocomplete()"/>
		<BlFormField name="email" />
		<BlFormField name="phone" />
		<div class="bottomActions">
			<BlFormSubmit />
			<BlButton :style="{display: this.recipient ? 'block' : 'none'}" style="align-self: flex-end; margin-bottom: -20px;" :label="$t('documentsignaturerecipient.remove')" class="error dense outlined" icon="delete" @click="remove()" />
		</div>
	</BlForm>
</template>

<script>
import { Dialog } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'BlDocumentSignatureAddRecipientForm',
	props: ['recipient'],
	methods: {
		autocomplete() {
			const field = this.$refs.form.form.getChild('name')
			if(field.value && field.value in field.options.choices) {
				let req = {}
				req['context("contact"):model.get("contact").findOneByName("' + field.value + '")'] = {
					name: null,
					mobile: null,
					email: null
				}
				Api.post('/api/structure/', req).then(resp => {
					this.$refs.form.form.getChild('email').setValue(resp.email)                
					this.$refs.form.form.getChild('phone').setValue(resp.mobile)
				})
			}
		},
		save() {
			Dialog.close(true, true, {
				name: this.$refs.form.form.getChild('name').value,
				email: this.$refs.form.form.getChild('email').value,
				phone: this.$refs.form.form.getChild('phone').value ? this.$refs.form.form.getChild('phone').value : ''
			})
		},
		remove() {
			Dialog.close(true, true, {remove: true})
		}
	}
}
</script>

<style scoped lang="scss">
	.bottomActions {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 10px;
		gap: 5px;
	}
	
	.fieldsContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>