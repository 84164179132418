<template>
	<div :class="{errored: field.isErrored() && field.getTouched(), lowScore: model == 1, highScore: model == 5, dark: theme == 'dark', light: theme == 'light'}" :tabindex="tabindex">
		<label>{{ field.label }}</label>
		<input v-for="i in [1,2,3,4,5]" :key="i" :class="{active: model >= i}" type="radio" v-model="model" @change="field.setValue(model)" @blur="field.setTouched()" ref="field" :value="i" />
	</div>
</template>

<script>
import { Variables } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldScore',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			theme: Variables.theme
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped lang="scss">
div {
	label {
		background-color: var(--bl-surface);
		font-size: 10.5px;
		display: block;
		color: var(--bl-legend);
		padding-right: 4px;
		padding-left: 2px;
		margin-top: -2px;
	}

	input {
		position: relative;
		width: 22px;
		height: 20px;
	}

	input.active:after {
		color: #FBDA43;
		content: 'star';
	}

	input:after {
		position: absolute;
		top: 0;
		content: 'grade';
		font-family: 'Material Icons';
		font-size: 30px;
		text-align: center;
		margin-top: -2px;
		margin-left: -5px;
		padding-bottom: 5px;
		display: block;
		cursor: pointer;
		line-height: 20px;
		color: var(--bl-legend);
		background-color: var(--bl-surface);
		transition: all .2s;
		border-radius: 50%;
	}

	input:hover:after {
		transform: scale(1.3);
	}
}

div.errored {
	label, input:after {
		color: var(--bl-error);
	}
}

div.lowScore input.active:after {
	color: #F62;
}

div.highScore.dark input.active:after {
	text-shadow: 0 0 10px #952;
}

div.highScore.light input.active:after {
	text-shadow: 0 0 10px #FBDA43;
}
</style>