<template>
	<div style="flex: 1;">
		<div class="bl-card">
			<h1>{{ $t('navigation.pageNotFound.title') }}</h1>
			<p>{{ $t('navigation.pageNotFound.content') }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlInterfaceNotFound'
}
</script>

<style scoped lang="scss">
p {
	color: var(--bl-legend);
	text-align: center;
}
</style>