<template>
	<div v-bl-input :class="{errored: field.isErrored() && field.getTouched()}">
		<label>{{ field.label }}</label>
		<div class="inputContainer"><input type="color" :tabindex="tabindex" v-model="model" @change="field.setValue(model)" @blur="field.setTouched()" ref="field" /></div>
		<div class="color" @click="focusInput()">
			<div class="preview" :style="{backgroundColor: field.value}"><icon>palette</icon></div>
			<span>{{ field.value }}</span>
		</div>
		<button class="bl-icon-button suffix" type="button" @click="clear()" v-if="!field.options.required && field.value">cancel</button>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldColor',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	},
	methods: {
		focusInput() {
			this.$refs.field.click()
			this.field.setTouched()
		},
		clear() {
			this.model = null
			this.field.setValue(null)
		}
	}
}
</script>

<style scoped lang="scss">
.inputContainer {
	opacity: 1;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

div.bl-input {
	width: calc(100% - 1px);
	margin-top: 4px;
	height: 28px;
	border: 1px solid var(--bl-border);
	border-radius: var(--bl-border-radius);

	label {
		margin: -12px 0 15px 5px;
	}

	button.suffix.bl-icon-button {
		font-size: 20px;
		padding: 0;
		margin-top: 5px;
		margin-right: 7px;
	}
}

div.color {
	display: flex;
	cursor: pointer;
	align-items: center center;
	margin-top: 3px;

	.preview {
		border-right: 1px solid var(--bl-border);
		height: 33px;
		margin-top: -9px;
		margin-right: 5px;
		border-top-left-radius: var(--bl-border-radius);
		border-bottom-left-radius: var(--bl-border-radius);
		padding: 0 10px;
		transition: background-color .2s;

		icon {
			user-select: none;
			line-height: 36px;
			font-size: 20px;
			color: var(--bl-legend);
		}
	}

	span {
		line-height: 18px;
	}
}
</style>