<template>
	<div class="windowContainer">
		<div class="backdrop" v-if="fullscreen"></div>
		<div v-for="win in windows" :key="win.id" :class="{fullscreen: win.fullscreen}">
			<component :is="win.component" v-bind="win.properties" />
		</div>
	</div>
</template>

<script>
import { WindowManager } from 'InterfaceBundle'

export default {
	name: 'InterfaceWindows',
	data() {
		return {
			windows: WindowManager.windows,
			sub: null,
			fullscreen: false
		}
	},
	created() {
		this.sub = WindowManager.change.subscribe(() => {
			this.windows = WindowManager.windows
			this.fullscreen = this.windows.filter(w => w.fullscreen).length > 0
			this.$forceUpdate()
		})
	},
	unmounted() {
		this.sub.unsubscribe()
	}
}
</script>

<style scoped lang="scss">
	.windowContainer {
		max-height: 100%;
		position: fixed;
		width: 100%;
		height: 100%;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		pointer-events: none;
		z-index: 5;

		.backdrop {
			position: absolute;
			z-index: 9;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .66);
			margin: 0;
		}
	}

	.windowContainer > div {
		max-height: 100%;
		pointer-events: auto;
		margin: 0 4px;
	}

	.windowContainer > div.fullscreen {
		position: absolute;
		width: calc(100% - 50px);
		left: 25px;
		height: calc(100% - 50px);
		top: 25px;
		z-index: 10;
	}
</style>