function blInputDirectiveUpdate(el, force = false) {
	let inputValue = null
	let inputEl = el.querySelector('input')
	if(!inputEl) inputEl = el.querySelector('textarea')
	if(!inputEl) {
		inputEl = el.querySelector('div[contenteditable]')
		inputValue = inputEl.innerHTML
	}
	else inputValue = inputEl.value
	if(inputValue && (!el.blInputHasValue || force)) {
		el.blInputHasValue = true
		el.classList.add('bl-input__value')
	}
	else if(!inputValue && (el.blInputHasValue || force)) {
		el.blInputHasValue = false
		el.classList.remove('bl-input__value')
	}
}

export default {
	mounted(el) {
		el.classList.add('bl-input')
		let labelEl = el.querySelector('label')
		if(labelEl) labelEl.addEventListener('click', () => el.querySelector('input, textarea, div[contenteditable]').focus())
		blInputDirectiveUpdate(el)
		if(el.querySelector('.suffix')) el.classList.add('__suffix')
		if(el.querySelector('.prefix')) el.classList.add('__prefix')
	},
	updated(el) {
		if(!el.classList.contains('bl-input')) {
			el.classList.add('bl-input')
			blInputDirectiveUpdate(el, true)
		}
		else blInputDirectiveUpdate(el)
	}
}