<template>
	<div>
		<BlSelect :data="choices" :label="field.label" v-model="search" @searchChange="searchValue($event)" @change="setFieldValue()" ref="field" @focus="onFocus()" @blur="onBlur()" :errored="field.isErrored() && field.getTouched()" :tabindex="tabindex" />
	</div>
</template>

<script>
import { MapService } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldPlace',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: null,
			search: '',
			choices: {}
		}
	},
	created() {
		this.setModelValue()
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.setModelValue())
		MapService.load().once(() => {
			let map = document.createElement('div')
			this.autoCompleteService = new window.google.maps.places.AutocompleteService(map)
			this.placesService = new window.google.maps.places.PlacesService(map)
		})
	},
	methods: {
		setFieldValue() {
			let item = this.autocompleteResults.filter(r => r.place_id == this.search)[0]
			if(item) {
				this.placesService.getDetails({placeId: item.place_id, fields: ['name', 'geometry', 'photos', 'address_components']}, (result, status) => {
					if(status == 'OK') {
						let value = {
							lat: result.geometry.location.lat(),
							lng: result.geometry.location.lng(),
							name: item.description
						}
						let locality = result.address_components.filter(c => c.types.includes('locality'))
						if(locality.length) value.city = locality[0].short_name
						if(result.photos && result.photos.length) value.photo = result.photos[0].getUrl()
						this.field.setValue(value)
					}
				})
			}
			else this.field.setValue(null)
		},
		setModelValue() {
			this.model = this.field.value
			this.search = this.field.value ? this.field.value.name : ''
			this.choices = {}
			if(this.search) this.choices[this.field.value.name] = this.field.value.name
		},
		searchValue(search) {
			if(!this.requestToken) this.generateToken()
			this.autoCompleteService.getPlacePredictions({input: search, sessionToken: this.requestToken}, (result, status) => {
				if(status == 'OK') {
					this.choices = {}
					this.autocompleteResults = result
					for(let item of this.autocompleteResults) this.choices[item.description] = item.place_id
				}
			})
		},
		generateToken() {
			this.requestToken = new window.google.maps.places.AutocompleteSessionToken()
		},
		onBlur() {
			this.requestToken = null
		},
		onFocus() {
			this.field.setTouched()
			if(!this.search.length) this.choices = {}
		}
	}
}
</script>

<style scoped lang="scss">
	:deep .bl-input .suffix {
		visibility: hidden !important;
	}
</style>