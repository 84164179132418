import { Realtime, Snackbar, Dialog, Router } from 'InterfaceBundle'

/**
 * Bridge for flow elements
 */
export const Bridge = {
	downloaderSnackbars: {},
	initialize() {
		//Snackbar element
		Realtime.listen('toast').then(data => Snackbar.open({text: data.title}))

		//Dialog element
		Realtime.listen('dialog').then(data => {
			let restoreFlow = data._restoreFlow
			delete data._restoreFlow
			Dialog.confirm(data).then(() => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {accepted: true, canceled: false}})
			}).catch(() => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {accepted: false, canceled: true}})
			})
		})

		//Custom element
		Realtime.listen('customdialog').then(data => {
			let restoreFlow = data._restoreFlow
			delete data._restoreFlow
			Dialog.custom(data).then(() => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {accepted: true, canceled: false}})
			}).catch(() => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {accepted: false, canceled: true}})
			})
		})

		//Script downloader
		Realtime.listen('scriptdownloader').then(data => {
			if(data.content) {
				const linkSource = 'data:application/pdf;base64,' + data.content.content
				const downloadLink = document.createElement('a')
				downloadLink.href = linkSource
				downloadLink.download = data.content.fileName
				downloadLink.click()
				if(this.downloaderSnackbars[data._restoreFlow]) {
					this.downloaderSnackbars[data._restoreFlow].hide()
					delete this.downloaderSnackbars[data._restoreFlow]
				}
				Realtime.emit('__flow_restoreState', {stateId: data._restoreFlow, data: {downloaded: true}})
			}
			else {
				this.downloaderSnackbars[data._restoreFlow] = Snackbar.open({
					clickToClose: false,
					autoClose: false,
					component: 'InterfaceFileSnackbar',
					componentProps: {
						name: data.fileName,
						icon: data.fileType
					}
				})
			}
		})

		Realtime.listen('router.navigate').then(data => {
			if(data.route) Router.navigate(data.route)
			Realtime.emit('__flow_restoreState', {stateId: data._restoreFlow, data: {}})
		})
	}
}

Bridge.initialize()