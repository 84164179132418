<template>
	<div class="bl-reloginform-container">
		<h1>{{ username }},</h1>
		<p class="info">{{ $t('auth.relogin.info') }}</p>
		<form @submit="formSubmit($event)">
			<BlStandaloneField ref="passwordField" type="password" label="Password" v-model="password" :required="true" />
			<div class="bl-card error" v-if="errored">{{ $t('auth.relogin.error') }}</div>
			<BlButton style="width:  100%;" :label="$t('auth.relogin.login')" class="primary" :loading="currentRequest" type="submit"></BlButton>
			<div style="text-align: right; margin-top: 30px;"><BlButton @click="logout()" :label="$t('auth.relogin.logout')" icon="logout" class="primary outlined"></BlButton></div>
		</form>
	</div>
</template>

<script>

import { Auth, Login } from 'AuthBundle'
import { Dialog, ViewServices } from 'InterfaceBundle'

export default {
	name: 'ReloginForm',
	data() {
		return {
			username: '',
			password: '',
			errored: false,
			currentRequest: false
		}
	},
	methods: {
		logout() {
			Auth.logout()
			Dialog.close(false, false)
		},
		formSubmit(event) {
			event.preventDefault()
			this.currentRequest = true
			this.errored = false
			Login.relogin(
				ViewServices.interfaceData.userPreferences.username,
				this.password,
				ViewServices.interfaceData.userPreferences.providerId
			).once(resp => {
				if(resp.valid) {
					Dialog.close(true, false)
					this.currentRequest = false
				}
				else {
					this.password = ''
					this.$refs.passwordField.$el.querySelector('input').focus()
					this.currentRequest = false
					this.errored = true
				}
			})
			return false
		}
	},
	mounted() {
		if(ViewServices?.interfaceData?.userPreferences?.name) this.username = ViewServices.interfaceData.userPreferences.name
		else this.$nextTick(() => this.logout())
		document.querySelector('.bl-dialog-backdrop').style.backdropFilter = 'blur(10px)'
		this.$refs.passwordField.$el.querySelector('input').focus()
	}
}
</script>

<style scoped lang="scss">
	h1 {
		font-family: 'Product sans';
	}

	p.info {
		color: var(--bl-legend);
	}

	.bl-card.error {
		padding-top: 0;
		padding-left: 0;
		font-weight: normal;
	}
</style>