import { ViewServices } from './ViewServices.js'

/**
 * Color generator
 */
export const Colors = {
	/**
	 * Get color for string
	 * @param  {string} string
	 */
	get(string) {
		let int = 0
		for(let part of string.split('')) int += part.charCodeAt(0)
		return this.all()[int % this.all().length]
	},
	/**
	 * Get all colors
	 */
	all() {
		return ViewServices.interfaceData.colors
	}
}

ViewServices.colors = Colors