<template>
	<div class="bl-input-multiple" :class="{errored: field.isErrored() && field.getTouched(), 'bl-input__value': model}">
		<label>{{ field.label }}</label>
		<div class="bl-input-multiple-content">
			<div class="bl-formchip val" v-for="(validator, index) in model" :key="index"><span><b>{{ index }}</b></span></div>
		</div>
		<div class="overlay" v-if="this.field.options.type" @click="openEditor()">
			<i class="material-icons">edit</i>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldModeloptionselector',
	props: ['field'],
	data() {
		return {
			model: this.field.value ? JSON.parse(this.field.value) : []
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.openEditor())
		this.field.emitter.change.subscribe(() => this.model = this.field.value ? JSON.parse(this.field.value) : [])
	},
	methods: {
		openEditor() {
			if(this.field.options.type) {
				Dialog.custom({
					component: 'BlFormFieldModeloptionselectorDialog',
					componentProps: {model: this.model, fieldOptions: this.field.options, property: 'model'},
					required: true
				}).then(resp => {
					resp = JSON.stringify(Object.assign({}, resp))
					this.field.setValue(resp)
					this.model = JSON.parse(resp)
				})
			}
		}
	}
}
</script>

<style scoped lang="scss">
.bl-formchip.val {
	padding: 3px 6px !important;

	b {
		font-weight: 500;
	}
}

.bl-input-multiple {
	position: relative;
	
	.bl-input-multiple-content {
		height: 34px;
		display: flex;
	}
}

.bl-input-multiple:hover .overlay {
	display: flex;
	animation: fadeIn .2s;
}

.overlay {
	cursor: pointer;
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--bl-primary);
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: var(--bl-border-radius);
	align-items: center;
	justify-content: center;
	color: var(--bl-on-primary);
}

@keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
</style>