<template>
	<div>
		<div style="white-space: normal;" v-html="text"></div>
		<div class="audioFiles" ref="audioFiles">
			<div v-for="key in Object.keys(getVoice())" :key="key" @click="toggle(key)">
				<icon>volume_up</icon>
				<span>{{ $t('Audio') }} {{ parseInt(key) + 1 }}</span>
				<audio @loadedmetadata="checkForDuration($event)">
					<source :src="voiceData[key]" v-if="voiceData[key]" type="audio/mpeg">
				</audio>
				<div class="timeContainer" :style="{background: 'conic-gradient(var(--bl-secondary) ' + (players[key]?.el.currentTime / players[key]?.el.duration) * 100 + '%, var(--bl-border) 0)'}">
					<div class="loading" v-if="loading[key]"><div></div></div>
					<button class="bl-icon-button" v-else>{{ players[key]?.playing ? 'pause' : 'play_arrow' }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlViewVoice',
	props: ['text', 'voice', 'instance'],
	data() {
		return {
			voiceData: {},
			loading: {},
			players: [],
			forceRefresh: null
		}
	},
	mounted() {
		this.initializeAudioPlayers()
	},
	watch: {
		voice() {
			this.voiceData = {}
			this.loading = {}
			this.$nextTick(() => this.initializeAudioPlayers())
		}
	},
	methods: {
		checkForDuration(event) {
			const element = event.target
			if(element.duration === Infinity) {
				element.currentTime = 1e101
				setTimeout(() => {
					element.currentTime = 0
					this.$forceUpdate()
				}, 200)
			}
		},
		toggle(index, checkPlayer = true) {
			if(!this.voiceData[index]) return this.load(index)
			//Check if another player is playing, if it is stop it
			if(checkPlayer) {
				let i = 0
				for(const player of this.players) {
					if(player.playing && i != parseInt(index)) {
						this.toggle(i, false)
						break
					}
					i++
				}
			}

			const player = this.players[index]
			if(player.playing) {
				player.el.pause()
				clearTimeout(this.forceRefresh)
			}
			else {
				player.el.play()
				this.forceRefresh = setInterval(() => {
					this.$forceUpdate()
					if(player.el.ended) this.toggle(index, false)
				}, 50)//Force update media player position
			}
			player.playing = !player.playing
		},
		initializeAudioPlayers() {
			this.players = []
			const players = this.$refs.audioFiles?.children
			if(players) {
				for(const player of players) this.players.push({el: player.querySelector('audio'), playing: false})
			}
		},
		getVoice() {
			return this.voice ? this.voice : []
		},
		load(index) {
			if(this.loading[index]) return
			this.loading[index] = true
			let req = {}
			req['context("result"):model.get("' + this.instance.model + '").findOneById(' + this.instance.id + ')'] = {
				result: 'local.result.get' + this.instance.prop + '(true, {loadIndex: ' + index + '})'
			}
			Api.post('/api/structure/', req).then(resp => {
				this.voiceData[index] = resp.result
				setTimeout(() => {
					this.loading[index] = false
					this.toggle(index)
				}, 210)//Wait for checkForDuration
			})
		}
	}
}
</script>

<style scoped lang="scss">
	.audioFiles {
		display: flex;
		flex-wrap: wrap;

		> div {
			user-select: none;
			cursor: pointer;
			display: flex;
			margin: 5px 5px 0 0;
			align-items: center;
			border: 1px solid var(--bl-border);
			border-radius: var(--bl-border-radius);
			padding: 2px 5px;

			> span {
				margin: 0 5px;
			}

			> icon {
				font-size: 20px;
			}
		}
	}

	.timeContainer {
		border-radius: 50%;
		width: 36px;
		height: 36px;
		transition: all .1s;

		> button {
			background-color: var(--bl-surface);
			margin: 4px 0 0 4px;
			width: 28px;
			padding: 0px;
		}

		> div.loading {
			border-radius: 50%;
			background-color: var(--bl-background);
			width: 28px;
			height: 28px;
			margin: 4px 0 0 4px;
			overflow: hidden;

			> div {
				border-top-right-radius: 100px;
				border-top-left-radius: 100px;
				border: 3px solid var(--bl-primary);
				box-sizing: border-box;
				border-bottom: 0;
				width: inherit;
				height: 50%;
				animation: loadingIndicator 1.5s linear infinite;
				transform-origin: 50% bottom;
			}
		}
	}

	@keyframes loadingIndicator {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
</style>