<template>
	<input type="hidden" v-model="model" @change="field.setValue(model)" />
</template>

<script>
export default {
	name: 'BlFormFieldHidden',
	props: ['field'],
	data() {
		return {
			model: this.field.value
		}
	},
	created() {
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped>
</style>