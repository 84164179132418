<template>
	<div v-bl-input :class="{errored: field.isErrored() && field.getTouched()}">
		<label>{{ field.label }}</label>
		<input type="text" v-model="model" @change="field.setValue(model)" @input="field.options.updateOnInput ? field.setValue(model) : null" @blur="field.setTouched()" ref="field" :disabled="field.options.disabled" :tabindex="tabindex" />
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldText',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped>
</style>