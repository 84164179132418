<template>
	<slot v-if="loadBody"></slot>
</template>

<script>
export default {
	name: 'BlTabBody',
	inject: ['blTabLoadBody'],
	data() {
		return {
			loadBody: false
		}
	},
	created() {
		this.blTabLoadBody.subscribe(e => this.loadBody = e)
	}
}
</script>

<style scoped lang="scss">
</style>