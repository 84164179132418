<template>
	<div class="modelSearchDialogContent">
		<div style="min-width: 70%; padding: 20px;">
			<div class="searchInputContainer" @click="$refs.input.focus()">
				<icon>search</icon>
				<input ref="input" type="text" placeholder="Search" v-model="search" />
			</div>
			<div class="searchResultsContainer">
				<ul>
					<li>
						<h5>T<b>est re</b>sult 1</h5>
						<span style="background-color: #54adf9;">Company</span>
					</li>
					<li>
						<h5>T<b>est re</b>sult 2</h5>
						<span style="background-color: #689360;">Product</span>
					</li>
					<li>
						<h5>T<b>est re</b>sult 3</h5>
						<span style="background-color: #689360;">Product</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlModelSearch',
	data() {
		return {
			search: 'est re'
		}
	},
	methods: {
		handleClick(e) {
			if(e.target.classList.contains('modelSearchDialogContainer') || e.target.classList.contains('modelSearchDialogContent')) Dialog.close(false)
		}
	},
	mounted() {
		this.$refs.input.focus()
		document.body.addEventListener('click', this.handleClick)
	},
	unmounted() {
		document.body.removeEventListener('click', this.handleClick)
	}
}
</script>

<style scoped lang="scss">
	.modelSearchDialogContent {
		display: flex;
		justify-content: center;
	}

	.searchInputContainer {
		background-color: var(--bl-surface);
		border-radius: var(--bl-border-radius);
		display: flex;
		align-items: center;
		border: 1px solid var(--bl-border);

		input {
			flex: 1;
			background-color: transparent;
			border: 0;
			font-family: 'Product sans';
			font-size: 18px;
			font-weight: 500;
			padding: 13px;
			outline: none;
		}

		input::placeholder {
			color: var(--bl-legend);
		}

		icon {
			color: var(--bl-legend);
			font-size: 26px;
			margin-left: 15px;
		}
	}

	.searchResultsContainer {
		background-color: var(--bl-surface);
		border-radius: var(--bl-border-radius);
		border: 1px solid var(--bl-border);
		margin-top: 20px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				border-bottom: 1px solid var(--bl-border);
				padding: 15px;
				cursor: pointer;
				transition: background-color .2s;

				h5 {
					font-weight: 400;
					font-family: 'Product sans';
					font-size: 16px;
					margin: 0;
					padding: 0;

					b {
						font-weight: 400;
						background-color: var(--bl-warning-bg);
					}
				}

				span {
					border-radius: var(--bl-border-radius);
					padding: 2px 5px;
					font-size: 12px;
					color: white;
					font-weight: 500;
					margin-top: 4px;
					display: inline-block;
				}
			}

			li:last-child {
				border-bottom: none;
			}

			li:hover, li.active {
				background-color: var(--bl-background);
			}
		}
	}
</style>