<template>
	<div>
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="20%" height="20%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<g transform="translate(50 50) scale(0.7000000000000001) translate(-50 -50)">
				<g transform="rotate(359.541 50.0011 50.0011)">
					<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" calcMode="spline" dur="4s" values="0 50 50;90 50 50;180 50 50;270 50 50;360 50 50" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animateTransform>
					<g transform="scale(0.996425 0.996425)">
						<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="1;1;0.5" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
						<g transform="translate(25 25)">
							<rect x="-25" y="-25" width="52" height="52" fill="#e15b64" style="background: red;">
							</rect>
						</g>
						<g transform="translate(25 75)">
							<rect x="-25" y="-25" width="52" height="50" fill="#e15b64" transform="scale(1 1)">
								<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
							</rect>
						</g>
						<g transform="translate(75 25)">
							<rect x="-25" y="-25" width="50" height="52" fill="#e15b64" transform="scale(1 1)">
								<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
							</rect>
						</g>
						<g transform="translate(75 75)">
							<rect x="-25" y="-25" width="50" height="50" fill="#e15b64" transform="scale(1 1)">
								<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
							</rect>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'InterfaceGlobalLoading'
}
</script>

<style scoped lang="scss">
div {
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--bl-surface);
	opacity: .75;
	animation: globalloading-fade-in .2s;
}

@keyframes globalloading-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: .75;
	}
}

svg rect {
	fill: var(--bl-primary);
}
</style>