<template>
	<div class="menuContainer">
		<ul>
			<li :class="{active: url == 'mobile-menu'}" @click="toggleMenu('mobile-menu')">
				<div><icon>menu</icon></div>
				<span>{{ $t('navigation.menu') }}</span>
			</li>
			<li v-for="(shortcut, shortcutName) in shortcuts" :key="shortcutName" :class="{active: url == shortcut.route}" @click="toggleMenu(shortcut.route)">
				<div><icon>{{ shortcut.icon }}</icon></div>
				<i v-if="shortcut.count"><div class="counter">{{ shortcut.count }}</div></i>
				<span>{{ $t(shortcut.label) }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { Router, Realtime, ViewServices } from 'InterfaceBundle'

export default {
	name: 'InterfaceMobileMenu',
	data() {
		return {
			url: null,
			shortcuts: ViewServices.interfaceData.shortcuts,
			rt: {},
			routeChangeListener: () => this.url = Router.currentRoute.definition.url,
			subscription : null
		}
	},
	created() {
		this.url = Router.currentRoute ? Router.currentRoute.definition.url : null
		this.subscription = Router.change.subscribe(this.routeChangeListener)
		for(let item of Object.keys(this.shortcuts)) {
			this.rt[item] = Realtime.listen('interface.' + item + 'count')
			this.rt[item].then(resp => this.shortcuts[item].count = resp)
		}
	},
	unmounted() {
		this.subscription.unsubscribe()
		for(let key of Object.keys(this.rt)) this.rt[key].unsubscribe()
	},
	methods: {
		toggleMenu(route) {
			if(this.url == route) history.back()
			else Router.navigate(route)
		}
	}
}
</script>

<style scoped lang="scss">
div.menuContainer {
	background-color: var(--bl-surface);
	border-top: 1px solid var(--bl-border);
	user-select: none;

	ul {
		list-style-type: none;
		display: flex;
		margin: 0;
		padding: 0;
		justify-content: space-around;

		li {
			position: relative;
			overflow: hidden;
			border-radius: var(--bl-border-radius);
			-webkit-tap-highlight-color: transparent;
			margin: 0;
			padding: 0;
			min-width: 80px;
			display: flex;
			user-select: none;
			flex-direction: column;
			align-items: center;
			padding: 10px;

			.counter {
				font-style: normal;
				pointer-events: none;
				background-color: var(--bl-error);
				color: var(--bl-on-primary);
				font-weight: bold;
				font-size: 13px;
				border-radius: var(--bl-border-radius);
				padding: 2px 4px;
				position: absolute;
				margin-top: -35px;
			}

			icon {
				user-select: none;
			}

			span {
				font-family: Product sans;
				font-size: 14px;
			}
		}

		li > div:not(.counter) {
			margin-bottom: 5px;
			padding: 2px 4px 0 4px;
			border-radius: var(--bl-border-radius);
			min-width: 55px;
			text-align: center;
			position: relative;
			overflow: hidden;
		}

		li.active {
			span {
				font-weight: bold;
			}

			icon {
				animation: bl-mobile-icon-in .4s linear both;
			}
		}

		li.active > div:before {
			animation: bl-mobile-menu .2s;
			content: ' ';
			background-color: var(--bl-primary);
			opacity: .3;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			animation: bl-mobile-pop-in .1s linear both;
		}

		li:before {
			content: ' ';
			background-color: var(--bl-primary);
			opacity: 0;
			transition: all .2s;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}

		li:active:before {
			opacity: .05;
		}
	}
}

@keyframes bl-mobile-pop-in {
	0% {
		border-radius: 50px;
		transform: scale(.5);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes bl-mobile-icon-in {
	0% {
		transform: rotate(-10deg);
	}
	30% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(-6deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes bl-mobile-menu {
	0% {
		opacity: 0;
		transform: scale(.92);
	}
	100% {
		opacity: 0.3;
		transform: scale(1);
	}
}
</style>