import { ViewServices } from './ViewServices.js'

export const ScriptLoader = {
	imported: {},
	importing: {},

	/**
	 * Import JS file
	 * @param  {string}             src
	 * @return {Promise}
	 */
	importJS(src) {
		let ret = new Promise(resolve => {
			if(this.importing[src]) return this.importing[src]
			else if(this.imported[src]) resolve()
			else {
				this.importing[src] = ret
				let script = document.createElement('script')
				script.onload = () => {
					delete this.importing[src]
					this.imported[src] = true
					resolve()
				}
				script.src = src
				document.head.appendChild(script)
			}
		})
		return ret
	},

	/**
	 * Destroy imported JS file
	 * @param {string} src
	 */
	destroyJS(src) {
		let items = Array.from(document.head.getElementsByTagName('SCRIPT'))
		for(let item of items) {
			if(item.getAttribute('src') == src) document.head.removeChild(item)
		}
		delete this.imported[src]
	},

	/**
	 * Import CSS file
	 * @param  {string}             src
	 * @return {Promise}
	 */
	importCSS(src) {
		let ret = new Promise(resolve => {
			if(this.importing[src]) return this.importing[src]
			else if(this.imported[src]) resolve()
			else {
				this.importing[src] = ret
				let script = document.createElement('link')
				script.onload = () => {
					delete this.importing[src]
					this.imported[src] = true
					resolve()
				}
				script.rel = 'stylesheet'
				script.type = 'text/css'
				script.href = src
				document.head.appendChild(script)
			}
		})
		return ret
	},

	/**
	 * Destroy imported CSS file
	 * @param {string} src
	 */
	destroyCSS(src) {
		let items = Array.from(document.head.getElementsByTagName('LINK'))
		for(let item of items) {
			if(item.getAttribute('href') == src) document.head.removeChild(item)
		}
		delete this.imported[src]
	}
}

ViewServices.scriptLoader = ScriptLoader