<template>
	<component v-for="(child, index) in children" :key="index" :is="child._cn" v-bind="child._cp"></component>
</template>

<script>

export default {
	name: 'BlManyToManyComponentContainer',
	props: ['children']
}
</script>

<style scoped lang="scss">
</style>