<template>
	<div>
		<div class="bl-card processContainer" v-for="process in signatureProcess" :key="process.id">
			<div class="processInfos">
				<div class="processHeader" >
					<h1 v-bl-tooltip="process.name" class="processTitle">{{ process.name }}</h1>
					<BlButton v-if="process.status === 1" :label="$t('documentsignature.download')" icon="download" classlist="outlined downloadButton dense" @click="download(process)" />
				</div>
				<div class="statusBar">
					<div class="bl-chip contractStatus" :style="{'--bl-chip-color': statusColor[process.status]}">
						<icon>{{ contractStatusIcon[process.status] }}</icon>
						<span>{{ process.statusVerbose }}</span>
					</div>
					<div class="recipientsContainer" >
						<span class="bl-chip signatureStatus" :style="{'--bl-chip-color': statusColor[recipient.status]}" v-for="recipient in process.recipients" :key="recipient.id">{{ recipient.name + ' ' + $t('documentsignaturerecipient.signature.' + this.signatureStatus[recipient.status]) }}</span>
					</div>
				</div>
				<hr v-if="process.body" style="margin-top: 6px;" />
				<span class="messageBody" v-html="process.body"></span>
			</div>
			<div class="documentPlaceHolder">
				<iframe v-if="process.status == 1 && !mobile" class="documentPreview" :src="process.iframeUrl"></iframe>
				<span v-else-if="process.status == 0" class="placeholder">{{ $t('documentsignature.noDocumentYet') }}</span>
			</div>
		</div>
		<p v-if="signatureProcess.length == 0 && ready" style="text-align: center;">{{ $t('documentsignature.noExistingProcess') }}</p>
		<div v-if="!ready" class="bl-loader-line loading"></div>
	</div>
</template>

<script>
import { Variables } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'BlDocumentSignatureStatus',
	props: ['model', 'modelId'],
	inject: ['blViewProps'],
	data() {
		return {
			mobile: Variables.mobile,
			signatureProcess: [],
			statusColor: [
				'var(--bl-legend)',
				'var(--bl-valid)',
				'var(--bl-error)'
			],
			contractStatusIcon: [
				'pending',
				'check',
				'cancel'
			],
			signatureStatus: [
				'awaiting',
				'signed',
				'refused'
			],
			ready : false
		}
	},
	mounted() {
		let req = {}
		req['context("documentsignature"):model.get("documentsignature").findByObject(model.get("' + this.blViewProps().model + '").findOneById(' + this.blViewProps().id + '))'] = {
			'loop("process"):local.documentsignature': {
				id: null,
				reference: null,
				documentName: null,
				name: null,
				status: null,
				statusVerbose: 'local.process.getStatus(true)',
				body: null,
				recipients: {
					'loop("recipient"):local.process.recipients': {
						name: null,
						status: null,						
					}
				}
			}
		}
		Api.post('api/structure/', req).then(resp => {
			this.signatureProcess = resp
			this.ready = true
			for(let process of this.signatureProcess) {
				process.iframeUrl = Api.getUrl('document/signature/document/' + process.id) + '#toolbar=0&view=FitW'
			}
		})
	},
	methods: {
		download(process) {
			Api.openWindow('document/signature/document/' + process.id, {download: true})
		}
	}
}
</script>

<style scoped lang="scss">
	hr {
		width: calc(100% - 10px);
	}

	.loading {
		width: 30%; 
		margin: auto; 
		margin-top: 100px;
	}

	.processContainer {
		display: flex;
		flex-direction: row;
		overflow: hidden;

		.processInfos {
			display: flex;
			flex-direction: column;
			gap: 5px;
			width: 40%;

			.processTitle {
				flex: 1;
				white-space: nowrap; 
				overflow: hidden; 
				text-overflow: ellipsis; 
				display: block
				}

			.statusBar {
				display: flex;
				flex-direction: row;
				gap: 5px;
			}

			.processHeader {
				display: flex;
			}
		}

		.documentPlaceHolder {
			width: 60%;
			display: flex;
			align-items: center;
			margin: -5px 0;

			.documentPreview {
				width: 100%;
				min-height: 400px;
				height: 100%;
				position: relative;
				right: -5px;
				border: 1px solid var(--bl-border);
			}

			.placeholder {
				margin: auto;
			}
		}
	}

	.bl-chip span {
		font-weight: 300;
	}

	.messageBody {
		text-align: justify;
		padding-right: 5px;

	}

	.messageBody::v-deep img {
		width: 100%;
	}

	.signatureStatus {
		display: flex;
		flex-direction: row;
	}

	.recipientsContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: start;
		gap: 5px;
	}

	.contractStatus {
		display: flex;
		align-self: flex-start;
	}
</style>