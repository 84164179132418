<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlModelSearchContainer',
	created() {
		Dialog.custom({
			component: 'BlModelSearch',
			fullScreen: true,
			closeButton: false,
			class: 'modelSearchDialogContainer'
		})
	},
	mounted() {
		this.$parent.deselect()
	}
}
</script>

<style scoped lang="scss">
</style>