<template>
	<div class="bl-card">
		<div style="display: flex; margin-bottom: 10px;">
			<div v-bl-input>
				<label>Request (JSON)</label>
				<input type="text" v-model="arguments" />
			</div>
			<BlButton style="margin-top: 3px; margin-left: 24px;" label="Refresh" @click="loadData()" :loading="currentRequest" />
		</div>
		<iframe v-if="iframeUrl" :src="iframeUrl"></iframe>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Auth } from 'AuthBundle'

export default {
	name: 'BlDocumentFormPreview',
	props: ['id', 'collectionId', 'type', 'excelPreviewUrl'],
	data() {
		return {
			iframeUrl: null,
			currentRequest: false,
			arguments: '{}'
		}
	},
	methods: {
		loadData() {
			let args = JSON.parse(this.arguments)
			if(this.type === 2) {
				args.bearer = Auth.getToken()
				this.iframeUrl = this.excelPreviewUrl.replace('{{url}}', window.location.origin + Api.getUrl('/document/preview/' + this.id, args))
			}
			else {
				this.iframeUrl = null
				this.currentRequest = true
				let url = this.id ? ('document/preview/' + this.id) : ('document/collection-preview/' + this.collectionId)
				Api.get(url, args).then(resp => {
					this.currentRequest = false
					this.iframeUrl = 'data:' + resp.data + '#toolbar=0&navpanes=0'
				}, () => this.currentRequest = false)
			}
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-card {
		display: flex;
		flex-direction: column;
	}

	iframe {
		flex: 1;
		border: 0;
		width: 100%;
		background-color: #525659;
	}
</style>