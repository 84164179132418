<template>
	<div v-bl-input :class="{errored: (field.isErrored() || invalidFormat) && field.getTouched()}" class="bl-input-time-container">
		<label>{{ field.label }}</label>
		<input ref="field" type="text" v-mask="mask" v-model="modelStr" inputmode="numeric" @change="fieldChange()" @keyup="autoCompleteHours()" @focus="selectAll()" @blur="field.setTouched()" :tabindex="tabindex" />
		<div class="placeholder" :style="{display: modelStr ? 'block' : null}" @click="$refs.field.focus()">
			<span>00</span>:<span>00</span>{{ field.options.seconds ? ':' : '' }}
		</div>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
//import dayjs from 'dayjs'

export default {
	name: 'BlFormFieldTime',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			modelStr: '',
			mask: null,
			regex: null,
			invalidFormat: false
		}
	},
	created() {
		if(this.field.options.seconds) {
			this.mask = '##:##:##'
			this.regex = new RegExp('[0-9]{2}:[0-9]{2}:[0-9]{2}')
		}
		else {
			this.mask = '##:##'
			this.regex = new RegExp('[0-9]{2}:[0-9]{2}')
		}

		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())

		this.modelStr = this.field.value
		this.field.emitter.change.subscribe(() => this.modelStr = this.field.value)
	},
	methods: {
		fieldChange() {
      this.autoCompleteChange()
			if(this.modelStr.match(this.regex)) {
				let parts = this.modelStr.split(':')
				let valid = true
				let hours = true
				for(let part of parts) {
					let max = hours ? 23 : 60
					if(parseInt(part) > max) {
						valid = false
						break
					}
					hours = false
				}
				if(valid) {
					this.invalidFormat = false
					this.field.setValue(this.modelStr)
				}
				else this.invalidFormat = true
			}
		},
    autoCompleteHours() {
			//Auto prefix hours
			if(this.modelStr && this.modelStr.length == 1 && parseInt(this.modelStr) > 2) {
				this.modelStr = '0' + this.modelStr
				setTimeout(() => this.$refs.field.setSelectionRange(2, 2))
			}
		},
    autoCompleteChange() {
      if(['1', '2'].includes(this.modelStr)) this.modelStr = '0' + this.modelStr
      if(this.modelStr.length == 1 && this.modelStr == '0') this.modelStr = '00:00'
      else if(this.modelStr.length == 2) this.modelStr += ':00'
      else if(this.modelStr.length == 3) this.modelStr += '00'
      else if(this.modelStr.length == 4) this.modelStr += '0'
		},
		selectAll() {
			this.$refs.field.select()
		}
	},
	directives: {
		mask
	}
}
</script>

<style scoped lang="scss">
	.placeholder {
		position: absolute;
		top: 13px;
		left: 8px;

		span {
			opacity: 0
		}
	}

	.bl-input:not(:focus-within) .placeholder {
		display: none;
	}
</style>
