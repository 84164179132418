export default {
	mounted(el, binding) {
		el.addEventListener('mouseenter', () => {
			el.tooltipComponent = document.createElement('DIV')
			el.tooltipComponent.innerText = binding.value
			el.tooltipComponent.classList.add('bl-tooltip-container')
			el.style.anchorName = '--tooltip-anchor-element'
			document.body.appendChild(el.tooltipComponent)
		})

		const handleLeave = () => {
			if(el.tooltipComponent && el.tooltipComponent.parentNode) {
				el.style.removeProperty('anchor-name')
				el.tooltipComponent.parentNode.removeChild(el.tooltipComponent)
				el.tooltipComponent = null
			}
		}

		el.addEventListener('mouseleave', handleLeave)
		el.addEventListener('click', handleLeave)
	},
	unmounted(el) {
		if(el.tooltipComponent) el.tooltipComponent.parentNode.removeChild(el.tooltipComponent)
	}
}