import { Auth } from 'AuthBundle'

/**
 * Native proxy management
 */
export const NativeProxy = {
	_proxy: window.nativeProxy,
	/**
	 * Check if proxy is enabled
	 */
	isEnabled() {
		return this._proxy ? true : false
	},
	/**
	 * Get device definition
	 */
	getDeviceDefinition() {
		if(!this.isEnabled()) return null
		return this._proxy.getDeviceDefinition()
	},
	/**
	 * Set theme color
	 * @param {string} color
	 */
	setThemeColor(color) {
		if(!this.isEnabled()) return
		this._proxy.setThemeColor(color)
	},
	/**
	 * Reload page
	 */
	reload() {
		if(!this.isEnabled()) return
		this._proxy.reload()
	},
	/**
	 * Synchronize authorizations
	 */
	synchronizeAuthorizations() {
		if(!this.isEnabled()) return null
		return this._proxy.synchronizeAuthorizations()
	},
	/**
	 * Initialize proxy
	 */
	_initialize() {
		Auth.events.login.subscribe(data => {
			if(data.native_token) {
				this._proxy.login(data.native_token)
				this.synchronizeAuthorizations()
			}
		})
		Auth.events.credentialsChanged.subscribe(data => {
			if(data.native_token) {
				this._proxy.login(data.native_token)
				this.synchronizeAuthorizations()
			}
		})
		Auth.events.logout.subscribe(() => this._proxy.logout())
	}
}

if(NativeProxy.isEnabled()) NativeProxy._initialize()