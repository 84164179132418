import { ViewLoader } from 'InterfaceBundle'

export default {
	install: (app, options) => {
		app.config.globalProperties.$currency = (number) => {
			if(!window.intlPluginCurrencyFormatter) window.intlPluginCurrencyFormatter = new Intl.NumberFormat(ViewLoader.data.intl.currency.language, { style: 'currency', currency: ViewLoader.data.intl.currency.code })
			return window.intlPluginCurrencyFormatter.format(number)
		}

		app.provide('intl', options)
	}
}