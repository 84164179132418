import { Router } from 'InterfaceBundle'

export default {
	mounted(el, binding) {
		if(el.hasAttribute('href')) {
			let prefix = el.getAttribute('href').split('/')
			prefix.pop()
			prefix = prefix.join('/') + '/'
			let prefixLen = prefix.length
			let active = false

			const handlePageChange = () => {
				let matching = window.location.pathname.substr(0, prefixLen) == prefix
				if(binding.value?.regex) matching = window.location.pathname.match(binding.value.regex.replace(/\\\\/g, '\\'))
				if(matching) {
					if(!active) {
						el.classList.add('active')
						active = true
					}
				}
				else {
					if(active) {
						el.classList.remove('active')
						active = false
					}
				}
			}

			handlePageChange()

			el.mainMenuActiveEventListener = el.routeChange = Router.change.subscribe(() => {
				setTimeout(() => handlePageChange())
			})
		}
	},
	unmounted(el) {
		if(el.mainMenuActiveEventListener) el.mainMenuActiveEventListener.unsubscribe()
	}
}