import { Auth } from './Auth.js'
import { Realtime, EventEmitter } from 'InterfaceBundle'

/**
 * Handle idle user status
 * @type {Object}
 */
export const UserStatus = {
	_controller: null,
	_permissionTimeout: null,
	_statusSubscription: null,
	status: null,
	change: null,

	/**
	 * Start user detection
	 */
	async start() {
		if(!UserStatus._statusSubscription) {
			//Settimeout to wait for Realtime service to initialize
			setTimeout(() => {
				if(!UserStatus.change) UserStatus.change = new EventEmitter()
				UserStatus._statusSubscription = Realtime.listen('auth.userstatus')
				UserStatus._statusSubscription.then(status => {
					UserStatus.status = status
					UserStatus.change.emit()
				})
			})
		}
		if(!('IdleDetector' in window)) return
		let state = null
		try {
			state = await window.IdleDetector.requestPermission();
		} catch(e) {
			if(!UserStatus._permissionTimeout) UserStatus._permissionTimeout = {tries: 1}
			else UserStatus._permissionTimeout.tries *= 2
			UserStatus._permissionTimeout.promise = setTimeout(() => UserStatus.start(), 1000 * UserStatus._permissionTimeout.tries)
		}
		if(state !== 'granted') return
		try {
			UserStatus._controller = new AbortController()
			let signal = UserStatus._controller.signal

			let idleDetector = new window.IdleDetector()
			idleDetector.addEventListener('change', () => {
				let active = idleDetector.userState == 'active' && idleDetector.screenState == 'unlocked'
				Realtime.emit('auth.currentuseridle', active)
			})

			await idleDetector.start({threshold: 60000, signal})
		} catch(e) {
			console.err(e)
		}
	},
	/**
	 * Stop user detection
	 */
	stop() {
		if(UserStatus._statusSubscription) {
			UserStatus._statusSubscription.unsubscribe()
			UserStatus._statusSubscription = null
		}
		if(UserStatus._permissionTimeout) {
			clearTimeout(UserStatus._permissionTimeout.promise)
			UserStatus._permissionTimeout = null
		}
		if(UserStatus._controller) {
			UserStatus._controller.abort()
			UserStatus._controller = null
		}
		this.status = null
	},

	/**
	 * Get status for user
	 * @param  {string} username
	 * @return {string}
	 */
	getStatus(username) {
		if(!this.status) return null
		else if(!this.status[username]) return 'offline'
		else if(this.status[username] == 1) return 'idle'
		else if(this.status[username] == 2) return 'active'
	}
}

/**
 * Events to start / stop user detection
 */
Auth.events.login.subscribe(() => UserStatus.start())
Auth.events.logout.subscribe(() => UserStatus.stop())
if(Auth.isAuthenticated()) UserStatus.start()