<template>
	<div style="position: relative;">
		<BlSelect v-if="dataReady" :data='data' v-model="value" @change="setFilter()" :combo="true" />
		<span v-if="value && value.length" class="suffix material-icons clear" @click="clear()" style="visibility: visible; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlListFilterSelectWorkflow',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: [],
			data: {},
			dataReady: false
		}
	},
	created() {
		this.value = this.initialValue
		if(!this.value) this.value = []
		//Load metadata
		let req = {}
		req['context("workflow"):s.internals.flow.listFilterSelectWorkflow("' + this.metadata.field.options.workflow + '")'] = {
			actions: {
				'loop("action"):local.workflow.actions': {
					reference: null,
					labelStatus: 'local.action.getLabelStatus()',
					icon: null,
					color: null
				}
			}
		}
		Api.post('api/structure/' , req).then(resp => {
			for(let action of resp.actions) this.data['<div class="listFilterSelectWorkflow"><icon style="color: ' + action.color + '">' + action.icon + '</icon><span>' + action.labelStatus + '</span></div>'] = action.reference
			this.dataReady = true
		})
	},
	methods: {
		setFilter() {
			if(this.value && this.value.length) this.$emit('blListFilterChange', [[this.metadata.field.name, 'IN', this.value], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = []
			this.setFilter()
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
	}
</style>