<template>
	<div v-bl-input :class="{errored: field.isErrored() && field.getTouched(), __prefix: field.options.prefix, __suffix: field.options.suffix}">
		<label>{{ field.label }}</label>
		<input :disabled="field.options.disabled" type="number" :step="precision" v-model="model" @change="field.setValue(model)" @blur="field.setTouched()" ref="field" :tabindex="tabindex" />
		<span ref="prefix" class="prefix" v-if="field.options.prefix">{{ field.options.prefix }}</span>
		<span class="suffix" v-if="field.options.suffix">{{ field.options.suffix }}</span>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldNumber',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			precision: null
		}
	},
	created() {
		this.precision = this.field.options.precision ? (1 / Math.pow(10, this.field.options.precision)) : 1
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	},
	mounted() {
		if(this.field.options.prefix) {
			this.$refs.field.style.paddingLeft = this.$refs.prefix.getBoundingClientRect().width + 10 + 'px'
		}
	}
}
</script>

<style scoped>
</style>