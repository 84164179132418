<template>
	<div :class="{lowScore: model == 1, highScore: model == 5, dark: theme == 'dark', light: theme == 'light'}">
		<span v-for="i in [1,2,3,4,5]" :key="i" :class="{active: model >= i}"></span>
	</div>
</template>

<script>
import { Variables } from 'InterfaceBundle'

export default {
	name: 'BlViewScore',
	props: ['model'],
	data() {
		return {
			theme: Variables.theme
		}
	}
}
</script>

<style scoped lang="scss">
div {
	display: flex;

	span {
		width: 30px;
		height: 20px;
	}

	span.active:after {
		color: #FBDA43;
		content: 'star';
	}

	span:after {
		content: 'grade';
		font-family: 'Material Icons';
		font-size: 30px;
		text-align: center;
		margin-top: -2px;
		margin-left: -5px;
		padding-bottom: 5px;
		display: block;
		line-height: 20px;
		color: var(--bl-border);
	}
}

div.lowScore span.active:after {
	color: #F62;
}

div.highScore.dark span.active:after {
	text-shadow: 0 0 10px #952;
}

div.highScore.light span.active:after {
	text-shadow: 0 0 10px #FBDA43;
}
</style>