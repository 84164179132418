<template>
	<div class="multiFileContainer">
		<div v-for="file in files" :key="file.id" style="margin: 0 4px 4px 0;">
			<BlFileView :preview="file.preview" :name="file.name" :icon="file.icon" :id="file.id" :size="file.size" :embededViewerUrl="file.embededViewerUrl" :navigation="file.navigation" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlMultiFileView',
	props: ['files'],
	watch: {
		files() {
			this.setNavigation()
		}
	},
	created() {
		this.setNavigation()
	},
	methods: {
		setNavigation() {
			let files = this.files
			if(this.files && this.files.length > 1) {
				for(let i = 0; i < this.files.length; i++) files[i].navigation = [
					this.files[i == 0 ? (this.files.length - 1) : ((i - 1) % this.files.length)],
					this.files[(i + 1) % this.files.length]
				]
			}
			else if(this.files) this.files.forEach(f => f.navigation = null)
		}
	}
}
</script>

<style scoped lang="scss">
	.multiFileContainer {
		display: flex;
		flex-wrap: wrap;
	}
</style>