/**
 * Attach to an input to bind it to a dropdown
 * @param input {string} required, query builder for the input element
 * @param list {string} required, query builder for the list element
 * @param 
 */
export default {
	mounted(el, binding) {
		binding.value.els = {
			input: el.querySelector(binding.value.input),
			list: el.querySelector(binding.value.list)
		}
		if(!binding.value.els.input) throw Error('Unable to get input')
		if(!binding.value.els.list) throw Error('Unable to get list')

		binding.value.els.list.style.display = 'none'
		binding.value.methods = {
			openList: () => {
				binding.value.els.list.style.display = 'block'
				document.addEventListener('click', binding.value.methods.handleBodyClick)
				document.addEventListener('focusin', binding.value.methods.handleBodyClick)
				window.addEventListener('blur', binding.value.methods.handleVisibilityChange)
			},
			handleVisibilityChange: () => {
				if(binding.value.els.list.style.display == 'block') binding.value.methods.closeList()
			},
			handleBodyClick: (event) => {
				if(el && !el.contains(event.target) && el != event.target) {
					if(event instanceof FocusEvent && event.target.tagName == 'DIALOG') return
					event.preventDefault()
					event.stopPropagation()
					binding.value.methods.closeList()
					return false
				}
			},
			closeList: () => {
				binding.value.els.list.style.display = 'none'
				document.removeEventListener('click', binding.value.methods.handleBodyClick)
				document.removeEventListener('focusin', binding.value.methods.handleBodyClick)
				window.removeEventListener('blur', binding.value.methods.handleVisibilityChange)
			},
			keydown: (ev) => {
				if(binding.value.value && !ev) binding.value.value.activeIndex = null
				if(ev && !['ArrowDown', 'ArrowUp'].includes(ev.key)) return
				let listElements = binding.value.els.list.children
				if(!listElements.length) return
				if(ev) ev.preventDefault()
				let activeElementIndex = !ev || ev.key == 'ArrowDown' ? -1 : 0
				let i = 0
				for(let item of listElements) {
					if(item.classList.contains('hovered')) {
						item.classList.remove('hovered')
						activeElementIndex = i
						break
					}
					i++
				}
				if(ev && ev.key == 'ArrowDown') activeElementIndex++
				else if(ev) activeElementIndex--
				else activeElementIndex = 0

				if(activeElementIndex == -1) activeElementIndex = listElements.length - 1
				else if(activeElementIndex == listElements.length) activeElementIndex = 0

				listElements[activeElementIndex].classList.add('hovered')
				listElements[activeElementIndex].scrollIntoView({block: 'nearest'})
				if(binding.value.value) binding.value.value.activeIndex = activeElementIndex
			},
			mousemove: (ev) => {
				let dropdownIndex = null
				let i = 0
				for(let item of ev.composedPath()) {
					if(item === binding.value.els.list) {
						dropdownIndex = i
						break
					}
					i++
				}
				if(dropdownIndex === null || !ev.composedPath()[dropdownIndex - 1]) return
				if(!ev.composedPath()[dropdownIndex - 1].classList.contains('hovered')) {
					let j = 0
					for(let item of binding.value.els.list.children) {
						if(item.classList.contains('hovered')) item.classList.remove('hovered')
						else if(item === ev.composedPath()[dropdownIndex - 1]) {
							item.classList.add('hovered')
							if(binding.value.value) binding.value.value.activeIndex = j
						}
						j++
					}
				}
			}
		}
		binding.value.els.input.addEventListener('focus', binding.value.methods.openList)
		binding.value.els.input.addEventListener('keydown', binding.value.methods.keydown)
		binding.value.els.list.addEventListener('mousemove', binding.value.methods.mousemove)
		binding.value.observer = new MutationObserver(() => binding.value.methods.keydown(null))
		binding.value.observer.observe(binding.value.els.list, {childList: true})
	},
	unmounted(el, binding) {
		if(binding.value.observer) binding.value.observer.disconnect()
		if(binding.value.methods) binding.value.methods.closeList()
		if(binding.value.els && binding.value.els.input) {
			binding.value.els.input.removeEventListener('focus', binding.value.methods.openList)
			binding.value.els.input.removeEventListener('keydown', binding.value.methods.keydown)
		}
		if(binding.value.els && binding.value.els.list) {
			binding.value.els.list.removeEventListener('mousemove', binding.value.methods.mousemove)
		}
	}
}