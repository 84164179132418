<template>
	<div class="paginationSettings">
		<h1>{{ $t('model.list.pagination.title') }}</h1>

		<div class="bl-card error" v-if="defaultPaginationError">
			{{ $t('model.list.pagination.errorDefault') }}
		</div>

		<table class="bl-datatable">
			<thead>
				<tr>
					<th>{{ $t('model.list.pagination.pagination') }}</th>
					<th>{{ $t('model.list.pagination.enabled') }}</th>
					<th>{{ $t('model.list.pagination.default') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="setting in settings" :key="setting.value">
					<td>{{ setting.label }}</td>
					<td>
						<input type="checkbox" class="bl-checkbox" v-model="setting.enabled" @change="handleDefault()" />
					</td>
					<td>
						<button :disabled="!setting.enabled" class="bl-icon-button" :class="{active: this.default == setting.value}" @click="this.default = setting.value">{{ this.default == setting.value ? 'radio_button_checked' : 'radio_button_unchecked' }}</button>
					</td>
				</tr>
			</tbody>
		</table>

		<div style="display: flex; justify-content: flex-end; padding-right: 9px; padding-top: 5px;">
			<BlButton :label="$t('model.list.pagination.save')" :disabled="currentRequest" @click="save()" />
		</div>
	</div>
</template>

<script>
import { ViewLoader, Dialog, Router } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'BlListPaginationSettings',
	props: ['blList'],
	data() {
		return {
			settings: null,
			default: null,
			currentRequest: false
		}
	},
	created() {
		let userSettings = ViewLoader.data.userPreferences.customSettings.blListPagination
		if(!userSettings) userSettings = []
		this.settings = ['a', 10, 20, 30, 50, 100, 200, 300]
		this.default = userSettings.length ? userSettings[0] : this.settings[0]
		if(!this.default) this.default = this.blList.defaultPaginationSettings[0]
		this.settings = this.settings.map(s => {
			return {
				value: s,
				label: s == 'a' ? this.$t('model.list.autopagination') : s,
				enabled: userSettings.length ? userSettings.includes(s) : ['a', 20, 50, 100].includes(s)
			}
		})
	},
	methods: {
		handleDefault() {
			for(let item of this.settings) {
				if(item.value == this.default && !item.enabled) this.default = null
			}
		},
		save() {
			this.defaultPaginationError = false
			if(!this.default) this.defaultPaginationError = true
			if(!this.settings.filter(s => s.enabled).length) this.defaultPaginationError = true

			if(this.defaultPaginationError) return
			this.currentRequest = true

			let value = this.settings.filter(s => s.enabled && s.value != this.default).map(s => s.value)
			value.unshift(this.default)

			let req = {}
			req['context("data"):script.run("MainMenuSettingsSet", "blListPagination", ' + JSON.stringify(value) + ')'] = {}
			Api.post('api/structure/', req).then(() => {
				this.currentRequest = false
				ViewLoader.data.userPreferences.customSettings.blListPagination = value
				Router.reload(true)
				Dialog.close()
			})

		}
	}
}
</script>

<style scoped lang="scss">
	.bl-icon-button {
		padding: 0;
		color: var(--bl-legend);
	}

	.bl-icon-button.active {
		color: var(--bl-primary);
	}

	.paginationSettings {
		padding-left: 10px;

		table.bl-datatable {
			margin: 5px 0;
		}
	}
</style>