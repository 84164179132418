import { Registry } from 'Bundles/InterfaceBundle/services/Registry.js'

/**
 * Style
 */
import './styles/auth.scss'

/**
 * Components
 */
import AuthLoginForm from './components/AuthLoginForm.vue'
import ProfilePicture from './components/ProfilePicture.vue'
import ReloginForm from './components/ReloginForm.vue'
import DeviceTypeSelector from './components/DeviceTypeSelector.vue'

Registry.components(AuthLoginForm, ProfilePicture, ReloginForm, DeviceTypeSelector)

/**
 * Directives
 */

/**
 * Services
 */
import { Auth as AuthService } from './services/Auth.js'
import { RefreshToken as RefreshTokenService } from './services/RefreshToken.js'
import { UserStatus as UserStatusService } from './services/UserStatus.js'
import { AccessControl as AccessControlService } from './services/AccessControl.js'
import { Login as LoginService } from './services/Login.js'
//eslint-disable-next-line no-unused-vars
import { PasswordUpdateRedirector } from './services/PasswordUpdateRedirector.js'
//eslint-disable-next-line no-unused-vars
import { TwoFA as TwoFAService } from './services/TwoFA.js'

export const Auth = AuthService
export const RefreshToken = RefreshTokenService
export const UserStatus = UserStatusService
export const AccessControl = AccessControlService
export const Login = LoginService