import { Auth } from 'AuthBundle'
import { Router, ViewServices } from 'InterfaceBundle'

/**
 * Handle force redirect when password change is required
 */
export const PasswordUpdateRedirector = {
	initialize() {
		Router.change.subscribe(() => {
			if(
				Auth.isAuthenticated() &&
				ViewServices.interfaceData.userPreferences.customSettings?.passwordResetRequired &&
				(Router.currentRoute?.definition?.component != 'InternalsUserSettingsPassword')
			) {
				setTimeout(() => Router.navigate('user/settings/password'))
			}
		})
	}
}

setTimeout(() => PasswordUpdateRedirector.initialize())