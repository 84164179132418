import { Grid } from 'InterfaceBundle'

const blGridContainerResizeObserver = new ResizeObserver(entries => {
	for(let entry of entries) {
		let className = null
		for(let item of Grid.definition) {
			if(entry.contentRect.width <= item[1]) {
				className = 'grid-container-' + item[0]
				break
			}
		}

		for(let item of entry.target.classList) {
			if(item.substr(0, 15) == 'grid-container-' && item != className) entry.target.classList.remove(item)
		}

		if(!entry.target.classList.contains(className)) entry.target.classList.add(className)
	}
})

/**
 * Set component as grid container for grid classes based on component and not on body size
 */
export default {
	mounted(el) {
		blGridContainerResizeObserver.observe(el)
	},
	unmounted(el) {
		blGridContainerResizeObserver.unobserve(el)
	}
}