import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { EventEmitter } from './EventEmitter.js'
dayjs.extend(localeData)

/**
 * Handles date formatting
 * @type {Object}
 */
export const DateFormat = {
	dayShort: null,
	dayMedium: null,
	dayLong: null,
	isoDayShort: null,
	isoDayMedium: null,
	isoDayLong: null,
	isoDayMatch: null,
	monthLong: null,
	monthShort: null,
	firstDayOfWeek: null,
	php2jsTokens: {
		'dd': 'DD',
		'd': 'D',
		'MM': 'MM',
		'M': 'M',
		'yyyy': 'YYYY',
		'yy': 'YY',
		'HH': 'HH',
		'mm': 'mm'
	},

	/**
	 * Get month name
	 * @param  {Date}    date
	 * @param  {Boolean} long
	 * @return {string}
	 */
	getMonthName(date, long = true) {
		return long ? this.monthLong[date.getMonth()] : this.monthShort[date.getMonth()]
	},

	/**
	 * Get local day
	 * @param  {Date} date
	 * @return {integer}
	 */
	getLocalDay(date) {
		let ret = (date.getDay() - this.firstDayOfWeek) % 7
		if(ret < 0) ret = 7 + ret
		return ret
	},

	/**
	 * Get day name
	 * @param  {Date}    date
	 * @param  {string}  format
	 * @return {string}
	 */
	getDayName(date, format = 'short') {
		let day = this.getLocalDay(date)
		let source = format == 'short' ? this.dayShort : (format == 'long' ? this.dayLong : this.dayMedium)
		return source[day]
	},

	/**
	 * Transform PHP formatting string to day.js formatting string
	 * @param {string} php format
	 * @return {string} dayjs format
	 */
	transformPHPFormatToJS(format) {
		for(let token in this.php2jsTokens) format = format.replace(token, this.php2jsTokens[token])
		return format
	},

	/**
	 * Create mask
	 * @param  {string} mask
	 * @return {string}
	 */
	createMask(mask) {
		for(let token in this.php2jsTokens) mask = mask.replace(token, this.php2jsTokens[token].split('').map(() => '#').join(''))
		return mask
	},

	/**
	 * Initialize items
	 */
	_initialize(language, country) {
		let ret = new EventEmitter()
		const initialize = () => {
			this.firstDayOfWeek = dayjs.localeData().firstDayOfWeek()
			this.isoDayMatch = {}
			for(let i = 0; i <= 6; i++) this.isoDayMatch[i] = (i - this.firstDayOfWeek + 8) % 7

			const offsetDays = (a, l) => {
				for(let i = 0; i < (l ? l : this.firstDayOfWeek); i++) a.push(a.shift())
			}

			this.dayLong = dayjs.localeData().weekdays()
			this.isoDayLong = JSON.parse(JSON.stringify(this.dayLong))
			offsetDays(this.dayLong)
			offsetDays(this.isoDayLong, 1)
			this.dayShort = dayjs.localeData().weekdaysMin().map(d => d.toUpperCase().substr(0, 1))
			this.isoDayShort = JSON.parse(JSON.stringify(this.dayShort))
			offsetDays(this.dayShort)
			offsetDays(this.isoDayShort, 1)
			this.dayMedium = dayjs.localeData().weekdaysShort()
			this.isoDayMedium = JSON.parse(JSON.stringify(this.dayMedium))
			offsetDays(this.dayMedium)
			offsetDays(this.isoDayMedium, 1)
			this.monthLong = dayjs.localeData().months()
			this.monthShort = dayjs.localeData().monthsShort()
			ret.emit()
		}

		const loadLocale = (code, cb) => {
			import('dayjs/locale/' + code + '.js').then(() => {
				dayjs.locale(code)
				cb(true)
			}).catch(() => cb(false))
		}

		loadLocale(language + '-' + country, valid => {
			if(valid) initialize()
			else loadLocale(language, () => initialize())
		})

		return ret
	}
}