<template>
	<div :class="{hasResults: results.length}" v-bl-dropdown="{input: 'input', list: 'ul', value: dropdownOptions}">
		<div class="searchContainer">
			<input type="text" v-model="term" @input="search()" ref="input" @keydown.enter="handleEnter()" />
			<button v-if="term" class="bl-icon-button" @click="term = ''; search()">cancel</button>
			<icon v-else>search</icon>
		</div>
		<ul class="bl-light-scroll">
			<li v-for="item in results" :key="item.uid" @click="integratedEditorOpenSearchResult(item)">
				<div>
					<icon :style="{color: item.color}">{{ item.icon }}</icon>
					<span>{{ item.name }}</span>
				</div>
				<em>{{ item.namespace.name }}</em>
			</li>
		</ul>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'InterfaceIntegratedEditorSearch',
	data() {
		return {
			term: '',
			results: [],
			dropdownOptions: {}
		}
	},
	created() {
		window.addEventListener('keydown', this.handleKeyboardShortcuts)
	},
	unmounted() {
		window.removeEventListener('keydown', this.handleKeyboardShortcuts)
	},
	methods: {
		search() {
			if(this.searchTimeout) clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(() => this.doSearch(), 150)
		},
		doSearch() {
			if(!this.term) {
				this.results = []
				return
			}
			let req = {}
			req['context("result"):s.internal.install.editor.search("' + this.term.replaceAll('"', '') + '")'] = {
				result: 'local.result'
			}
			Api.post('/api/structure/', req, {}, 'internal.install.editor.search').then(resp => {
				this.results = resp.result ? resp.result : []
			})
		},
		handleKeyboardShortcuts(event) {
			if(event.code == 'KeyF' && event.ctrlKey && event.shiftKey) {
				this.$refs.input.focus()
				this.$refs.input.select()
			}
		},
		handleEnter() {
			this.integratedEditorOpenSearchResult(this.results[this.dropdownOptions.activeIndex])
		}
	},
	inject: ['integratedEditorOpenSearchResult']
}
</script>

<style scoped lang="scss">
	div.hasResults {
		border-bottom: 1px solid var(--bl-border);
	}

	.searchContainer {
		display: flex;
		position: relative;

		input {
			background-color: var(--bl-surface);
			border: 1px solid var(--bl-border);
			width: calc(100% - 18px);
			outline: none;
			font-size: 12px;
			padding: 4px 6px;
			border-radius: 6px;
			transition: border .2s;
			margin: 2px 0 4px 2px;
		}

		input:focus {
			border: 1px solid var(--bl-primary);
		}

		icon {
			pointer-events: none;
			position: absolute;
			right: 0;
			font-size: 20px;
			margin: 4px;
			color: var(--bl-legend);
		}

		button.bl-icon-button {
			position: absolute;
			font-size: 17px;
			right: 0;
			color: var(--bl-legend);
			padding: 4px;
			margin-right: 2px;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0 4px;
		max-height: 300px;
		overflow-x: auto;

		li {
			padding: 4px 6px;
			user-select: none;
			margin: 0;
			cursor: pointer;
			border-radius: 6px;
			transition: background-color .2s;

			div {
				font-size: 12px;
				display: flex;
				align-items: center;

				icon {
					font-size: 14px;
					margin-right: 4px;
				}
			}

			em {
				font-size: 10px;
				font-style: normal;
				color: var(--bl-legend);
				display: block;
				margin-top: -1px;
			}
		}

		li:hover, li.hovered {
			background-color: var(--bl-surface);
		}
	}
</style>