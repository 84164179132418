<template>
	<div v-bl-input :class="{errored: field.isErrored() && field.getTouched()}">
		<label>{{ field.label }}</label>
		<textarea v-model="model" @change="field.setValue(model)" @blur="field.setTouched()" ref="field" :style="{height: getHeight()}" :disabled="field.options.disabled" :tabindex="tabindex" />
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldTextarea',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	},
	methods: {
		getHeight() {
			let lines = (typeof this.model === 'string' ? this.model : '').split("\n").length
			if(this.field.options.maxLines && lines > this.field.options.maxLines) lines = this.field.options.maxLines
			return ((lines * 17) + 4) + 'px'
		}
	}
}
</script>

<style scoped>
div.bl-input textarea {
	font-size: 14px;
	padding: 8px 8px 4px 8px;
	margin: 0 0 0 0;
	line-height: auto;
	min-height: 38px;
}
</style>