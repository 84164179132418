<template>
	<BlButton :label="label" type="submit" :disabled="currentRequest" ref="submit" :tabindex="taxIndex" />
</template>

<script>
export default {
	name: 'BlFormSubmit',
	inject: ['blFormSubmitChangeDetector', 'blFormSubmitShowDefault'],
	props: ['default'],
	data() {
		return {
			label: null,
			currentRequest: null,
			taxIndex: null
		}
	},
	mounted() {
		this.blFormSubmitChangeDetector.subscribe(this.changeFn)
		this.$nextTick(() => this.blFormSubmitShowDefault(!this.default ? false : null))
	},
	methods: {
		changeFn(datum) {
			this.label = datum.saveButtonLabel ? datum.saveButtonLabel : this.$t('form.save')
			this.currentRequest = datum.currentRequest
			this.taxIndex = datum.submitTabIndex
		}
	},
	unmounted() {
		this.blFormSubmitChangeDetector.unsubscribe(this.changeFn)
	}
}
</script>

<style scoped lang="scss">
</style>