<template>
	<div class="bl-input" ref="blInputContainer" :class="{errored: field.isErrored() && field.getTouched()}">
		<div class="mail" v-for="(address, index) in value" :key="index" v-bl-tooltip="address.mail" :class="{error: address.valid === false}">
			<span>{{ address.name ? (address.name + ' (' + address.mail.split('@')[1] + ')') : address.mail }}</span>
			<i class="bl-icon-button" @click="remove(index)">close</i>
		</div>
		<div style="flex: 1;" ref="dropDown" v-bl-dropdown="{input: 'input', list: '.dropDown', value: dropdownOptions}">
			<input type="email" v-model="newAddress" ref="field" @keydown.enter="add($event)" @keydown.backspace="backspace()" @keydown.tab="tab($event)" @blur="field.setTouched()" />
			<ul class="dropDown">
				<li v-for="item of getList()" :key="item.mail" @click="addItem(item)">
					<div>{{ item.name ? item.name : item.mail }}</div>
					<span v-if="item.name">{{ item.mail }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

export default {
	name: 'BlFormFieldMailrecipient',
	props: ['field'],
	data() {
		return {
			model: null,
			dropdownOptions: {},
			newAddress: '',
			value: []
		}
	},
	created() {
		this.model = this.field.value
		this.modelToList()

		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => {
			this.model = this.field.value
			this.modelToList()
		})
	},
	methods: {
		getList() {
			if(!this.field.options.contacts) return []
			let list = this.field.options.contacts.filter(a => !this.value.map(v => v.mail).includes(a.mail))
			if(this.newAddress.length) return list.filter(a => a.mail.toUpperCase().includes(this.newAddress.toUpperCase()))
			return list
		},
		/**
		 * Handles backspace keydown input
		 */
		backspace() {
			if(this.newAddress.length == 0) this.remove(this.value.length - 1)
		},
		/**
		 * Handles tab keydown input
		 */
		tab(ev) {
			if(this.dropdownOptions.activeIndex !== null && this.getList()[this.dropdownOptions.activeIndex] && this.newAddress.length) {
				ev.preventDefault()
				ev.stopPropagation()
				this.addItem(this.getList()[this.dropdownOptions.activeIndex])
			}
			if(this.newAddress.length) this.add(ev)
		},
		/**
		 * Add active element
		 */
		add(ev = null) {
			if(ev) {
				ev.preventDefault()
				ev.stopPropagation()
			}
			if(this.dropdownOptions.activeIndex !== null && this.getList()[this.dropdownOptions.activeIndex]) this.addItem(this.getList()[this.dropdownOptions.activeIndex])
			else if(this.newAddress.length) {
				this.value.push({mail: this.newAddress, valid: (/\S+@\S+\.\S+/).test(this.newAddress)})
				this.newAddress = ''
				this.listToModel()
			}
		},
		/**
		 * Remove item by index
		 */
		remove(index) {
			this.value.splice(index, 1)
			this.listToModel()
		},
		/**
		 * Add item from list
		 */
		addItem(item) {
			this.value.push(item)
			this.showList = false
			this.newAddress = ''
			setTimeout(() => this.$refs.dropDown.querySelector('input').focus())//Timeout mandatory for dropdown to close / reopen list
			this.listToModel()
		},
		/**
		 * Set value from model string
		 */
		modelToList() {
			if(!this.model) this.value = []
			else this.value = this.model.split(';').map(v => {
				if(v.includes('<')) return {name: v.split('<')[0].trim(), mail: v.split('<')[1].slice(0, -1).trim()}
				else return {mail: v.trim()}
			})
		},
		/**
		 * Set model value from list
		 */
		listToModel() {
			this.model = this.value.filter(v => v.valid !== false).map(v => {
				if(v.name) return v.name + ' <' + v.mail + '>'
				else return v.mail
			}).join(';')
			this.field.setValue(this.model)
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-input {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		input {
			width: auto;
			min-width: 150px;
			width: 100%;
			padding: 0 8px;
			min-height: 30px;
		}

		.mail {
			background-color: var(--bl-border);
			border-radius: var(--bl-border-radius);
			padding: 0 0 0 6px;
			margin: 2px;
			display: flex;
			align-items: center;

			.bl-icon-button {
				font-size: 16px;
				padding: 5px;
				margin-left: -3px;
			}
		}

		.mail.error {
			background-color: var(--bl-error);
			color: var(--bl-on-primary);

			.bl-icon-button {
				color: var(--bl-on-primary);
			}
		}

		.dropDown {
			min-width: 150px;
			list-style: none;
			margin: 0;
			padding: 0;
			border: 1px solid var(--bl-border);
			position: absolute;
			z-index: 2;
			background-color: var(--bl-surface);
			border-radius: var(--bl-border-radius);
			overflow: hidden;

			li {
				padding: 8px 12px;
				transition: background-color .2s;
				overflow: hidden;
				cursor: pointer;
				height: 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				span {
					color: var(--bl-legend);
					font-size: 12px;
				}
			}

			li.hovered {
				background-color: var(--bl-background);
			}
		}

		.dropDown:empty {
			border: 0;
		}
	}
</style>