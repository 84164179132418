import { EventEmitter, ViewServices } from 'InterfaceBundle'
import { Auth } from 'AuthBundle'

export var Signal = {
	_interrupted: false,
	/**
	 * Dispatch chained event
	 * @param  {string} event
	 * @param  			data
	 * @return {EventEmitter}
	 */
	chained(event, data = null) {
		return this._handle(event, data, true)
	},
	/**
	 * Dispatch unchained event
	 * @param  {string} event
	 * @param  			data
	 * @return {EventEmitter}
	 */
	unchained(event, data = null) {
		return this._handle(event, data, false)
	},
	/**
	 * Interrupt execution
	 */
	interrupt() {
		this._interrupted = true
	},
	_handle(event, data, chained) {
		const emitter = new EventEmitter()
		if(!ViewServices.interfaceData.signals) ViewServices.interfaceData.signals = []
		const listeners = ViewServices.interfaceData.signals.filter(e => event === e.event && (!e.permissions || Auth.isGranted(e.permissions))).sort((a, b) => a.priority > b.priority ? -1 : 1)
		this._dispatch(listeners, data, emitter, chained)

		return emitter
	},
	async _dispatch(listeners, data, res, chained) {
		let callStack = []
		for(let listener of listeners) {
			if(this._interrupted) break
			let listenerInstance = null
			eval('listenerInstance = {run() { ' + listener.content + '}}')
			listenerInstance.services = ViewServices
			listenerInstance.getData = () => data
			listenerInstance.getCallStack = () => callStack
			const result = listenerInstance.run(data, callStack)
			const ret = result instanceof EventEmitter ? await new Promise(resolve => result.once(r => resolve(r))) : result
			if(chained) data = ret
			callStack.push({listener: listener, data: data})
		}
		this._interrupted = false
		setTimeout(() => res.emit(data))
	}
}

ViewServices.signal = Signal