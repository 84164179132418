<template>
	<div :class="{errored: field.isErrored() && field.getTouched(), disabled: field.options.disabled}">
		<label class="mainLabel">{{ field.label }}</label>
		<div class="container">
			<div v-for="(label, value) in field.options.choices" :key="value" class="bl-checkbox-field">
				<input ref="checkbox" class="bl-checkbox" type="checkbox" v-model="model[value]" @change="updateField()" @blur="field.setTouched()" :disabled="field.options.disabled" />
				<label @click="toggle(value)">{{ label }}</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldMulticheckbox',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: {}
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.checkbox.focus())
		this.field.emitter.change.subscribe(() => this.updateModel())
		this.updateModel()
	},
	methods: {
		toggle(value) {
			if(this.field.options.disabled) return
			this.model[value] = !this.model[value]
			this.updateField()
		},
		updateField() {
			let field = []
			for(let value in this.model) {
				if(this.model[value]) field.push(value)
			}
			this.field.setValue(field)
		},
		updateModel() {
			this.model = {}
			if(this.field.value) {
				for(let item of this.field.value) this.model[item] = true
			}
		}
	}
}
</script>

<style scoped>
label.mainLabel {
	color: var(--bl-legend);
	background-color: var(--bl-surface);
	font-size: 10.5px;
	margin-left: 7px;
	transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px 10px;
	padding-left: 3px;

	> div {
		display: flex;

		label {
			cursor: pointer;
			padding-left: 6px;
			padding-top: 4px;
			user-select: none;
		}
	}

	> div:after {
		content: ' ';
		background: var(--bl-border);
		width: 1px;
		height: 16px;
		margin-left: 10px;
		margin-top: 4px;
	}

	> div:last-child:after {
		display: none;
	}
}

.disabled > .container > div.bl-checkbox-field label {
	cursor: default;
}

.errored {
	label.mainLabel {
		color: var(--bl-error);
	}
}
</style>