<template>
	<a class="bl-email-field" :href="'mailto:' + model" v-if="model">{{ model }}</a>
</template>

<script>
export default {
	name: 'BlViewEmail',
	props: ['model']
}
</script>

<style scoped lang="scss">
</style>