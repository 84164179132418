<template>
	<div>
		{{ lat }},{{ lng }}
		<BlMap @ready="setMap($event)" style="height: 300px; border-radius: var(--bl-border-radius);" />
	</div>
</template>

<script>
export default {
	props: ['lat', 'lng'],
	name: 'BlViewGeo',
	methods: {
		setMap(map) {
			const pos = {lat: this.lat, lng: this.lng}
			this.marker = new window.google.maps.Marker({
				map: map,
				position: pos
			})
			map.setCenter(pos)
			map.setZoom(10)
			map.setOptions({
				mapTypeControl: true,
				scrollwheel: false,
				zoomControl: true
			})
		}
	}
}
</script>

<style lang="scss" scoped>
</style>