<template>
	<div :style="{backgroundImage: data, width: size + 'px', height: size + 'px', minWidth: size + 'px', minHeight: size + 'px'}"><div v-if="status && showStatus" :class="[status, 'status']"></div></div>
</template>

<script>
import { Api } from 'ModelBundle'
import { ViewServices } from 'InterfaceBundle'
import { UserStatus } from 'AuthBundle'

let blProfilePictureCache = {}
let blProfilePictureLoading = {}

export default {
	name: 'BlProfilePicture',
	props: {
		id: {
			type: Number
		},
		size: {
			type: Number,
			default: 37
		},
		showStatus: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			data: null,
			status: null,
			statusSub: null
		}
	},
	watch: {
		id() {
			this.load()
		}
	},
	created() {
		this.load()
	},
	methods: {
		load() {
			let id = this.id
			if(!id && ViewServices.interfaceData.userPreferences.id) id = ViewServices.interfaceData.userPreferences.id
			if(blProfilePictureCache[id]) this.data = blProfilePictureCache[id]
			else if(blProfilePictureLoading[id]) blProfilePictureLoading[id].then(resp => this.data = resp)
			else {
				blProfilePictureLoading[id] = new Promise(resolve => {
					Api.get('user/profile-picture/' + id).then(resp => {
						blProfilePictureCache[id] = 'url(\'' + resp.data + '\')'
						resolve(blProfilePictureCache[id])
					})
				}).then(resp => this.data = resp)
			}
			if(this.showStatus) {
				this.status = UserStatus.getStatus(id)
				this.statusSub = UserStatus.change.subscribe(() => this.status = UserStatus.getStatus(id))
			}
			else if(this.statusSub) {
				this.statusSub.unsubscribe()
				this.statusSub = null
			}
		}
	},
	unmounted() {
		if(this.statusSub) this.statusSub.unsubscribe()
	}
}
</script>

<style scoped lang="scss">
	div {
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		position: relative;
	}

	div.status {
		border: 2px solid var(--bl-surface);
		transition: background-color .2s;
		background-color: var(--bl-legend);
		border-radius: 50%;
		width: 28%;
		height: 28%;
	}

	div.status.active {
		background-color: #59B04D;
	}

	div.status.idle {
		background-color: #EB9C14;
	}
</style>