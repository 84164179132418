<template>
	<div :class="{errored: field.isErrored() && field.getTouched(), disabled: field.options.disabled}" class="radioContainer">
		<label class="fieldLabel" @click="field.setTouched()">{{ field.label }}</label>
		<div>
			<div v-for="(val, label) in field.options.choices" :key="val">
				<span class="inputContainer">
					<input type="radio" v-if="field.options.disabled" :name="field.getQualifiedName()" disabled :value="val" v-model="model" :tabindex="tabindex">
					<input type="radio" v-else :name="field.getQualifiedName()" :value="val" v-model="model" @change="field.setValue(model)" :tabindex="tabindex">
				</span>
				<label @click="labelClick(val, $event)" v-html="label"></label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldRadio',
	inject: ['blFormLiveUpdateFields'],
	props: ['field', 'tabindex'],
	watch: {
		'field.options.choices'() {
			if(this.model && !Object.values(this.field.options.choices).includes(this.model)) {
				this.model = null
				this.field.setValue(this.model)
			}
		}
	},
	data() {
		return {
			model: this.field.value
		}
	},
	methods: {
		focusInput(event) {
			if(event.composedPath()[0].tagName == 'LABEL') event.composedPath()[0].parentElement.querySelector('input').focus()
		},
		labelClick(val, event) {
			if(this.field.options.disabled) return
			this.focusInput(event)
			this.model = val
			this.field.setValue(this.model)
		}
	},
	created() {
		let req = {}
		if(!this.field.options.choices) {
			req[this.field.name] = {loadAll: true}
			this.blFormLiveUpdateFields(req)
		}
	},
	mounted() {
		this.field.emitter.focus.subscribe(() => this.$el.querySelector('input[type="radio"]').focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped lang="scss">
.radioContainer > div {
	display: flex;
	flex-wrap: wrap;
}

.radioContainer > div > div {
	display: flex;
}

.fieldLabel {
	color: var(--bl-legend);
	font-size: 12px;
	margin-left: 4px;
}

.radioContainer > div label {
	margin-left: 10px;
	margin-right: 8px;
	display: inline-block;
	height: 24px;
	line-height: 24px;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
}

.inputContainer {
	position: relative;
	cursor: pointer;
	margin: -1px 1px 0 -1px;

	input {
		cursor: pointer;
		display: inline-block;
		outline: none;
		margin-top: 4px;
		margin-left: 7px;
		margin-right: -3px;
	}
}

.inputContainer:has(input:active)::before, .inputContainer:has(input:focus)::before {
	content: ' ';
	width: 14px;
	height: 14px;
	margin-left: 7.5px;
	margin-top: 5px;
	display: block;
	position: absolute;
	box-shadow: 0 0 0 10px var(--bl-primary);
	opacity: .2;
	border-radius: 50%;
	animation: active-animate-in .1s both;
	pointer-events: none;
	z-index: 2;
}

.inputContainer::after {
	pointer-events: none;
	top: 4px;
	margin-left: 6.5px;
	width: 16px;
	transition: color .2s;
	height: 16px;
	font-size: 22px;
	background-color: var(--bl-surface);
	position: absolute;
	display: block;
	font-family: Material icons;
	border-radius: 50%;
	line-height: 17px;
	text-indent: -3px;
}

.inputContainer:has(input:not(:checked))::after {
	content: 'radio_button_unchecked';
	color: var(--bl-legend);
}

.inputContainer:has(input:checked)::after {
	content: 'radio_button_checked';
	color: var(--bl-primary);
}

@keyframes active-animate-in {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 0.2;
	}
}

.errored > label {
	color: var(--bl-error);
}

div.disabled {
	label {
		color: var(--bl-legend);
		cursor: default;
	}

	input:after {
		cursor: default;
	}

	input:checked:after {
		color: var(--bl-legend);
	}

	input:active:before, input:focus:before {
		box-shadow: none;
	}
}
</style>