import { Realtime, EventEmitter, ViewServices } from 'InterfaceBundle'

/**
 * Run flows
 */
export const Flow = {
	input(inputId, data = {}) {
		let ret = new EventEmitter()
		Realtime.emit('flow.input', {inputId: inputId, data: data})
		let sub = Realtime.listen('flow.callback')
		sub.then(resp => {
			if(resp.inputId == inputId) {
				ret.emit(resp.data)
				sub.unsubscribe()
			}
		})
		return ret
	}
}

ViewServices.flow = Flow