<template>
	<div>
		<div ref="hook">
			<slot name="hook"></slot>
		</div>
		<div class="bl-menu-content" ref="content" v-if="opened"><slot></slot></div>
	</div>
</template>

<script>
import { Variables } from 'InterfaceBundle'

export default {
	name: 'BlMenu',
	emits: ['change'],
	props: ['openFrom'],
	data() {
		return {
			opened: false,
			content: null
		}
	},
	methods: {
		toggle() {
			document.body.removeEventListener('click', this.handleBodyClick)
			if(!this.opened) {
				if(Variables.currentlyOpenedMenu && Variables.currentlyOpenedMenu.opened) Variables.currentlyOpenedMenu.toggle()
				Variables.currentlyOpenedMenu = this
			}
			this.opened = !this.opened
			this.$emit('change', this.opened)
			if(this.opened) {
				this.$nextTick(() => {
					if(this.$refs.hook && this.$refs.content) {
						let boundingClient = this.$refs.hook.childNodes[0].nextSibling.getBoundingClientRect()
						this.content = this.$refs.content
						//Height boundingClientContent value must be multiplied by 2 because of the animation
						let boundingClientContent = this.content.getBoundingClientRect()

						let openFrom = null
						if(this.getOpenFrom().includes('above')) openFrom = 'above'
						else if(this.getOpenFrom().includes('below')) openFrom = 'below'
						else {
							let maxPoint = boundingClient.top + document.documentElement.scrollTop + boundingClient.height + boundingClientContent.height * 2
							openFrom = maxPoint > window.innerHeight ? 'above' : 'below'
						}

						let top = null
						if(openFrom == 'above') top = boundingClient.top + document.documentElement.scrollTop - boundingClientContent.height * 2
						else top = boundingClient.top + document.documentElement.scrollTop + boundingClient.height
						
						let left = null
						openFrom = null
						if(this.getOpenFrom().includes('before')) openFrom = 'before'
						else if(this.getOpenFrom().includes('after')) openFrom = 'after'
						else {
							let maxPoint = boundingClient.left + document.documentElement.scrollLeft + boundingClient.width + boundingClientContent.width
							openFrom = maxPoint > window.innerWidth ? 'before' : 'after'
						}
						if(openFrom == 'after') left = boundingClient.left + document.documentElement.scrollLeft
						else left = boundingClient.left + document.documentElement.scrollLeft - boundingClientContent.width + boundingClient.width

						this.content.style.top = top + 'px'
						this.content.style.left = left + 'px'
						document.body.appendChild(this.content)
					}
				})
				setTimeout(() => document.body.addEventListener('click', this.handleBodyClick), 10)
			}
		},
		handleBodyClick() {
			this.toggle()
		},
		getOpenFrom() {
			return this.openFrom ? this.openFrom : ''
		}
	},
	mounted() {
		this.$refs.hook.childNodes[0].nextSibling.addEventListener('click', e => {
			e.preventDefault()
			e.stopPropagation()
			this.toggle()
			return false
		})
	},
	beforeUnmount() {
		if(this.opened && this.content && this.content.parentNode) this.content.parentNode.removeChild(this.content)
	},
	unmounted() {
		document.body.removeEventListener('click', this.handleBodyClick)
	}
}
</script>

<style lang="scss">
.bl-menu-content {
	z-index: 1500;
	top: 0;
	left: 0;
	position: absolute;
	background-color: var(--bl-surface);
	border: 1px solid var(--bl-border);
	border-radius: var(--bl-border-radius);
	overflow: hidden;
	min-width: 150px;
	animation: bl-menu-dropdown-in 0.08s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.2);

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: 0 15px;
			min-height: 40px;
			display: flex;
			align-items: center;
			transition: background-color .2s;
			cursor: pointer;

			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			i.material-icons, icon {
				font-size: 20px;
				margin-right: 10px;
				margin-left: -5px;
				color: var(--bl-legend);
			}
		}

		li:hover {
			background-color: var(--bl-background);
		}
	}

	hr {
		border: none;
		margin: 0;
		padding: 0;
		border-top: 1px solid var(--bl-border);
	}
}

@keyframes bl-menu-dropdown-in {
	0% {
		transform: scaleY(0.5);
		transform-origin: 50% 0%;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 50% 0%;
	}
}
</style>