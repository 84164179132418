import { Realtime, WindowManager } from 'InterfaceBundle'

/**
 * Bridge for flow elements
 */
export const MailBridge = {
	initialize() {
		//Mail dialog element
		Realtime.listen('maildialog').then(data => {
			let restoreFlow = data._restoreFlow
			delete data._restoreFlow
			WindowManager.open('BlMailWindow', data).subscribe(resp => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {sent: resp === true, canceled: resp !== true}})
			})
		})
	}
}

MailBridge.initialize()