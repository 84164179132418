<template>
	<div>
		<div class="header">
			<h1>{{ field.label }}</h1>
			<button class="bl-icon-button" @click="close()">close</button>
		</div>
		<div>
			<canvas class="canvas" @mousedown="startDraw($event)" @touchstart="startDraw($event)" ref="field"></canvas>
		</div>
		<div class="actionsContainer">
			<BlButton class="outlined erase-btn" :label="$t('Clear')" @click="resetPath()" />
			<BlButton :label="$t('Sign')" @click="save()" />
		</div>
	</div>
</template>

<script>
import {Dialog} from 'InterfaceBundle'
import dayjs from 'dayjs';

export default {
	name: 'BlFormFieldSignatureDialog',
	props: ['field'],
	data() {
		return {
			model: null,
			canvas: null,
			ctx: null,
			events: {
				touchstart: {
					moveEvent: 'touchmove',
					stopEvent: 'touchend'
				},
				mousedown: {
					moveEvent: 'mousemove',
					stopEvent: 'mouseup'
				}
			},
			eventType: null
		}
	},
	mounted() {
		this.initCanvas()
	},
	methods: {
		fitToContainer() {
			// Make it visually fill the positioned parent
			this.canvas.style.width = '100%'
			this.canvas.style.height = '100%'
			// ...then set the internal size to match
			this.canvas.width = this.canvas.offsetWidth
			this.canvas.height = this.canvas.offsetHeight
		},
		initCanvas() {
			this.canvas = this.$refs.field
			this.ctx = this.canvas.getContext('2d')
			this.fitToContainer()
			this.model = this.extractPath()
			this.ctx.stroke(new Path2D(this.model))
		},
		extractPath() {
			if(!this.field.value) return ''
			let arrayData = this.field.value.split(',')
			if(arrayData.length >= 3) {
				return arrayData[2] // 0=width , 1=height , 2= path,3= date
			}
		},
		startDraw(event) {
			this.eventType = event.type
			const {x, y} = this.getMousePos(event)
			this.model += `M ${x} ${y} L ${this.formatPos(x - 0.2)} ${this.formatPos(y + 0.2)} L ${this.formatPos(x - 0.2)} ${this.formatPos(y - 0.2)} `
			this.ctx.stroke(new Path2D(this.model))
			this.canvas.addEventListener(this.events[this.eventType].moveEvent, this.mouseMoved)
			document.addEventListener(this.events[this.eventType].stopEvent, this.mouseUp)
		},
		formatPos(pos) {
			return parseFloat(pos).toFixed(2)
		},
		mouseMoved(event) {
			if(this.eventType === 'touchstart' && event.touches.length > 1) return
			const {x, y} = this.getMousePos(event)
			this.model += `L ${x} ${y} `
			this.ctx.stroke(new Path2D(this.model))
		},
		mouseUp() {
			this.canvas.removeEventListener(this.events[this.eventType].moveEvent, this.mouseMoved)
			document.removeEventListener(this.events[this.eventType].stopEvent, this.mouseUp)
		},
		resetPath() {
			this.model = ''
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
		},
		getMousePos(e) {
			let br = this.canvas.getBoundingClientRect()
			if(this.eventType === 'touchstart') {
				return {
					// x & y are relative to the clicked element
					x: this.formatPos(e.touches[0].clientX - br.left),
					y: this.formatPos(e.touches[0].clientY - br.top),
				}
			}
			else {
				return {
					x: this.formatPos(e.clientX - br.left),
					y: this.formatPos(e.clientY - br.top)
				}
			}
		},
		getFormatedDate() {
			return dayjs().format('YYYY/MM/DD HH:mm:ss')
		},
		save() {
			let ret = (this.model && this.model.length) ? `${this.canvas.width},${this.canvas.height},${this.model}`.trimEnd() + `,${this.getFormatedDate()}` : null
			Dialog.close(true, true, ret)
		},
		close() {
			Dialog.close(false)
		}
	},
	beforeUnmount() {
		if(this.eventType) this.mouseUp()
	}
}
</script>

<style scoped lang="scss">
.actionsContainer {
	display: flex;
	margin-top: 20px;
	justify-content: flex-end;
}

.canvas {
	border-radius: var(--bl-border-radius);
	border: solid 1px var(--bl-border);
	touch-action: none;
}

.header {
	display: flex;
	justify-content: space-between;
	margin-top: -20px;
}
.erase-btn {
	margin-right: 10px;
}

h1 {
	font-family: Product sans;
	font-size: 21px;
}
</style>