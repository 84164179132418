<template>
	<div>
		<div v-for="item in progress" :key="item.id" class="progressContainer bl-card">
			<b>{{ item.title }}</b>
			<div style="display: flex;">
				<span>{{ item.progress }}/{{ item.total }}</span>
				<div class="bar">
					<div :style="{width: ((item.progress/item.total)*100) + '%', opacity: item.done ? 0 : 1}"></div>
				</div>
				<span>{{ item.percent }}%</span>
			</div>
		</div>
		<div class="bl-card" v-if="data.length" style="display: flex; flex-direction: column;" :class="theme">
			<BlButton label="Clear logs" type="button" classlist="dense outlined" style="width: 100%;" @click="clear()" />
			<div class="bl-light-scroll" ref="container">
				<div v-for="log in data" :key="log.id" @click="openLog(log)">
					<div style="display: flex; align-items: center;">
						<em>{{ log.date.substr(11) }}</em>
						<component :is="log.type._cn" v-bind="log.type._cp" />
						<div v-if="log.typeDetail" class="typeDetail">
							{{ log.typeDetail }}
						</div>
						<div class="title" :class="log.titletype">{{ log.title }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog, Variables, Realtime } from 'InterfaceBundle'
import { Api, ModelChangeEventHelpers } from 'ModelBundle'

export default {
	name: 'BlLogViewer',
	props: ['channel', 'channels', 'debug'],
	data() {
		return {
			progress: {},
			theme: Variables.theme,
			data: [],
			ids: [],
			newlogSub: null,
			progressSub: null
		}
	},
	watch: {
		channel: function() {
			this.loadChannels()
		},
		channels: function() {
			this.loadChannels()
		}
	},
	methods: {
		clear() {
			this.data = []
		},
		loadData(channel) {
			let filters = ['&', ['id', '>', this.fromIds[channel]]]
			if(this.getChannels()) filters.push(['channel', '=', channel])
			let req = {
				data: {
					fields: [{name: 'title'}, {name: 'titletype'}, {name: 'date'}, {name: 'type'}, {name: 'id'}],
					filters: filters,
					model: 'internals.log',
					sort: [{field: 'date', order: 'ASC'}],
					limit: -1,
					metadata: false
				}
			}
			Api.post('api/', req).then(resp => {
				for(let item of resp.data.data) {
					if(item.titletype.split('.').length > 1) {
						item.typeDetail = item.titletype.split('.')[1]
						item.titletype = item.titletype.split('.')[0]
					}
					if(item.id > this.fromId) this.fromId = item.id
					if(!this.ids.includes(item.id)) {
						this.data.unshift(item)
						this.ids.push(item.id)
					}
				}
				this.$nextTick(() => {
					if(this.$refs.container) this.$refs.container.scrollTop = this.$refs.container.scrollHeight
				})
			})
		},
		openLog(log) {
			Dialog.custom({
				component: 'BlLogDetail',
				componentProps: log.id ? {id: log.id} : {data: log}
			}).then(() => {}).catch(() => {})
		},
		handleProgress(data) {
			if(!this.progress[data.id]) this.progress[data.id] = data
			else {
				for(let k in data) this.progress[data.id][k] = data[k]
			}
			if(this.progress[data.id].done) {
				this.progress[data.id].percent = 100
				setTimeout(() => delete this.progress[data.id], 2000)
			}
			else this.progress[data.id].percent = Math.round(this.progress[data.id].progress / this.progress[data.id].total * 100)
		},
		getChannels() {
			if(this.channels) return this.channels
			if(this.channel) return [this.channel]
			return null
		},
		loadChannels() {
			this.unloadChannels()

			for(let channel of this.getChannels()) {
				//Get max id
				let req = {
					data: {
						fields: [{name: 'id'}],
						filters: ['channel', '=', channel],
						model: 'internals.log',
						sort: [{field: 'id', order: 'DESC'}],
						limit: 1,
						metadata: false
					}
				}
				Api.post('api/', req).then(resp => {
					this.fromIds[channel] = resp.data.count ? resp.data.data[0].id : 1
					const sub = ModelChangeEventHelpers.listen('custom.log.new.' + channel)
					sub.subscribe(() => this.loadData(channel))
					this.newlogSubs.push(sub)
				})
			}
		},
		unloadChannels() {
			this.fromIds = {}
			if(this.newlogSubs) {
				for(let sub of this.newlogSubs) ModelChangeEventHelpers.unsubscribe(sub)
			}
			this.newlogSubs = []
		}
	},
	created() {
		this.loadChannels()
		this.progressSub = ModelChangeEventHelpers.listen('custom.log.progress')
		this.progressSub.subscribe(resp => this.handleProgress(resp))
		if(this.debug) {
			this.debugSub = Realtime.listen('logger.debug')
			this.debugSub.then(log => this.data.unshift(log))
		}
	},
	unmounted() {
		if(this.debugSub) this.debugSub.unsubscribe()
		this.unloadChannels()
		ModelChangeEventHelpers.unsubscribe(this.progressSub)
	}
}
</script>

<style scoped lang="scss">
	.bl-card {
		padding: 0;
		overflow: hidden;
	}

	.bl-card  > .bl-light-scroll {
		overflow-x: auto;
		scroll-snap-type: y mandatory;
		max-height: 300px;
	}

	.search {
		display: flex;
		align-items: center;
	}

	.bl-card > div.bl-light-scroll > div {
		cursor: pointer;
		scroll-snap-align: center;
		transition: background-color .1s;
		padding: 6px 5px;

		em {
			font-size: 12px;
			font-style: normal;
			color: var(--bl-legend);
			margin-right: 8px;
			white-space: nowrap;
			min-width: 30px;
		}

		.bl-chip {
			overflow: hidden;
			max-width: 14px;
			padding: 0;
			margin-right: 4px;

			:deep icon {
				font-size: 14px !important;
			}
		}

		.title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.title.boolean, .title.double, .title.integer {
			color: #9e3379;
		}

		.title.string::before, .title.string::after {
			content: "'";
		}

		.title.array, .title.object, .title.instance, .typeDetail {
			color: #3c3cbf;
		}

		.typeDetail {
			font-size: 11px;
			font-weight: 500;
			border-radius: 2px;
			padding-right: 4px;
			font-style: italic;
		}

		.logTypeIcon {
			margin-right: 4px;
			font-size: 14px;
		}
	}

	.bl-card.dark > div.bl-light-scroll > div {
		.title.array, .title.object, .title.instance, .typeDetail {
			color: #b4b4ef;
		}

		.title.boolean, .title.double, .title.integer {
			color: #f7a1d9;
		}
	}

	.bl-card > div.bl-light-scroll > div:hover {
		background-color: var(--bl-background);
	}

	.progressContainer > div > span {
		font-weight: 500;
		line-height: 30px;
		font-family: Product sans;
		font-size: 16px;
		min-width: 20px;
		padding: 0 14px;
	}

	.progressContainer {
		b {
			display: block;
			padding-bottom: 4px;
			text-align: center;
		}

		.bar {
			flex: 1;
			position: relative;
			border: 1px solid var(--bl-border);
			border-radius: var(--bl-border-radius);
			overflow: hidden;
			height: 30px;

			div {
				background-color: var(--bl-secondary);
				height: 100%;
				position: absolute;
				z-index: 0;
				transition: width .2s, opacity .5s;
				border-top-right-radius: var(--bl-border-radius);
				border-bottom-right-radius: var(--bl-border-radius);
			}
		}
	}
</style>