import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'

export const Snackbar = {
	stack: [],
	_stackChange: new EventEmitter(),

	/**
	 * Open snackbar
	 */
	open(options) {
		let validOptions = ['text', 'error', 'autoClose', 'autoCloseTime', 'component', 'componentProps', 'actionLabel', 'actionClick', 'clickToClose']
		for(let item of Object.keys(options)) {
			if(!validOptions.includes(item)) throw new Error('Snackbar option "' + item + '" invalid')
		}
		if(typeof options.autoClose === 'undefined') options.autoClose = true
		if(typeof options.clickToClose === 'undefined') options.clickToClose = true
		if(options.autoClose && !options.autoCloseTime) options.autoCloseTime = 4000
		if(options.component && !options.componentProps) options.componentProps = {}

		let stackItem = {
			id: (this.stack.length ? Math.max(...this.stack.map(t => t.id)) : 0) + 1,
			options: options
		}
		if(options.autoClose) stackItem.autocloseTimeout = setTimeout(() => this._remove(stackItem.id), options.autoCloseTime)

		this.stack.push(stackItem)
		this._stackChange.emit()
		document.documentElement.style.setProperty('--bl-snackbar-count', this.stack.length)

		return {
			hide: () => this._remove(stackItem.id)
		}
	},

	/**
	 * Hide snackbar
	 * @param  {mixed} element
	 */
	_remove(id) {
		if(!this.stack.map(s => s.id).includes(id)) return
		if(this.removing) {
			setTimeout(() => this._remove(id), 100)
			return
		}
		this.removing = true
		let stackItem = this.stack.filter(s => s.id == id)[0]
		stackItem.animateOut = true
		this._stackChange.emit()
		setTimeout(() => {
			if(stackItem.autocloseTimeout) clearTimeout(stackItem.autocloseTimeout)
			this.stack = this.stack.filter(e => e.id != id)
			this._stackChange.emit()
			document.documentElement.style.setProperty('--bl-snackbar-count', this.stack.length)
			this.removing = false
		}, 100)
	}

	//
}

document.documentElement.style.setProperty('--bl-snackbar-count', 0)
ViewServices.snackbar = Snackbar