<template>
	<ul :class="{moveMode: moveMode}">
		<li v-for="(item, index) in tree" :key="index" :style="{display: moveMode && !item.namespaceId ? 'none' : null}">
			<div :class="{active: item.tabOpened, highlight: item.highlight}" @click="toggleItem(item)" @contextmenu="moveMode ? null : addElement(item, $event)" :id="'installIntegratedEditorItem|' + item.model + '|' + item.id">
				<div class="bl-loader-line" v-if="item.loading"></div>
				<icon :style="{color: item.color}">{{ moveMode ? item.moveIcon : item.icon }}</icon>
				<span>{{ item.label }}{{ item.extension ? '.' + item.extension : '' }}</span>
				<button class="bl-icon-button" v-if="!moveMode && item.namespaceId" @click="addElement(item, $event)">more_vert</button>
				<button class="bl-icon-button" v-if="moveMode" @click="addElement(item, $event)">drive_file_move</button>
			</div>
			<InterfaceIntegratedEditorNavigation v-if="item.nodes && ((!moveMode && item.opened) || (moveMode && item.moveOpened))" :tree="item.nodes" :moveMode="moveMode" />
		</li>
	</ul>
</template>

<script>
export default {
	name: 'InterfaceIntegratedEditorNavigation',
	props: ['tree', 'moveMode'],
	inject: ['toggleItem', 'addElement']
}
</script>

<style scoped lang="scss">
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0 0 0 15px;

		li {
			cursor: pointer;
			user-select: none;

			div {
				font-size: 12px;
				width: calc(100% - 5px);
				display: inline-flex;
				align-items: center;
				padding: 3px 3px 3px 0;
				position: relative;
				transition: background-color .2s;
				border-radius: 6px;

				icon {
					font-size: 14px;
					margin: 0 5px;
				}

				button {
					display: none;
					padding: 0;
					font-size: 18px;
					margin: -7px 0;
				}

				span {
					flex: 1;
				}
			}

			div:hover {
				background-color: var(--bl-surface);

				button {
					display: inline-block;
				}
			}

			div.active {
				font-weight: 500;
			}

			div.highlight, div.highlight.active {
				background-color: var(--bl-primary);
			}

			.bl-loader-line {
				width: 11px;
				height: 6px;
				left: 8px;
				top: 12px;
				position: absolute;
				overflow: hidden;
				background-color: transparent;
			}

			.bl-loader-line::before {
				height: 3px;
			}
		}
	}

	ul.moveMode li {

		div button {
			display: inline-block;
			margin: 0 4px;
			font-size: 22px;
		}
	}

	ul.moveMode div:hover {
		border-radius: var(--bl-border-radius);
		background-color: var(--bl-background);
	}
</style>