<template>
	<div v-if="blViewGetField(name)" class="bl-view-field" :style="{display: (hideEmpty && !blViewGetField(name).value) || hide ? 'none' : null}">
		<label v-if="(label || blViewGetField(name).metadata) && !hideLabel">{{ label ? label : blViewGetField(name).metadata.label }}</label>
		<slot>
			<span class="emptyValue" v-if="!hideLabel && !blViewGetField(name).value && !blViewGetField(name).value?._cn">{{ $t('model.empty') }}</span>
			<span v-if="blViewGetField(name).value && !blViewGetField(name).value._cn" :class="{right: blViewGetField(name).metadata && blViewGetField(name).metadata.options.tableAlign == 'right'}"><component :is="{template: '<span>' + blViewGetField(name).value + '</span>'}"></component></span>
			<component v-if="blViewGetField(name).value && blViewGetField(name).value._cn" :is="blViewGetField(name).value._cn" v-bind="getBinding()" />
		</slot>
	</div>
</template>

<script>
export default {
	name: 'BlViewField',
	props: {
		name: {
			default: '_self',
			type: String
		},
		hideEmpty: {
			default: false,
			type: Boolean
		},
		label: {
			default: null,
			type: String
		},
		formatted: {
			default: true,
			type: Boolean
		},
		hideLabel: {
			default: false,
			type: Boolean
		},
		bindings: {
			default: null
		},
		hide: {
			default: false,
			type: Boolean
		},
		shortLabel: {
			type: Boolean,
			default: null
		}
	},
	inject: ['blViewAddField', 'blViewGetField', 'blViewLoaded', 'blViewFieldsShortLabel'],
	created() {
		this.addField()
	},
	methods: {
		addField() {
			this.blViewAddField({name: this.name, formatted: this.formatted, shortLabel: this.shortLabel !== null ? this.shortLabel : (this.blViewFieldsShortLabel ? this.blViewFieldsShortLabel() : null)})
		},
		getBinding() {
			let ret = this.blViewGetField(this.name).value._cp
			if(this.bindings) ret = {...ret, ...this.bindings}
			return ret
		}
	},
	watch: {
		name() {
			this.addField()
		}
	}
}
</script>

<style scoped lang="scss">
	div:not(.bl-chip) {
		padding: 4px 0;
	}

	label {
		color: var(--bl-legend);
		font-size: 11px;
		display: block;
	}

	.field-error {
		position: relative;
	}

	.field-error::before {
		content: ' ';
		width: 100%;
		height: 100%;
		position: absolute;
		background: repeating-linear-gradient(45deg, var(--bl-error), var(--bl-error) 10px, var(--bl-surface) 10px, var(--bl-surface) 20px);
		opacity: .2;
	}

	.emptyValue {
		background-color: var(--bl-background);
		border-radius: var(--bl-border-radius);
		color: var(--bl-legend);
		font-size: 12px;
		padding: 2px 5px;
	}
</style>