<template>
	<div class="bl-card">
		<div v-for="item in data" :key="item.id">
			<div style="display: flex">
				<BlProfilePicture v-if="item.userId" :id="item.userId" style="margin-right: 10px;" />
				<div class="noProfilePicture" v-else>S</div>
				<div>
					<div style="display: flex; align-items: center;">
						<b v-if="item.user">{{ item.user }}</b>
						<b v-else><icon>bolt</icon> {{ $t('System') }}</b>
						<em>{{ item.date }}</em>
					</div>
					<span class="action"><icon>{{ item.creation ? 'add' : 'edit' }}</icon> {{ item.label }} {{ item.creation ? $t('created') : $t('updated') }}</span>
					<ul v-if="item.properties.length">
						<li v-for="prop in item.properties" :key="prop.field">
							<span class="propertyName">{{ $t(prop.field) }}</span>
							<span :class="{empty: prop.from === null || prop.from === ''}" v-html="prop.from === null || prop.from === '' ? 'Empty' : prop.from"></span>
							<i class="material-icons">arrow_right_alt</i>
							<span :class="{empty: prop.to === null || prop.to === ''}" v-html="prop.to === null || prop.to === '' ? 'Empty' : prop.to"></span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { ViewServices } from 'InterfaceBundle'

export default {
	name: 'BlViewHistory',
	props: ['model', 'modelId'],
	inject: ['blViewProps'],
	data() {
		return {
			data: [],
			objectSettings: {}
		}
	},
	created() {
		this.objectSettings.model = this.model
		this.objectSettings.id = this.modelId ? this.modelId : ViewServices.routeParams.id
		if(this.blViewProps) {
			this.objectSettings.id = this.blViewProps().id
			this.objectSettings.model = this.blViewProps().model
		}
		this.loadData()
		this.rt = ModelChangeEventHelpers.listen(this.objectSettings.model)
		this.rt.subscribe(() => this.loadData())
	},
	unmounted() {
		ModelChangeEventHelpers.unsubscribe(this.rt)
	},
	methods: {
		loadData() {
			Api.get('model-state/' + this.objectSettings.model + '/' + this.objectSettings.id).then(resp => this.data = resp)
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-card > div:last-child {
		margin-bottom: 10px;
	}

	.bl-card > div {
		margin: 10px 10px 25px 10px;

		.noProfilePicture {
			width: 37px;
			height: 37px;
			min-width: 37px;
			min-height: 37px;
			margin-right: 10px;
			background-color: var(--bl-background);
			border-radius: 50%;
			justify-content: center;
			font-family: sans-serif;
			font-size: 20px;
			color: var(--bl-legend);
			display: flex;
			align-items: center;
		}

		b {
			font-weight: 500;
			margin-right: 10px;
			display: flex;

			icon {
				color: var(--bl-legend);
				font-size: 16px;
			}
		}

		em {
			font-style: normal;
			color: var(--bl-legend);
			font-size: 12px;
		}

		span.action {
			margin: 2px 0 5px 0;
			display: inline-flex;
			font-size: 12px;
			color: var(--bl-legend);
			border: 1px solid var(--bl-border);
			border-radius: var(--bl-border-radius);
			padding: 3px 8px 3px 5px;
			align-items: center;

			icon {
				font-size: 12px;
				margin-right: 3px;
			}
		}

		ul {
			list-style: none;
			margin: 0;
			border-left: 1px solid var(--bl-border);
			padding: 4px 0 4px 8px;
			margin-left: 8px;

			li {
				padding: 2px 0;
				display: flex;
				align-items: center;

				.propertyName {
					font-weight: 500;
					width: 100px;
				}

				i.material-icons {
					font-size: 18px;
				}

				.empty {
					background-color: var(--bl-background);
					border-radius: var(--bl-border-radius);
					color: var(--bl-legend);
					font-size: 12px;
					padding: 2px 5px;
				}
			}
		}
	}
</style>