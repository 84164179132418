import { Auth } from 'AuthBundle'
import { ViewLoader } from 'InterfaceBundle'

/**
 * Handle auto logout from access control policies
 */
export const AccessControl = {
	//Timeout for "session" rule
	autologoutTimeout: null,
	//Duration for "session" rule
	autologoutDuration: null,
	//Interval for "Working hours" and "Auto disconnect" rules, check if time rule applies
	logoutAtTimeout: null,
	//Interval for "Working hours" and "Auto disconnect" rules with inactivity time. Is set once an hour is reached to start measuring inactivity time
	logoutAtKickTimeout: null,
	//Duration for "Working hours" and "Auto disconnect" rules with inactivity time
	logoutAtDuration: null,
	_activityCb: () => AccessControl.activity(),
	_logoutWindowCloseCb: () => AccessControl.logoutWindowClose(),

	/**
	 * Initialize listeners and subscribers, called once on app boot
	 */
	initialize() {
		document.body.addEventListener('keydown', this._activityCb)
		document.body.addEventListener('mouseover', this._activityCb)
		Auth.events.logout.subscribe(() => this.unmount())
		Auth.events.login.subscribe(() => this.doInitialize())
		ViewLoader.ready.subscribe(() => this.doInitialize())
	},

	/**
	 * Initialize on login / page load
	 */
	doInitialize() {
		if(!ViewLoader.isReady || !Auth.isAuthenticated()) return
		if(localStorage.getItem('Auth.logoutAtNextRefresh')) this.kickUser()
		if(ViewLoader.data.accesscontrol.autoLogoutAfter) {
			this.autologoutDuration = ViewLoader.data.accesscontrol.autoLogoutAfter * 60 * 1000
			this.setAutologout(1)
		}
		if(ViewLoader.data.accesscontrol.logoutWindowClose) {
			window.addEventListener('beforeunload', this._logoutWindowCloseCb)
		}
		if(ViewLoader.data.accesscontrol.logoutAt.length) {
			this.logoutAtTimeout = setInterval(() => {
				let now = new Date()
				for(let item of ViewLoader.data.accesscontrol.logoutAt) {
					if(item.day == now.getDay() && item.time[0] == now.getHours() && item.time[1] == now.getMinutes()) {
						if(item.inactivity === null) this.kickUser()
						else if(this.logoutAtDuration === null) {
							localStorage.setItem('logoutAtNextRefresh', true)
							this.logoutAtDuration = item.inactivity * 60 * 1000
							this.setAutologout(2)
						}
					}
				}
			}, 60 * 1000)//Update to minutes
		}
	},

	/**
	 * Unmount on logout
	 */
	unmount() {
		window.removeEventListener('beforeunload', this._logoutWindowCloseCb)
		document.body.removeEventListener('keydown', this._activityCb)
		document.body.removeEventListener('mouseover', this._activityCb)
		if(this.autologoutTimeout) clearTimeout(this.autologoutTimeout)
		if(this.logoutAtTimeout) clearTimeout(this.logoutAtTimeout)
		if(this.logoutAtKickTimeout) clearTimeout(this.logoutAtKickTimeout)
		this.logoutAtDuration = null
		this.autologoutDuration = null
		this.autologoutTimeout = null
		this.logoutAtTimeout = null
		this.logoutAtKickTimeout = null
	},

	/**
	 * Called on user activity to refresh timeouts
	 */
	activity() {
		if(this.autologoutTimeout) {
			clearTimeout(this.autologoutTimeout)
			this.setAutologout(1)
		}
		if(this.logoutAtKickTimeout) {
			clearTimeout(this.logoutAtKickTimeout)
			this.setAutologout(2)
		}
	},

	/**
	 * Create activity timeouts
	 */
	setAutologout(type) {
		if(type == 1) this.autologoutTimeout = setTimeout(() => this.kickUser(), this.autologoutDuration)
		else if(type == 2) this.logoutAtKickTimeout = setTimeout(() => this.kickUser(), this.logoutAtDuration)
	},

	/**
	 * Logout user
	 */
	kickUser() {
		Auth.logout()
		this.autologoutTimeout = null
		this.logoutAtKickTimeout = null
	},

	/**
	 * Logout on window close
	 */
	logoutWindowClose() {
		Auth.logout()
	}
}

setTimeout(() => AccessControl.initialize())