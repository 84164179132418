import { Auth } from 'AuthBundle'
import { ViewServices } from 'InterfaceBundle'

export const UploadHelpers = {
	dropHandler(event, multi, fileCb, progressCb, loadCb, getBase64 = false) {
		let files = event.dataTransfer.files
		if(!multi) files = [files[0]]
		for(let file of files) {
			const fileOption = {
				name: file.name,
				uploading: true,
				progress: 0,
				id: 1
			}
			fileCb(fileOption)
			this.handleFile(file, fileOption, progressCb, loadCb, getBase64)
		}
	},
	upload(multi, mimeType, fileCb, progressCb, loadCb, getBase64 = false) {
		const options = {multiple: multi}
		if(mimeType) {
			if(!Array.isArray(mimeType)) mimeType = [mimeType]
			options.types = [{description: 'File', accept: {}}]
			for(let type of mimeType) options.types[0].accept[type] = []
		}
		window.showOpenFilePicker(options).then(fileHandles => {
			for(let fileHandle of fileHandles) {
				const fileOption = {
					name: fileHandle.name,
					uploading: true,
					progress: 0,
					id: 1
				}
				fileCb(true, fileOption)
				fileHandle.getFile().then(file => this.handleFile(file, fileOption, progressCb, loadCb, getBase64))
			}
		}).catch(() => fileCb(false))
	},
	handleFile(file, fileOption, progressCb, loadCb, getBase64 = false) {
		fileOption.lastModified = file.lastModified
		fileOption.name = file.name
		for(let icon in ViewServices.interfaceData.files.mimetypeIcons.data) {
			if(ViewServices.interfaceData.files.mimetypeIcons.data[icon].includes(file.type)) fileOption.icon = icon
		}
		if(!fileOption.icon) fileOption.icon = ViewServices.interfaceData.files.mimetypeIcons.default
		const fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = () => {
			if(getBase64) {
				fileOption.base64 = fileReader.result
				loadCb(fileOption)
			}
			else {
				//Request
				const request = new XMLHttpRequest()
				request.open('POST', '/api/file/')
				request.setRequestHeader('Authorization', 'Bearer ' + Auth.getToken())
				request.upload.addEventListener('progress', event => {
					if(event.total && event.loaded) fileOption.progress = Math.round((event.loaded / event.total) * 100)
					progressCb()
				})
				request.addEventListener('load', result => {
					if(result.target.status != 200) loadCb({error: true})
					else {
						if(fileOption) {
							fileOption.progress = 100
							fileOption.uploading = false
							fileOption.tmpName = JSON.parse(request.responseText).value
							loadCb(fileOption)
						}
					}
				})
				request.addEventListener('abort', () => loadCb({error: true}))
				request.addEventListener('error', () => loadCb({error: true}))
				request.send(fileReader.result)
			}
		}
	}
}

/**
 * Polyfill
 */
function showOpenFilePickerPolyfill(options) {
	return new Promise((resolve, reject) => {
		const input = document.createElement('input')
		let rejectTimeout = null
		input.type = 'file'
		input.multiple = options.multiple
		if(options.types) input.accept = Object.keys(options.types[0].accept).join(',')
		const rejectCb = () => {
			rejectTimeout = setTimeout(() => reject(), 300)
		}

		window.addEventListener('focus', rejectCb, {once: true})

		input.addEventListener('change', () => {
			window.removeEventListener('focus', rejectCb)
			if(rejectTimeout) clearTimeout(rejectTimeout)
			resolve(
				[...input.files].map((file) => {
					return {
						getFile: async () => new Promise(resolve => resolve(file)),
					}
				})
			)
		})


		input.click()
	})
}

if(typeof window.showOpenFilePicker !== 'function') window.showOpenFilePicker = showOpenFilePickerPolyfill
ViewServices.uploadHelpers = UploadHelpers