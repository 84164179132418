<script>
import { Router } from 'InterfaceBundle'
import { ModelExporter } from 'ImportBundle'

export default {
	name: 'BlListExport',
	inject: ['blList'],
	mounted() {
		this.blListId = ModelExporter.exportModels.length
		ModelExporter.exportModels.push(this.blList)
		this.id = Date.now() + this.blList.model + '|' + this.blListId
		this.route = Router.currentRoute.definition
		this.route.actions.secondary.push({
			id: this.id,
			type: 2,
			template: `<li style="padding: 0;"><button class="topMenuButtonSecondary" @click="services.modelExporter.open('` + this.blListId + `')"><i class="material-icons">import_export</i><span>{{ $t('Export') }}</span></button></li>`
		})
	},
	beforeUnmount() {
		this.route.actions.secondary = this.route.actions.secondary.filter(s => s.id != this.id)
		ModelExporter.exportModels.splice(this.blListId, 1)
	}
}
</script>