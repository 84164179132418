<template>
	<div :class="{errored: field.isErrored() && field.getTouched()}" class="wrapper" ref="field">
		<label>{{ field.label }}</label>
		<div class="container">
			<BlMap style="height: 300px; flex: 1;" @ready="mapReady($event)" />
			<div class="sidePanel">
				<BlButton class="dense outlined legend" icon="my_location" :label="$t('form.geo.myPosition')" style="width: 100%;" @click="setCurrentLocation()" />
				<template v-if="model">
					<div v-bl-input style="margin-top: 10px;">
						<label>{{ $t('form.geo.latitude') }}</label>
						<input type="number" step="0.0000001" v-model="model.lat" @change="setMarkerPosition()" @blur="field.setTouched()" />
					</div>
					<div v-bl-input style="margin-top: 10px;">
						<label>{{ $t('form.geo.longitude') }}</label>
						<input type="number" step="0.0000001" v-model="model.lng" @change="setMarkerPosition()" @blur="field.setTouched()" />
					</div>
				</template>
				<template v-else>
					<div v-bl-input style="margin-top: 10px;">
						<label>{{ $t('form.geo.latitude') }}</label>
						<input type="number" step="0.0000001" v-model="emptyLat" @change="setEmptyMarker()" @blur="field.setTouched()" />
					</div>
					<div v-bl-input style="margin-top: 10px;">
						<label>{{ $t('form.geo.longitude') }}</label>
						<input type="number" step="0.0000001" v-model="emptyLng" @change="setEmptyMarker()" @blur="field.setTouched()" />
					</div>
				</template>
				<div style="flex: 1;"></div>
				<BlButton class="dense outlined legend" icon="cancel" :label="$t('form.geo.clear')" style="width: 100%; margin-bottom: 10px;" @click="clear()" v-if="!field.options.required" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldGeo',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			emptyLat: null,
			emptyLng: null
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field?.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	},
	methods: {
		mapReady(map) {
			this.map = map
			this.map.setOptions({'mapTypeControl': true})
			this.map.setZoom(10)
			window.google.maps.event.addListener(this.map, 'click', e => {
				this.model = {
					lat: this.round(e.latLng.lat()),
					lng: this.round(e.latLng.lng())
				}
				this.field.setValue(this.model)
				this.field.setTouched()
				if(this.marker) {
					this.marker.setPosition(this.model)
					this.centerMap()
				}
				else this.setMarkerPosition()
			})
			if(this.model) this.createMarker()
		},
		createMarker() {
			this.marker = new window.google.maps.Marker({
				map: this.map,
				draggable: true,
				animation: window.google.maps.Animation.DROP,
				position: this.model
			})
			this.centerMap()
			window.google.maps.event.addListener(this.marker, 'rightclick', () => {
				this.clear()
			})
			window.google.maps.event.addListener(this.marker, 'dragend', () => {
				this.model = {
					lat: this.round(this.marker.getPosition().lat()),
					lng: this.round(this.marker.getPosition().lng())
				}
				this.field.setValue(this.model)
				this.field.setTouched()
				this.centerMap()
			})
			this.emptyLng = null
			this.emptyLat = null
		},
		setMarkerPosition() {
			this.createMarker()
			this.marker.setPosition(this.model)
			this.centerMap()
		},
		centerMap() {
			this.map.panTo(this.model)
		},
		setCurrentLocation() {
			if(navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(position => {
					this.model = {
						lat: this.round(position.coords.latitude),
						lng: this.round(position.coords.longitude)
					}
					this.field.setValue(this.model)
					this.setMarkerPosition()
				})
			}
		},
		round(value) {
			const multiplier = Math.pow(10, 7)
			return Math.round(value * multiplier) / multiplier
		},
		clear() {
			this.model = null
			this.field.setValue(null)
			if(this.marker) {
				this.marker.setMap(null)
				this.marker = null
			}
		},
		setEmptyMarker() {
			if(this.emptyLat && this.emptyLng) {
				this.model = {lat: this.emptyLat, lng: this.emptyLng}
				this.field.setValue(this.model)
				this.setMarkerPosition()
			}
		}
	}
}
</script>

<style scoped lang="scss">
	.wrapper:focus-within:not(.errored) {
		.container {
			outline: 2px solid var(--bl-primary);
		}

		> label {
			color: var(--bl-primary);
		}
	}

	.wrapper.errored {
		.container {
			outline: 2px solid var(--bl-error);
		}

		> label {
			color: var(--bl-error);
		}
	}

	.container {
		outline: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);
		position: relative;
		overflow: hidden;

		.sidePanel {
			width: 140px;
			padding: 5px;
			float: right;
			position: absolute;
			top: 0;
			right: 0;
			background-color: color-mix(in srgb, var(--bl-surface) 90%, rgba(0, 0, 0, 0));
			height: 100%;
			border-left: 1px solid var(--bl-border);
			backdrop-filter: blur(10px);
			display: flex;
			flex-direction: column;
		}
	}
</style>