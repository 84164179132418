<template>
	<div>
		<div style="display: flex;">
			<h1 style="flex: 1">
				<icon :style="{color: object.color}">{{ object.icon }}</icon>
				{{ object.label }}
			</h1>
			<div v-if="object.namespaceId" style="font-size: 14px;">
				<BlView model="internals.namespace" :modelId="object.namespaceId">
					<BlViewField name="bundle" hideEmpty hideLabel />
				</BlView>
			</div>
		</div>
		<div v-if="moveMode">
			<div class="bl-card error" v-if="showMoveError">Unable to move to itself or one of it's children</div>
			<InterfaceIntegratedEditorNavigation :moveMode="true" :tree="tree.nodes" />
		</div>
		<div v-else>
			<template v-if="object.namespaceId">
				<h3>New</h3>
				<button class="bl-large-button" style="--button-color: #666;" @click="select('namespace')">
					<icon>folder</icon>
					folder
				</button>
				<button class="bl-large-button" v-for="model in models" :key="model.key" :style="{'--button-color': model.color}" @click="select(model.model)">
					<icon>{{ model.icon }}</icon>
					{{ model.model.split('.')[1] }}
				</button>
				<hr />
				<h3>Rename</h3>
				<div style="display: flex; align-items: center;">
					<div v-bl-input>
						<input type="text" v-model="rename" @keyup.enter="doRename()" />
					</div>
					<BlButton label="Save" icon="edit" class="outlined primary" style="margin: 0 0 -4px 20px;" @click="doRename()" />
				</div>
				<hr />
				<h3>Permissions</h3>
				<p class="info">Mass set permissions on all objects of namespace (only direct children). Warning, this will override any permissions already set on objects</p>
				<div style="display: flex; align-items: center;">
					<div v-bl-input>
						<input type="text" v-model="permissions" @keyup.enter="setPermissions()" />
					</div>
					<BlButton label="Set" icon="accessibility" class="outlined primary" style="margin: 0 0 -4px 20px;" @click="setPermissions()" />
				</div>
				<hr />
			</template>
			<h3>Move</h3>
			<div style="display: flex; justify-content: end;">
				<BlButton label="Move" icon="drive_file_move" class="outlined" @click="setMoveMode()" />
			</div>
			<hr />
			<h3>Delete</h3>
			<div class="bl-card error" v-if="showDeleteError">Unable to delete non empty namespace</div>
			<div style="display: flex; justify-content: end;">
				<BlButton label="Delete" icon="delete" class="outlined error" @click="doDelete()" />
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'
import { Api } from 'ModelBundle'
import InterfaceIntegratedEditorNavigation from './InterfaceIntegratedEditorNavigation'

export default {
	name: 'InterfaceIntegratedEditorCreateDialog',
	components: {
		InterfaceIntegratedEditorNavigation
	},
	props: ['object', 'models', 'tree'],
	data() {
		return {
			rename: '',
			permissions: '',
			moveMode: false,
			showDeleteError: false,
			showMoveError: false
		}
	},
	created() {
		this.rename = this.object.label
	},
	methods: {
		select(name) {
			Dialog.close(true, true, name)
		},
		setMoveMode() {
			this.resetTree(this.tree)
			this.moveMode = true
		},
		resetTree(tree) {
			for(let node of tree.nodes) {
				if(node.nodes) this.resetTree(node)
				node.moveOpened = false
				node.moveIcon = 'folder'
			}
		},
		doRename() {
			if(!this.rename) return
			Api.put('form/internals.namespace/' + this.object.namespaceId, {submit: {label: this.rename, namespace: this.object.parent?.namespaceId}}).then(resp => {
				if(resp.__id) {
					const namespace = this.object
					namespace.label = this.rename
					Dialog.close(false, true)
				}
			})
		},
		doDelete() {
			if(this.object.namespaceId) {
				this.showDeleteError = false
				let req = {}
				req['context("result"):s.internal.install.editor.namespace.delete(' + this.object.namespaceId + ')'] = {data: 'local.result'}

				Api.post('api/structure/', req).then(resp => {
					if(resp.data) {
						const namespace = this.object
						namespace.parent.nodes = namespace.parent.nodes.filter(n => !n.namespaceId || n.namespaceId != this.object.namespaceId)
						Dialog.close(false, true)
					}
					else this.showDeleteError = true
				})
			}
			else {
				let req = {}
				req['context("result"):s.internal.install.editor.element.delete("' + this.object.model + '", ' + this.object.id + ')'] = {data: 'local.result'}

				Api.post('api/structure/', req).then(() => {
					const namespace = this.object
					namespace.parent.nodes = namespace.parent.nodes.filter(n => n != this.object)
					Dialog.close(false, true)
				})
			}
		},
		toggleItem(item) {
			item.moveOpened = !item.moveOpened
			item.moveIcon = item.moveOpened ? 'folder_open' : 'folder'
		},
		moveTo(target, event) {
			event.stopPropagation()
			event.preventDefault()
			this.showMoveError = false
			//Do not allow move namespace to itself or one of it's children
			if(this.object.namespaceId) {
				let obj = target
				do {
					if(obj.namespaceId == this.object.namespaceId) {
						this.showMoveError = true
						return false
					}
					obj = obj.parent
				} while(obj)
			}
			let args = {
				model: this.object.model,
				id: this.object.id,
				to: target.namespaceId
			}
			if(!args.model) {
				args.model = 'internals.namespace'
				args.id = this.object.namespaceId
			}
			let req = {}
			req['context("result"):s.internal.install.editor.move("' + args.model + '", ' + args.id + ', ' + args.to + ')'] = {data: 'local.result'}
			Api.post('api/structure/', req).then(resp => {
				if(resp.data) {
					const object = this.object
					object.parent.nodes = object.parent.nodes.filter(n => n != object)
					if(target.opened || args.model == 'internals.namespace') {
						target.nodes.push(object)
						object.parent = target
					}
					Dialog.close(false, true)
				}
				else this.showMoveError = true
			})
		},
		setPermissions() {
			let req = {}
			req['context("result"):s.internal.install.editor.permissions.set("' + this.object.namespaceId + '", "' + this.permissions + '")'] = {
				result: 'local.result'
			}
			Api.post('/api/structure/', req)
		}
	},
	provide() {
		return {
			addElement: this.moveTo,
			toggleItem: this.toggleItem
		}
	}
}
</script>

<style scoped lang="scss">
	h1 {
		display: flex;
		align-items: center;

		icon {
			margin-right: 10px;
		}
	}

	hr {
		margin: 30px 0 10px 0;
	}

	h3 {
		font-weight: 600;
		font-family: 'Product sans';
		color: var(--bl-legend);
		font-size: 14px;
	}

	button.bl-large-button {
		width: 100px;
		color: var(--button-color);

		icon {
			color: var(--button-color);
		}
	}

	button.bl-large-button::before {
		border-color: var(--button-color);
	}

	button.bl-large-button::after {
		background-color: var(--button-color);
	}

	p.info {
		color: var(--bl-legend);
		font-size: 12px;
	}
</style>