/**
 * Dialog instance
 */
export class DialogInstance {
	/**
	 * Alert dialog (only one button)
	 */
	static TYPE_ALERT = 1

	/**
	 * Confirm dialog
	 */
	static TYPE_CONFIRM = 2

	/**
	 * Prompt dialog (show simple input)
	 */
	static TYPE_PROMPT = 3

	/**
	 * Custom dialog with component
	 */
	static TYPE_CUSTOM = 4

	/**
	 * Create dialog
	 * @param  {integer} type
	 */
	constructor(type) {
		this.type = type
		this._properties = ['title', 'content', 'accept', 'cancel', 'required', 'promptLabel', 'promptType', 'promptValue', 'closeButton', 'handleRouting', 'fullScreen', 'component', 'componentProps', 'class']
	}

	/**
	 * Bind data and check properties, set defaults
	 * @param  {mixed} data
	 */
	bindData(data) {
		let requiredProperties = []
		if(this.type == DialogInstance.TYPE_ALERT) {
			this.accept = window.$t('interface.dialog.accept')
			requiredProperties = ['title']
		}
		else if(this.type == DialogInstance.TYPE_CONFIRM) {
			this.accept = window.$t('interface.dialog.accept')
			this.cancel = window.$t('interface.dialog.cancel')
			requiredProperties = ['title']
		}
		else if(this.type == DialogInstance.TYPE_PROMPT) {
			this.accept = window.$t('interface.dialog.accept')
			this.promptType = 'text'
			requiredProperties = ['promptLabel']
		}
		else requiredProperties = ['component']

		for(let key in data) {
			if(!this._properties.includes(key)) throw Error('Property "' + key + '" is not valid')
			this[key] = data[key]
		}


		for(let key of requiredProperties) {
			if(!this[key]) throw Error('Property "' + key + '" is required')
		}

		if(!this.required) this.required = false
		if(typeof this.closeButton === 'undefined' && !this.required) this.closeButton = true
		if(typeof this.handleRouting === 'undefined') this.handleRouting = true
	}
}