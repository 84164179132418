<template>
	<div class="bl-input sig-input" :class="{'bl-input__value': field.value,errored: field.isErrored() && field.getTouched()}">
		<label>{{ field.label }}</label>
		<div class="sig-container" @click="openDialog()" :tabindex="tabindex">
			<img v-if="field.value" class="preview" :src="getSvg" />
		</div>
	</div>
</template>
<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldSignature',
	props: ['field', 'tabindex'],
	created() {
		this.field.emitter.focus.subscribe(() => this.openDialog())
	},
	methods: {
		openDialog() {
			Dialog.custom({
				component: 'BlFormFieldSignatureDialog',
				componentProps: {field: this.field},
				required: true
			}).then(resp => this.field.setValue(resp))
		}
	},
	computed: {
		getSvg() {
			if(this.field.value) {
				let [width, height, path] = this.field.value.split(',')
				return `data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"><path d="${path}" fill="none" stroke="black" fill-opacity="0"/></svg>`
			}
			return ''
		}
	}
}
</script>

<style lang="scss" scoped>
.sig-container {
	position: static;
	cursor: pointer;
	padding: 8px;
	min-height: 16px;
	max-height: 76px;
	border-radius: var(--bl-border-radius);
	width: 100%;
	min-width: 100px;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.2s, color 0.2s;
	box-shadow: 0 0 0 1px var(--bl-border);
	border: none;
	font-family: Roboto;
	font-size: 14px;
	outline: none;
	background-color: var(--bl-surface);
	color: var(--bl-on-surface);
}

.errored .sig-container {
	box-shadow: 0 0 0 2px var(--bl-error);
}

.sig-input {
	background-color: var(--bl-surface);
	color: var(--bl-on-surface);
}

.sig-input label {
	pointer-events: none;
}

.preview {
	position: relative;
	height: 76px;
}
</style>