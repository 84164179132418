<template>
	<BlMenu>
		<template #hook>
			<button class="bl-icon-button" type="button" v-bl-tooltip="'Emojis'" tabindex="-1">sentiment_satisfied</button>
		</template>
		<div>
			<ul class="categories">
				<li v-for="category in categories" :class="{active: category.id == selectedCategory}" :key="category" @click="$event.stopPropagation(); setCategory(category)" v-html="category.emoticon" v-bl-tooltip="category.name"></li>
			</ul>
			<div class="listContainer">
				<button class="bl-icon-button" v-for="emoji in emojis" :key="emoji.html" v-html="emoji.html" @click="pick(emoji.html)"></button>
			</div>
		</div>
	</BlMenu>
</template>

<script>
export default {
	name: 'BlEmojiPicker',
	emits: ['value'],
	data() {
		return {
			all: [],
			emojis: [],
			selectedCategory: null,
			categories: []
		}
	},
	async created() {
		let json = await fetch('https://api.mixsystems.fr/emoticons')
		this.all = await json.json()
		this.categories = this.all.categories
		this.all = this.all.emojis
		let i = 12
		for(let item of this.all) {
			item.id = i
			i++
		}
		this.setCategory({id: 1})
	},
	methods: {
		pick(value) {
			this.$emit('value', value)
		},
		setCategory(category) {
			this.selectedCategory = category.id
			this.emojis = this.all.filter(e => e.category == this.selectedCategory)
		}
	}
}
</script>

<style scoped lang="scss">
	.listContainer {
		overflow-y: scroll;
		width: 275px;
		height: 250px;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;

		button {
			font-family: Roboto;
			padding: 5px;
			font-size: 16px;
			display: block;
			line-height: 20px;
			width: 30px;
		}

		button::after {
			display: none !important;
		}
	}

	.categories {
		display: flex;
		border-bottom: 1px solid var(--bl-border);

		li {
			border-bottom: 2px solid transparent;
			min-height: 30px;
			padding: 0 10px;
		}

		li.active {
			border-bottom-color: var(--bl-secondary);
		}
	}
</style>