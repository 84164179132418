<template>
	<ul v-if="items?.nodes">
		<li v-for="item in items.nodes" :key="item.name">
			<div :class="{selected: item.selected}" @click="item.selected = !item.selected; methods.update()">
				<button v-if="item.loadChildren" class="bl-icon-button" @click="$event.stopPropagation(); methods.load(item)" :class="{opened: item.nodes}">chevron_right</button>
				<label>{{ $t(item.label) }}</label>
				<em>{{ item.type }}</em>
				<span style="flex: 1"></span>
				<input v-model="item.selected" type="checkbox" class="bl-checkbox" />
			</div>
			<BlModelExporterTree :items="item" :methods="methods" />
		</li>
	</ul>
</template>

<script>
export default {
	name: 'BlModelExporterTree',
	props: ['items', 'methods']
}
</script>

<style scoped lang="scss">
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-left: 15px;
		padding-left: 22px;
		margin-top: 10px;
		border-left: 1px solid var(--bl-border);

		li {
			> div {
				transition: all .1s;
				min-height: 28px;
				margin-top: 2px;
				display: flex;
				align-items: center;
				border-radius: var(--bl-border-radius);
				padding: 0 10px 0 7px;

				> label {
				}

				> em {
					border: 1px solid var(--bl-border);
					border-radius: var(--bl-border-radius);
					color: var(--bl-legend);
					font-style: normal;
					font-size: 10px;
					padding: 1px 5px;
					margin-left: 5px;
				}

				input {
					margin-top: -1px;
				}
			}

			> div:hover {
				background-color: var(--bl-background);
			}

			> div.selected {
				background-color: var(--bl-selected-bg);

				> label {
					color: var(--bl-selected-fg);
					font-weight: 500;
				}
			}
		}
	}

	.bl-icon-button {
		padding: 0;
		font-size: 18px;
		margin-right: 5px;
		transition: transform .2s;
	}

	.bl-icon-button.opened {
		transform: rotate(90deg);
	}
</style>