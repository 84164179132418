<template>
	<BlMenu v-if="from <= 0">
		<template #hook>
			<button class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.fontSize')">format_size</button>
		</template>
		<ul>
			<li v-for="size in fontSizes" :key="size.value">
				<button class="menuButton" @click="command('fontSize', size.value)" :class="{active: isActive('fontSize', size.value)}"><font :size="size.value">{{ $t('interface.wysiwyg.fontSize' + size.value) }}</font></button>
			</li>
		</ul>
	</BlMenu>
	<BlMenu v-if="from <= 1">
		<template #hook>
			<button class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.fontFamily')">font_download</button>
		</template>
		<ul>
			<li v-for="family in fontFamilies" :key="family.value">
				<button class="menuButton" @click="command('fontName', family.value)" :class="{active: isActive('fontName', family.value)}"><font :face="family.value">{{ $t(family.label) }}</font></button>
			</li>
		</ul>
	</BlMenu>
	<BlMenu v-if="from <= 2">
		<template #hook>
			<button class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.color')">format_color_text</button>
		</template>
		<div style="display: flex;">
			<table class="colorPicker">
				<thead>
					<tr><th colspan="8">{{ $t('interface.wysiwyg.backColor') }}</th></tr>
				</thead>
				<tbody v-for="(colorGroup, i) of colors" :key="i">
					<tr v-for="(colorLine, j) of colorGroup" :key="j">
						<td v-for="color of colorLine" :key="color">
							<button :style="{backgroundColor: color}" @click="command('backColor', color)" tabindex="-1" :class="{active: isActive('backColor', color)}"><i class="material-icons">check</i></button>
						</td>
					</tr>
				</tbody>
			</table>
			<table class="colorPicker">
				<thead>
					<tr><th colspan="8">{{ $t('interface.wysiwyg.textColor') }}</th></tr>
				</thead>
				<tbody v-for="(colorGroup, i) of colors" :key="i">
					<tr v-for="(colorLine, j) of colorGroup" :key="j">
						<td v-for="color of colorLine" :key="color">
							<button :style="{backgroundColor: color}" @click="command('foreColor', color)" tabindex="-1" :class="{active: isActive('foreColor', color)}"><i class="material-icons">check</i></button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</BlMenu>
	<button v-if="from <= 3" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.bold')" @click="command('bold')" :class="{active: isActive('bold')}">format_bold</button>
	<button v-if="from <= 4" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.italic')" @click="command('italic')" :class="{active: isActive('italic')}">format_italic</button>
	<button v-if="from <= 5" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.underline')" @click="command('underline')" :class="{active: isActive('underline')}">format_underlined</button>
	<button v-if="from <= 6" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.strikethrough')" @click="command('strikeThrough')" :class="{active: isActive('strikeThrough')}">format_strikethrough</button>
	<button v-if="from <= 7" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.textCenter')" @click="command('justifyCenter')" :class="{active: isActive('justifyCenter')}">format_align_center</button>
	<button v-if="from <= 8" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.textJustified')" @click="command('justifyFull')" :class="{active: isActive('justifyFull')}">format_align_justify</button>
	<button v-if="from <= 9" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.textLeft')" @click="command('justifyLeft')" :class="{active: isActive('justifyLeft')}">format_align_left</button>
	<button v-if="from <= 10" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.textRight')" @click="command('justifyRight')" :class="{active: isActive('justifyRight')}">format_align_right</button>
	<button v-if="from <= 11" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.bulletList')" @click="command('insertUnorderedList')" :class="{active: isActive('insertUnorderedList')}">format_list_bulleted</button>
	<button v-if="from <= 12" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.numberedList')" @click="command('insertOrderedList')" :class="{active: isActive('insertOrderedList')}">format_list_numbered</button>
	<button v-if="from <= 13" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.link')" @click="link()">add_link</button>
	<button v-if="from <= 14" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.image')" @click="image()">add_photo_alternate</button>
	<button v-if="from <= 15" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.quote')" @click="command('formatBlock', 'blockquote')">format_quote</button>
	<button v-if="from <= 16" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.indent')" @click="command('indent')">format_indent_increase</button>
	<button v-if="from <= 17" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.outdent')" @click="command('outdent')">format_indent_decrease</button>
	<button v-if="from <= 18" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.undo')" @click="command('undo')">undo</button>
	<button v-if="from <= 19" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.redo')" @click="command('redo')">redo</button>
	<button v-if="from <= 20" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.clear')" @click="command('removeFormat')">format_clear</button>
	<button v-if="from <= 21 && fullscreen" class="bl-icon-button toolbarItem separator" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.closeFullscreen')" @click="command('fullscreen')">close_fullscreen</button>
	<button v-if="from <= 21 && !fullscreen" class="bl-icon-button toolbarItem" tabindex="-1" type="button" v-bl-tooltip="$t('interface.wysiwyg.fullscreen')" @click="command('fullscreen')">open_in_full</button>
</template>

<script>
export default {
	name: 'InterfaceWysiwygElements',
	props: ['fullscreen', 'command', 'from', 'image', 'link'],
	data() {
		return {
			fontSizes: [
				{label: 'Small', value: 1},
				{label: 'Normal', value: 2},
				{label: 'Large', value: 4},
				{label: 'Huge', value: 6}
			],
			fontFamilies: [
				{label: 'Sans serif', value: 'Roboto'},
				{label: 'Serif', value: '"times new roman"'},
				{label: 'Fixed Width', value: 'monospace'},
				{label: 'Wide', value: '"arial black"'},
				{label: 'Narrow', value: '"arial narrow"'},
				{label: 'Comic Sans MS', value: '"comic sans ms"'},
				{label: 'Garamond', value: 'garamond'},
				{label: 'Georgia', value: 'georgia'},
				{label: 'Tahoma', value: 'tahoma'},
				{label: 'Trebuchet MS', value: '"trebuchet ms"'},
				{label: 'Verdana', value: 'verdana'},
			],
			colors: [
				[
					['rgb(0, 0, 0)', 'rgb(68, 68, 68)', 'rgb(102, 102, 102)', 'rgb(153, 153, 153)', 'rgb(204, 204, 204)', 'rgb(238, 238, 238)', 'rgb(243, 243, 243)', 'rgb(255, 255, 255)']
				], [
					['rgb(255, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 255, 0)', 'rgb(0, 255, 255)', 'rgb(0, 0, 255)', 'rgb(153, 0, 255)', 'rgb(255, 0, 255)']
				], [
					['rgb(244, 204, 204)', 'rgb(252, 229, 205)', 'rgb(255, 242, 204)', 'rgb(217, 234, 211)', 'rgb(208, 224, 227)', 'rgb(207, 226, 243)', 'rgb(217, 210, 233)', 'rgb(234, 209, 220)'],
					['rgb(234, 153, 153)', 'rgb(249, 203, 156)', 'rgb(255, 229, 153)', 'rgb(182, 215, 168)', 'rgb(162, 196, 201)', 'rgb(159, 197, 232)', 'rgb(180, 167, 214)', 'rgb(213, 166, 189)'],
					['rgb(224, 102, 102)', 'rgb(246, 178, 107)', 'rgb(255, 217, 102)', 'rgb(147, 196, 125)', 'rgb(118, 165, 175)', 'rgb(111, 168, 220)', 'rgb(142, 124, 195)', 'rgb(194, 123, 160)'],
					['rgb(204, 0, 0)', 'rgb(230, 145, 56)', 'rgb(241, 194, 50)', 'rgb(106, 168, 79)', 'rgb(69, 129, 142)', 'rgb(61, 133, 198)', 'rgb(103, 78, 167)', 'rgb(166, 77, 121)'],
					['rgb(153, 0, 0)', 'rgb(180, 95, 6)', 'rgb(191, 144, 0)', 'rgb(56, 118, 29)', 'rgb(19, 79, 92)', 'rgb(11, 83, 148)', 'rgb(53, 28, 117)', 'rgb(116, 27, 71)'],
					['rgb(102, 0, 0)', 'rgb(120, 63, 4)', 'rgb(127, 96, 0)', 'rgb(39, 78, 19)', 'rgb(12, 52, 61)', 'rgb(7, 55, 99)', 'rgb(32, 18, 77)', 'rgb(76, 17, 48)']
				]
			]
		}
	},
	methods: {
		isActive(command, arg = null) {
			if(arg) return document.queryCommandValue(command) == arg
			else return document.queryCommandState(command)
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-icon-button {
		margin: 0 -1px;
		font-size: 22px;
		padding: 4px;
		color: var(--bl-legend);
		width: 33px;
		min-width: 33px;
		height: 33px;
		line-height: 27px;
		overflow: hidden;
		position: relative;
	}

	.bl-icon-button.active {
		color: var(--bl-secondary);
		background-color: var(--bl-border);
		border-radius: var(--bl-border-radius);
	}

	.bl-icon-button:active {
		transform: none;
	}

	.separator.bl-icon-button {
		margin-left: 16px;
		overflow: visible;
	}

	.separator::before {
		content: ' ';
		display: block;
		border-right: 1px solid var(--bl-border);
		margin: 1px 0 0 -13px;
		position: absolute;
		width: 1px;
		height: 23px;
	}

	button.menuButton {
		background-color: transparent;
		border: 0;
		display: block;
		width: 100%;
		height: 100%;
		cursor: pointer;
		text-align: left;
		padding: 0 15px;
	}

	li > button.menuButton {
		margin-left: -15px;
		width: calc(100% + 30px);
		margin-right: -15px;
		height: 40px;
	}

	button.menuButton.active {
		background-color: var(--bl-background);
		color: var(--bl-secondary);
	}

	.colorPicker {
		margin: 10px;
		border-collapse: collapse;

		thead tr th {
			font-weight: normal;
			font-size: 12px;
			color: var(--bl-legend);
			padding-bottom: 5px;
		}

		tbody:not(:nth-child(2))::before {
			content: '';
			display: block;
			height: 7px;
		}

		tbody tr {
			td {
				padding: 0;
			}

			td button {
				cursor: pointer;
				width: 18px;
				height: 18px;
				border-radius: 5px;
				border: 1px solid var(--bl-surface);
				transition: all .1s;
				display: block;

				i {
					opacity: 0;
					pointer-events: none;
					position: absolute;
					font-size: 20px;
					margin-top: -9px;
					margin-left: -8px;
					display: block;
					color: var(--bl-secondary);
				}
			}

			td button:hover {
				border: 1px solid var(--bl-secondary);
				transform: scale(1.2);
			}

			td button.active {
				border: 1px solid var(--bl-secondary);

				i {
					opacity: 1;
				}
			}
		}
	}
</style>