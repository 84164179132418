<template>
	<div class="fileView">
		<div class="topBarShadow" />
		<div class="topBar" v-if="fileName">
			<img :src="'https://static.mixsuite.fr/file_icons/' + icon + '.svg'" />
			<h4>{{ fileName }}</h4>
			<button class="bl-icon-button" v-bl-tooltip="'Download'" @click.stop="download()">download</button>
			<button class="bl-icon-button" v-bl-tooltip="'Close'" @click.stop="close()">close</button>
		</div>
		<div class="topBar" v-else>
			<h5 v-for="name in fileNames" :key="name">
				<img src="https://static.mixsuite.fr/file_icons/pdf_color.svg" />
				<span>{{ name }}</span>
			</h5>
			<h5 v-if="moreNames" class="more">
				<span>{{ moreNames }} {{ $t('more') }}</span>
			</h5>
			<span style="flex: 1"></span>
			<button class="bl-icon-button" v-bl-tooltip="'Download'" @click.stop="download()">download</button>
			<button class="bl-icon-button" v-bl-tooltip="'Close'" @click.stop="close()">close</button>
		</div>
		<div class="bl-loader-line" v-if="!iframeLoaded" style="z-index: 2;"></div>
		<iframe ref="iframe" v-if="iframeSrc" :src="iframeSrc" />
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'
import { Api } from 'ModelBundle'
import { Document } from '../services/Document'

export default {
	name: 'BlDocumentDialogPreview',
	props: ['src', 'raw'],
	data() {
		return {
			iframeLoaded: false,
			fileName: null,
			fileNames: [],
			moreNames: 0,
			extension: null,
			icon: 'pdf_color',
			iframeSrc: null
		}
	},
	methods: {
		close() {
			Dialog.close()
		},
		download() {
			this.fileName ? Document.download(this.raw[0], this.raw[1]) : Document.downloadMulti(this.raw[0], JSON.parse(this.raw[1].data))
		}
	},
	mounted() {
		Api.get(this.src.replace('/api/document/multi/get/', 'document/multi/name/').replace('/api/document/get/', 'document/name/')).then(resp => {
			if(Array.isArray(resp.name)) {
				let i = 0
				for(let name of resp.name) {
					i++
					if(i > 3) this.moreNames++
					else this.fileNames.push(name + '.pdf')
				}
				this.iframeSrc = this.src
			}
			else {
				this.extension = resp.extension[0]
				this.icon = resp.extension[1]
				this.fileName = resp.name + '.' + this.extension
				if(resp.extension.length == 3) this.iframeSrc = resp.extension[2].replace('{{url}}', encodeURIComponent(location.protocol + '//' + location.host + this.src + '&uid=' + crypto.randomUUID()))//Add random uid to circonvent caching
				else this.iframeSrc = this.src
			}
			this.$nextTick(() => this.$refs.iframe.onload = () => this.iframeLoaded = true)
		})
	}
}
</script>

<style scoped lang="scss">
	.fileView {
		margin: -25px -30px;
		width: calc(100% + 55px);
		height: calc(100% + 50px);
		display: flex;
		flex-direction: column;
		position: relative;

		iframe {
			flex: 1;
			border: none;
			background-color: #525659;
		}

		.topBar {
			box-shadow: 0px 0px 12px 4px rgba(0,0,0,0.4);
			z-index: 1;
			display: flex;
			background-color: var(--bl-surface);
			align-items: center;

			img {
				margin: 20px;
			}

			h4 {
				flex: 1;
				font-size: 18px;
				font-family: Product sans;
				margin: 0;
				padding: 0;
			}

			h5:first-of-type {
				margin-left: 11px;
			}

			h5 {
				border: 1px solid var(--bl-border);
				border-radius: var(--bl-border-radius);
				margin: 8px 5px;
				padding: 10px 8px;
				font-family: Product sans;
				font-size: 16px;
				display: flex;
				align-items: center;

				img {
					margin: 0 10px 0 0;
				}
			}

			h5.more {
				color: var(--bl-legend);
				background-color: var(--bl-background);
			}

			button {
				margin-right: 10px;
			}
		}
	}
</style>