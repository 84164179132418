<template>
	<InterfaceUpgrading v-if="upgrading" />
	<InterfaceGlobalLoading v-if="globalLoading && !upgrading" />
	<div v-if="isAuthenticated && ready && !upgrading" style="height: 100%;">
		<InterfaceLoading v-if="loading" />
		<component v-if="interfaceContainer && !loading" :is="interfaceContainer" />
	</div>
	<AuthLoginForm v-if="!isAuthenticated && ready && !upgrading" />
	<BlDialogContainer v-if="ready && !upgrading" />
</template>

<script>
import { Auth } from 'Bundles/AuthBundle/services/Auth.js'
import { RefreshToken } from 'Bundles/AuthBundle/services/RefreshToken.js'
import { ViewLoader } from 'Bundles/InterfaceBundle/services/ViewLoader.js'
import { Variables } from 'Bundles/InterfaceBundle/services/Variables.js'
import { Realtime } from 'Bundles/InterfaceBundle/services/Realtime.js'
import { ViewServices } from 'Bundles/InterfaceBundle/services/ViewServices.js'

export default {
	name: 'App',
	data() {
		return {
			isAuthenticated: null,
			ready: false,
			loading: true,
			globalLoading: Variables.globalLoading,
			upgrading: Variables.upgrading,
			interfaceContainer: 'InterfaceRouterContainer'
		}
	},
	methods: {
		mountViews() {
			this.isAuthenticated = true
			this.loading = true
			ViewLoader.mountViews().then(() => {
				this._updateSubscription = Realtime.listen('interface.update')
				this._updateSubscription.then(data => ViewLoader.handleVersion(data.version))
				this.loading = false
				RefreshToken.initialize()
				this.setInterfaceContainer()
			})
		},
		unmountViews() {
			if(this._updateSubscription) this._updateSubscription.unsubscribe()
			this.isAuthenticated = false
			ViewLoader.unmountViews()
		},
		setInterfaceContainer() {
			for(let item of ViewLoader.data.containerStrategies) {
				let interfaceContainerCondition = null
				eval('interfaceContainerCondition = function(services) { ' + item.condition.replaceAll('this.services', 'services') + '}')
				if(interfaceContainerCondition(ViewServices)) {
					this.interfaceContainer = item.container
					break
				}
			}
		}
	},
	created() {
		if(Auth.isAuthenticated()) this.mountViews()
		Auth.events.login.subscribe(() => this.mountViews())
		Auth.events.logout.subscribe(() => this.unmountViews())
		Variables.globalLoadingEvent.subscribe(() => this.globalLoading = Variables.globalLoading)
		Variables.upgradingEvent.subscribe(() => this.upgrading = Variables.upgrading)
	},
	mounted() {
		//Required because of component registration
		setTimeout(() => {
			this.ready = true
			Variables.initialize()
		})
	}
}

</script>

<style scoped>
.mainContainer {
	display:  flex;
	height: 100%;
}
</style>