export const Grid = {
	definition: [
		['s', 600],
		['m', 900],
		['l', 1350],
		['xl', 1900]
	],

	/**
	 * Get if grid is shown for size
	 */
	matches(grid, size = null) {
		if(!size) size = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		return Grid.definition.find(d => d[0] == grid)[1] < size
	},
}