/**
 * Makes the element draggable, pass an object for options :
 * @param handle {String} optionnal, query selector for the handler element
 * @param model {Object} optionnal, object to set position properties on
 * @param axis {String} optionnal, constraint to "x" or "y" axis, defaults to both
 * @param xProperty {String} optionnal, name of the property on the object for the x axis, defaults to "x"
 * @param yProperty {String} optionnal, name of the property on the object for the y axis, defaults to "y"
 * @param moved {callable} optionnal, function to call when object is moved
 * @param dropped {callable} optionnal, function to call when object is dropped
 */
export default {
	mounted(el, binding) {
		if(binding.value === false) return
		if(typeof binding.value !== 'object') binding.value = {}
		let options = binding.value
		if(!options.copy) el.style.position = 'absolute'
		let handle = options.handle ? el.querySelector(options.handle) : el
		let axis = options.axis ? options.axis : 'both'
		let xAxis = ['both', 'x'].includes(axis)
		let yAxis = ['both', 'y'].includes(axis)
		handle.style.cursor = 'grab'

		if(!binding.value.model) binding.value.model = {}
		if(!binding.value.xProperty) binding.value.xProperty = 'x'
		if(!binding.value.yProperty) binding.value.yProperty = 'y'

		if(xAxis) {
			if(!binding.value.model[binding.value.xProperty]) binding.value.model[binding.value.xProperty] = 0
			el.style.left = binding.value.model[binding.value.xProperty] + 'px'
		}
		if(yAxis) {
			if(!binding.value.model[binding.value.yProperty]) binding.value.model[binding.value.yProperty] = 0
			el.style.top = binding.value.model[binding.value.yProperty] + 'px'
		}
		el.blDraggableOptions = options

		handle.addEventListener('mousedown', ev => {
			options = el.blDraggableOptions
			if(options.copy) {
				options.originElement = el
				el = el.cloneNode(true)
				el.style.position = 'absolute'
				el.classList.add('dragged')
				options.originElement.parentNode.appendChild(el)
				//Get closest relative parent
				let parent = el.parentNode
				while(parent.parentNode) {
					if(parent == document.body || window.getComputedStyle(parent).position == 'relative') break
					parent = parent.parentNode
				}
				let top = options.originElement.getBoundingClientRect().y - parent.getBoundingClientRect().y
				let left = options.originElement.getBoundingClientRect().x - parent.getBoundingClientRect().x
				el.style.top = top + 'px'
				el.style.left = left + 'px'

			}

			handle.style.cursor = 'grabbing'
			ev.stopPropagation()
			ev.preventDefault()
			
			let initialPosition = {
				x: parseInt(el.style.left.substring(0, el.style.left.length - 2)),
				y: parseInt(el.style.top.substring(0, el.style.top.length - 2))
			}
			let delta = {
				x: ev.x - initialPosition.x,
				y: ev.y - initialPosition.y
			}
			let maxValues = {}
			if(options.maxX) maxValues.x = options.maxX - el.offsetWidth
			if(options.maxY) maxValues.y = options.maxY - el.offsetHeight

			if(typeof options.start == 'function') options.start()

			let mouseMoveEvent = ev => {
				event.preventDefault()
				event.stopPropagation()
				let moveArgs = {x: 0, y: 0}
				if(xAxis) {
					let position = ev.x - delta.x
					if(options.snap && !ev.shiftKey) position = Math.round(position / options.snap) * options.snap
					if(maxValues.x && position > maxValues.x) position = maxValues.x
					if(position < 0) position = 0
					moveArgs.x = position - initialPosition.x
					binding.value.model[binding.value.xProperty] = position
					el.style.left = position + 'px'
				}
				if(yAxis) {
					let position = ev.y - delta.y
					if(options.snap && !ev.shiftKey) position = Math.round(position / options.snap) * options.snap
					if(maxValues.y && position > maxValues.y) position = maxValues.y
					if(position < 0) position = 0
					moveArgs.y = position - initialPosition.y
					binding.value.model[binding.value.yProperty] = position
					el.style.top = position + 'px'
				}
				if(typeof options.moved == 'function') options.moved(ev, moveArgs)
			}
			let mouseUpEvent = () => {
				handle.style.cursor = 'grab'
				window.removeEventListener('mousemove', mouseMoveEvent)
				window.removeEventListener('mouseup', mouseUpEvent)
				if(typeof options.dropped == 'function') options.dropped()

				if(options.copy) {
					options.originElement.parentNode.removeChild(el)
					el = options.originElement
				}
			}

			window.addEventListener('mousemove', mouseMoveEvent)
			window.addEventListener('mouseup', mouseUpEvent)

			return false
		})
	},
	updated(el, binding) {
		if(binding.value && binding.value.model) {
			el.style.left = binding.value.model[binding.value.xProperty] + 'px'
			el.style.top = binding.value.model[binding.value.yProperty] + 'px'
		}
		el.blDraggableOptions = binding.value ? binding.value : {}
	}
}