<template>
	<div v-if="hide">
		<h5>{{ $t('auth.userSettings.deviceType') }}</h5>
		<div style="margin-bottom: 15px;" v-if="editMode">
			<BlSelect v-if="types" :data="types" v-model="value" />
		</div>
		<div class="viewContainer" v-else>
			<icon>devices</icon>
			<span>{{ label }}</span>
			<button class="bl-icon-button" v-if="canEdit" @click="editMode = true">edit</button>
		</div>
	</div>
</template>

<script>
import { Auth } from 'AuthBundle'
import { ViewServices } from 'InterfaceBundle'

export default {
	name: 'BlDeviceTypeSelector',
	data() {
		return {
			types: null,
			value: null,
			label: null,
			canEdit: false,
			editMode: false,
			hide: false
		}
	},
	watch: {
		value() {
			if(Auth.getDeviceType() != this.value) {
				Auth.setDeviceType(this.value)
				for(let item in this.types) {
					if(this.types[item] == this.value) this.label = item
				}
			}
			this.editMode = false
		}
	},
	created() {
		this.canEdit = Auth.isGranted('AUTH_SETDEVICETYPE')
		this.types = ViewServices.interfaceData.accesscontrol.device.labels
		if(['[]', '{}'].includes(JSON.stringify(this.types))) return
		if(Auth.getDeviceType()) this.value = Auth.getDeviceType()
		else this.value = ViewServices.interfaceData.accesscontrol.device.default
		if(this.value) {
			for(let typeLabel in this.types) {
				if(this.value == this.types[typeLabel]) {
					this.label = typeLabel
					break
				}
			}
			this.hide = true
		}
	}
}
</script>

<style scoped lang="scss">
	.viewContainer {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		icon {
			color: var(--bl-legend);
			margin-right: 6px;
		}

		span {
			flex: 1;
		}

		button.bl-icon-button {
			font-size: 18px;
			padding: 0;
		}
	}
</style>