<template>
	<div v-bl-input class="bl-input-phone" :class="{errored: field.isErrored() && field.getTouched(), showFlag: country && country != userCountry}">
		<div class="prefix imageContainer" v-if="country && country != userCountry">
			<img :src="'https://api.mixsystems.fr/flagimage?country=' + country" />
		</div>
		<label>{{ field.label }}</label>
		<input type="tel" v-mask="{mask: mask, tokens: maskTokens}" v-model="model" @change="field.setValue(model)" @keyup="autoComplete()" @blur="field.setTouched()" ref="field" :disabled="field.options.disabled" :tabindex="tabindex" />
	</div>
</template>

<script>
import { ViewLoader } from 'InterfaceBundle'
import { mask } from 'vue-the-mask'

export default {
	name: 'BlFormFieldPhone',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			defaultMask: 'FFFFFFFFFFFFFFFFFF',
			mask: '',
			lastRequestModel: '',
			maskTokens: {
				F: {
					pattern: /[0-9+]/
				}
			},
			country: null,
			userCountry: ViewLoader.data.userPreferences.country.toLowerCase()
		}
	},
	created() {
		this.country = this.userCountry
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => {
			this.model = this.field.value
			this.autoComplete()
		})
		this.autoComplete()
	},
	methods: {
		autoComplete() {
			const from = this.model
			if(from && this.country != this.userCountry && from.length == 1 && from.substr(0, 1) != '+') {
				this.country = this.userCountry
				this.mask = this.defaultMask
			}
			else if(!from || !from.length) {
				this.country = this.userCountry
				this.mask = this.defaultMask
				return
			}
			if(from && from != this.lastRequestModel) {
				this.lastRequestModel = from
				fetch('https://api.mixsystems.fr/phoneformat?data=' + encodeURIComponent(from) + '&country=' + ViewLoader.data.userPreferences.country).then(r => r.json()).then(data => {
					this.mask = data.pattern ? data.pattern.replaceAll('#', 'F').replaceAll('+', 'F') : this.defaultMask
					this.country = data.country ? data.country.toLowerCase() : this.userCountry
				})
			}
		}
	},
	directives: {
		mask
	}
}
</script>

<style scoped lang="scss">
	.bl-input.showFlag {
		input {
			padding-left: 33px;
			width: calc(100% - 25px);
		}

		.imageContainer {
			margin-top: 5px;
			height: calc(100% - 5px);
			display: flex;
			align-items: center;

			img {
				width: 20px;
				border-radius: 2px;
			}
		}
	}
</style>