<template>
	<div style="position: relative; height: 24px;" @mousedown="handleClick($event)" :class="{transitions: transitions, compact: compact}" class="container">
		<div class="line" ref="container"></div>
		<div class="handler" :style="{left: start + '%', width: (end - start) + '%'}">
			<div class="end knob" @mousedown="mouseDown('end')">
				<div v-if="currentMove == 'end' && model">{{ roundToStep(model[1]) }}</div>
			</div>
			<div class="start knob" @mousedown="mouseDown('start')">
				<div v-if="currentMove == 'start' && model">{{ roundToStep(model[0]) }}</div>
			</div>
		</div>
	</div>
	<div class="legend" :class="{compact: compact}" v-if="model">
		<div style="text-align: left;">{{ roundToStep(model[0]) }}</div>
		<div style="flex: 1;"></div>
		<div style="text-align: right;">{{ roundToStep(model[1]) }}</div>
	</div>
</template>

<script>
export default {
	name: 'BlRangeSlider',
	emits: ['update:modelValue', 'change'],
	props: {
		min: {
			default: 0,
			type: Number
		},
		max: {
			default: 100,
			type: Number
		},
		modelValue: {
			type: Array
		},
		step: {
			default: 1,
			type: Number
		},
		compact: {
			default: false,
			type: Boolean
		}
	},
	data() {
		return {
			currentMove: null,
			start: 0,
			end: 0,
			model: null,
			transitions: true
		}
	},
	uncreated() {
		this.unregisterListeners()
	},
	methods: {
		/**
		 * Mouse down handler, register listeners
		 * @param  {string} type start or end
		 */
		mouseDown(type, handleTransitions = true) {
			if(handleTransitions) this.transitions = false
			this.currentMove = type
			window.addEventListener('mousemove', this.mouseMoveHandler)
			window.addEventListener('mouseup', this.mouseUpHandler)
		},
		/**
		 * Mouse move event handler
		 * @param  {mixed} event
		 */
		mouseMoveHandler(event) {
			//Recalculate knobs position
			if(this.currentMove == 'start') {
				this.start = (event.x - this.$refs.container.getBoundingClientRect().left) / this.$refs.container.offsetWidth * 100
				if(this.start < 0) this.start = 0
				if(this.start > this.end) this.start = this.end
			}
			else if(this.currentMove == 'end') {
				this.end = (event.x - this.$refs.container.getBoundingClientRect().left) / this.$refs.container.offsetWidth * 100
				if(this.end > 100) this.end = 100
				if(this.end < this.start) this.end = this.start
			}

			this.calculateModel()
			event.stopPropagation()
			event.preventDefault()
			return false
		},
		/**
		 * Mouse up handler, unregister events listeners and emit model change
		 */
		mouseUpHandler() {
			this.transitions = true
			this.currentMove = null
			this.$emit('update:modelValue', this.model)
			this.$emit('change', this.model)
			this.unregisterListeners()
		},
		/**
		 * Unregister listeners if any
		 */
		unregisterListeners() {
			window.removeEventListener('mousemove', this.mouseMoveHandler)
			window.removeEventListener('mouseup', this.mouseUpHandler)
		},
		/**
		 * Set start and end properties from model values
		 */
		setModelValue() {
			if(this.modelValue && Array.isArray(this.modelValue) && this.modelValue.length == 2) {
				this.start = (this.modelValue[0] - this.min) / (this.max - this.min) * 100
				this.end = (this.modelValue[1] - this.min) / (this.max - this.min) * 100
			}
		},
		/**
		 * Handle simple click
		 * @param  {mixed} event
		 */
		handleClick(event) {
			if(!event.composedPath()[0].classList.contains('knob')) {
				let value = (event.x - this.$refs.container.getBoundingClientRect().left) / this.$refs.container.offsetWidth * 100
				let type = null
				if(value > this.end) {
					this.end = value
					type = 'end'
				}
				else if(value < this.start) {
					this.start = value
					type = 'start'
				}
				else {
					let halfWay = (this.end - this.start) / 2 + this.start
					if(value > halfWay) {
						this.end = value
						type = 'end'
					}
					else {
						this.start = value
						type = 'start'
					}
				}
				this.calculateModel()
				this.mouseDown(type, false)
				setTimeout(() => this.transitions = false, 200)
			}
		},
		/**
		 * Calculate model from start / end values
		 */
		calculateModel() {
			this.model = [
				this.roundToStep(((this.max - this.min) * this.start / 100) + this.min),
				this.roundToStep(((this.max - this.min) * this.end / 100) + this.min)
			]
		},
		/**
		 * Round value to step
		 * @param  {Number} value
		 * @return {Number}
		 */
		roundToStep(value) {
			let low = value - (value % this.step)
			let high = (value + this.step) - (value % this.step)
			let ret = (value - low) > (high - value) ? high : low
			let roundMultiplier = 1 / this.step
			return Math.round(ret * roundMultiplier) / roundMultiplier
		}
	},
	created() {
		this.setModelValue()
		this.calculateModel()
	},
	watch: {
		modelValue() {
			this.setModelValue()
		}
	}
}
</script>

<style scoped lang="scss">
	.line {
		background-color: var(--bl-primary);
		height: 6px;
		border-radius: var(--bl-border-radius);
		opacity: .2;
		position: relative;
		margin-top: 10px;
	}

	.handler {
		background-color: var(--bl-primary);
		height: 6px;
		border-radius: var(--bl-border-radius);
		position: absolute;
		margin-top: -7px;

		.knob {
			border-radius: 50%;
			height: 20px;
			width: 20px;
			background-color: var(--bl-primary);
			cursor: grab;
			margin-top: -7px;
		}

		.knob:active:before, .knob:focus:before, .knob:hover:before {
			content: ' ';
			width: 18px;
			height: 18px;
			margin-left: 1px;
			cursor-events: none;
			margin-top: 1px;
			display: block;
			position: absolute;
			box-shadow: 0 0 0 9px var(--bl-primary);
			opacity: .2;
			z-index: 0;
			border-radius: 50%;
			animation: bl-checkbox-active-animate-in .1s both;
		}

		.knob.start {
			margin-left: -9px;
		}

		.knob.end {
			float: right;
			margin-right: -9px;
		}

		.knob > div {
			z-index: 50;
			background-color: rgba(0, 0, 0, .8);
			color: white;
			border-radius: var(--bl-border-radius);
			padding: 5px;
			color: rgba(255, 255, 255, .9);
			font-family: Product sans;
			font-weight: bold;
			animation: bl-knob-animation .1s;
			position: absolute;
			margin-top: -37px;
			margin-left: -5px;
		}
	}

	@keyframes bl-knob-animation {
		0% {
			opacity: 0;
			transform: scale(.92);
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	.legend {
		display: flex;
		font-weight: bold;
		padding: 0 10px;
		margin-top: -5px;
		font-size: 14px;
	}

	.transitions .handler {
		transition: left .2s, width .2s;
	}

	.container {
		margin: 0 9px;
	}

	.container.compact {
		margin: 0 4.5px;

		.line {
			height: 3px;
			margin-top: 5px;
		}

		.handler {
			height: 3px;
			margin-top: -3.5px;

			.knob {
				height: 10px;
				width: 10px;
				margin-top: -3.5px;
			}

			.knob:active:before, .knob:focus:before, .knob:hover:before {
				width: 9px;
				height: 9px;
			}

			.knob.start {
				margin-left: -4.5px;
			}

			.knob.end {
				margin-right: -4.5px;
			}

			.knob > div {
				padding: 2px;
				margin-top: -25px;
				margin-left: -2.5px;
				font-size: 12px;
			}
		}
	}

	.legend.compact {
		margin-top: -12px;
		padding: 0;

		div:first-child, div:last-child {
			width: 50px;
		}
	}
</style>