<template>
	<div class="placeContainer">
		<div class="photo" :style="{backgroundImage: 'url(' + photo + ')'}" v-if="photo"></div>
		{{ name }}
	</div>
</template>

<script>
export default {
	props: ['name', 'photo'],
	name: 'BlViewPlace'
}
</script>

<style lang="scss" scoped>
	.placeContainer {
		display: flex;
		align-items: center;

		.photo {
			width: 35px;
			height: 18px;
			border-radius: var(--bl-border-radius);
			margin-right: 5px;
			background-size: cover;
		}
	}
</style>