import { EventEmitter } from 'Bundles/InterfaceBundle/services/EventEmitter'

export const FormEvents = {
	submitted: new EventEmitter(),
	ready: new EventEmitter(),
	disableRouting: false,
	activeForms: [],
	activeFormChange: new EventEmitter(),
	addActiveForm(form) {
		this.activeForms.push(form)
		this.activeFormChange.emit()
	},
	removeActiveForm(form) {
		this.activeForms = this.activeForms.filter(f => f !== form)
		this.activeFormChange.emit()
	},
	getActiveForm() {
		return this.activeForms.length ? this.activeForms[this.activeForms.length - 1] : null
	}
}