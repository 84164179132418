<template>
	<slot></slot>
</template>

<script>
export default {
	name: 'BlFormFieldOneToOne',
	inject: ['blForm'],
	props: ['field'],
	provide() {
		return {
			blForm: this.field
		}
	}
}
</script>

<style scoped>
</style>