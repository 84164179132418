import { Realtime, WindowManager } from 'InterfaceBundle'

/**
 * Bridge for flow elements
 */
export const DocumentBridge = {
	initialize() {
		//Document signature dialog element
		Realtime.listen('documentsignaturedialog').then(data => {
			let restoreFlow = data._restoreFlow
			delete data._restoreFlow
			WindowManager.open('BlDocumentSignatureWindow', data).subscribe(resp => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {sent: resp === true, canceled: resp !== true}})
			})
		})
	}
}

DocumentBridge.initialize()