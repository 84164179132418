import { ViewServices } from './ViewServices.js'

/**
 * Drag&drop helpers
 */
export const DragDrop = {
	/**
	 * Get relative position to given container
	 * @param  {object} position
	 * @param  {object} container
	 */
	getPositionRelativeTo(position, container, xProperty = 'x', yProperty = 'y') {
		let containerRect = container.getBoundingClientRect()
		let parent = container.parentNode
		while(parent.parentNode) {
			if(parent == document.body || window.getComputedStyle(parent).position == 'relative') break
			parent = parent.parentNode
		}
		let offsetY = containerRect.y - parent.getBoundingClientRect().y
		let offsetX = containerRect.x - parent.getBoundingClientRect().x
		let borderSize = parseInt(window.getComputedStyle(container).getPropertyValue('border-left-width').replace('px', ''))
		offsetY += borderSize
		offsetX += borderSize
		
		
		let ret = {
			x: position[xProperty],
			y: position[yProperty]
		}
		ret.x -= offsetX
		ret.y -= offsetY
		
		if(ret.y > containerRect.height) return
		if(ret.x > containerRect.width) return
		
		ret.x += container.scrollLeft
		ret.y += container.scrollTop
		
		if(ret.x < 0) ret.x = 0
		if(ret.y < 0) ret.y = 0

		let finalObject = {}
		finalObject[xProperty] = ret.x
		finalObject[yProperty] = ret.y
		return finalObject
	}
}

ViewServices.dragDrop = DragDrop