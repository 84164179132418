import { Api } from 'ModelBundle'
import { Auth } from 'AuthBundle'
import { Dialog } from 'InterfaceBundle'

export const RefreshToken = {
	autoRefreshTimeout: null,
	initialized: false,
	reloginOpened: false,

	/**
	 * Refresh user token
	 */
	refresh() {
		return new Promise((resolve, reject) => {
			Api.request('POST', '/auth/refresh_token', {data: Auth.getRefreshToken()}, {}, false).then(resp => {
				Auth.refreshToken(resp.token)
				this.autoRefresh()
				resolve()
			}).catch(() => {
				if(!this.reloginOpened) {
					localStorage.setItem('Auth.reloginDialogOpened', true)
					this.reloginOpened = true
					Dialog.custom({component: 'ReloginForm', closeButton: false, required: true, handleRouting: false}).then(() => {
						this.reloginOpened = false
						resolve()
					}).catch(() => {
						this.reloginOpened = false
						reject()
					})
				}
			})
		})
	},

	/**
	 * Initialize auto refresh token
	 */
	initialize() {
		if(this.initialized) return
		this.initialized = true
		if(Auth.isAuthenticated()) this.autoRefresh()
		Auth.events.beforeLogout.subscribe(() => {
			if(Auth.getToken()) Api.delete('/auth/refresh_token', {token: Auth.getRefreshToken()})
		})
		Auth.events.logout.subscribe(() => {
			if(this.autoRefreshTimeout) {
				clearTimeout(this.autoRefreshTimeout)
				this.autoRefreshTimeout = null
			}
		})
		Auth.events.login.subscribe(() => this.autoRefresh())

		//Close relogin window on all tabs
		window.addEventListener('storage', () => {
			if(!localStorage.getItem('Auth.reloginDialogOpened') && this.reloginOpened) {
				this.reloginOpened = false
				Dialog.close()
			}
		})
	},

	/**
	 * Auto refresh token once expired
	 */
	autoRefresh() {
		if(!Auth.isAuthenticated()) return
		let base64 = (Auth.getToken().split('.')[1]).replace(/-/g, '+').replace(/_/g, '/')
		let expiry = JSON.parse(decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))).exp * 1000
		let now = (new Date()).getTime()
		this.autoRefreshTimeout = setTimeout(() => this.refresh(), expiry - now - 1000)
	}
}