import { DialogInstance } from './DialogInstance'
import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'
import { Router } from './Router.js'

export const Dialog = {
	stack: [],
	change: new EventEmitter(),
	initialized: false,
	listenStateChange: true,

	_init() {
		if(this.initialized) return
		this.initialized = true
		document.addEventListener('keydown', e => this._handleKeyboardShortcuts(e))
		window.addEventListener('popstate', () => {
			if(this.getCurrentDialog() && this.listenStateChange && this.getCurrentDialog().data.handleRouting) {
				Router.handleRouteChange = false
				if(this.getCurrentDialog().data.required) history.pushState({}, '', location.pathname)
				else this.close(false, false)
			}
		})
	},

	/**
	 * Handle keyboard shortcuts
	 * @param  {object} event
	 */
	_handleKeyboardShortcuts(event) {
		if(event.key == 'Escape' && this.getCurrentDialog()) this.close(false)
	},

	_open(type, data) {
		let line = {
			id: this.stack.length + 1,
			data: new DialogInstance(type),
			inAnimation: true
		}
		line.data.bindData(data)
		setTimeout(() => line.inAnimation = false, 200)
		line.promise = new Promise((resolve, reject) => {
			line.resolve = v => resolve(v)
			line.reject = () => reject()
		})
		this.stack.push(line)
		this.change.emit()
		if(line.data.handleRouting) history.pushState({}, '', location.pathname)
		if(!this.initialized) this._init()
		return line.promise
	},

	/**
	 * Get currently opened dialog
	 * @return {object}
	 */
	getCurrentDialog() {
		return this.stack.length ? this.stack[this.stack.length - 1] : null
	},

	/**
	 * Open alert dialog
	 * @param  {object} data
	 * @return {Promise}
	 */
	alert(data) {
		return this._open(DialogInstance.TYPE_ALERT, data)
	},

	/**
	 * Open confirm dialog
	 * @param  {object} data
	 * @return {Promise}
	 */
	confirm(data) {
		return this._open(DialogInstance.TYPE_CONFIRM, data)
	},

	/**
	 * Open prompt dialog
	 * @param  {object} data
	 * @return {Promise}
	 */
	prompt(data) {
		return this._open(DialogInstance.TYPE_PROMPT, data)
	},

	/**
	 * Open custom dialog
	 * @param  {object} data
	 * @return {Promise}
	 */
	custom(data) {
		return this._open(DialogInstance.TYPE_CUSTOM, data)
	},

	/**
	 * Close latest dialog
	 * @param  {Boolean} valid
	 * @param  {Boolean} handleRouting
	 * @param  {any}     data
	 */
	close(valid = true, handleRouting = true, data = null) {
		if(handleRouting && this.getCurrentDialog().data.handleRouting) {
			//Avoid close call twice because of history back
			this.listenStateChange = false
			Router.handleRouteChange = false
			history.back()
			setTimeout(() => this.listenStateChange = true, 1)
		}
		if(valid && this.getCurrentDialog()) {
			const dialog = this.getCurrentDialog()
			setTimeout(() => {
				if(dialog.data.type == DialogInstance.TYPE_PROMPT) dialog.resolve(dialog.data.promptValue)
				else dialog.resolve(data)
			}, 10)
		}
		else {
			const dialog = this.getCurrentDialog()
			setTimeout(() => dialog.reject(), 10)
		}
		this.stack.pop()
		this.change.emit()
	}
}

ViewServices.dialog = Dialog