<template>
	<div>
		<BlStandaloneField ref="nameInput" type="text" :label="$t('exporter.modelName')" v-model="name" :required="true" />
		<div class="bl-toggle">
			<label>{{ $t('exporter.modelScope') }}</label>
			<div :class="{active: !publicModel}" @click="togglePublic()"><icon>lock</icon>{{ $t('exporter.modelScope.private') }}</div>
			<div :class="{active: publicModel}" @click="togglePublic()"><icon>public</icon>{{ $t('exporter.modelScope.public') }}</div>
		</div>
		<BlStandaloneField v-if="publicModel" type="text" :label="$t('exporter.modelPermissions')" v-model="permissions" :required="true" ref="permissionsInput" />
		<div style="text-align: right;">
			<BlButton :label="$t(edit ? 'exporter.edit' : 'exporter.savemodel')" @click="save()" />
		</div>
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlModelExporterSaveModel',
	props: ['edit'],
	data() {
		return {
			name: '',
			permissions: '',
			publicModel: false
		}
	},
	mounted() {
		this.name = this.edit?.name
		this.permissions = this.edit?.permissions
		if(this.permissions) this.public = true
		this.$refs.nameInput.$el.querySelector('input').focus()
	},
	methods: {
		save() {
			if(!this.name) this.$refs.nameInput.$el.querySelector('input').focus()
			if(this.publicModel && !this.permissions) this.$refs.permissionsInput.$el.querySelector('input').focus()
			else Dialog.close(true, true, {
				name: this.name,
				permissions: this.permissions
			})
		},
		togglePublic() {
			this.publicModel = !this.publicModel
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-toggle {
		margin-bottom: 10px;
	}
</style>