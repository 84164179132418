/**
 * Mark element as handle for resize
 * @param {Float} value initial item value
 * @param {Float} min   minimum value
 * @param {Float} max   maximum value
 *
 * To get the value, add an event listener on the handler : @resize="yourvalue = $event"
 * Event @resizeevent is true when resize is in progress, false when not
 */
export default {
	mounted(el, binding, vnode) {
		el.classList.add('bl-resize-handler')
		el.innerHTML = '<div></div>'
		el.addEventListener('mousedown', ev => {
			if(typeof vnode.props.onCurrentresize === 'function') vnode.props.onCurrentresize(true)
			let delta = ev.x - binding.value.value
			ev.preventDefault()
			ev.stopPropagation()

			let mouseMoveEvent = ev => {
				let width = ev.x - delta
				if(binding.value.min && width < binding.value.min) width = binding.value.min
				if(binding.value.max && width > binding.value.max) width = binding.value.max
				if(typeof vnode.props.onResize === 'function') vnode.props.onResize(width)
				binding.value.value = width
			}

			let mouseUpEvent = () => {
				if(typeof vnode.props.onCurrentresize === 'function') vnode.props.onCurrentresize(false)
				window.removeEventListener('mousemove', mouseMoveEvent)
				window.removeEventListener('mouseup', mouseUpEvent)
			}

			window.addEventListener('mousemove', mouseMoveEvent)
			window.addEventListener('mouseup', mouseUpEvent)
		})
	}
}