import { Auth } from 'AuthBundle'
import { Dialog } from './Dialog'
import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'
import { Realtime } from './Realtime.js'

/**
 * Route definition
 */
class RouteDefinition {
	constructor(id, url, args, component, title, actions, options = {}, permissions = null, context = null) {
		this.id = id
		this.url = url
		args = args ? args : {}
		this.args = []
		for(let argName in args) this.args.push({name: argName, required: args[argName]})
		this.component = component
		let regex = ['^' + this.url.replace(/\//g, '\\/')]
		for(let i = 1; i <= this.args.length; i++) regex.push('([a-zA-Z0-9:_%,.|]+)')
		this.regex = new RegExp(regex.join('\\/') + '$')
		this.title = title
		this.actions = {
			back: actions.filter(a => a.type === 0),
			main: actions.filter(a => a.type === 1),
			secondary: actions.filter(a => a.type === 2),
			cta: actions.filter(a => a.type === 3),
			select: actions.filter(a => a.type == 4)
		}
		this.actions.back = this.actions.back.length ? this.actions.back[0] : null
		this.options = options
		this.permissions = permissions
		this.context = context
	}
}

/**
 * Route instance
 */
class Route {
	constructor(definition, animate = null) {
		this.definition = definition
		this.animate = animate
		this.args = {}
	}

	/**
	 * Bind arguments
	 * @param  {any} args
	 */
	bind(args) {
		this.args = args
	}

	/**
	 * Get URI for current route
	 * @return {string}
	 */
	getUri() {
		let argsValue = []
		for(let arg of this.definition.args) {
			if(arg.required && !this.args[arg.name] && this.args[arg.name] !== 0) throw Error('Argument "' + arg.name + '" is required')
			argsValue.push(this.args[arg.name] || this.args[arg.name] === 0 ? this.args[arg.name] : (':' + arg.name))
		}
		let url = this.definition.url
		if(argsValue.length) {
			if(url.slice(-1) != '/') url += '/'
			url += argsValue.join('/')
		}
		if(url.substr(0, 1) != '/') url = '/' + url
		return url
	}

	/**
	 * Get animation level for new route
	 * @return {string}
	 */
	getAnimation() {
		return this.animate ? this.animate : 'same'
	}
}

export const Router = {
	routes: [],
	change: new EventEmitter(),
	currentRoute: null,
	dialogLeave: false,
	selectMode: null,
	selectModeChange: new EventEmitter(),
	usersOnRouteChange: new EventEmitter(),
	usersOnRoute: [],
	handleRouteChange: true,
	loading: false,
	params: {},
	$t: null,
	showUsersOnRouteEnabled: false,
	unsavedDataDialogOpened: false,
	_popstateListener: (e) => setTimeout(() => Router.handleRoute(null, false, e.state?.animate)),
	customHandler: null,

	/**
	 * Register route
	 * @param  {any} data
	 * @param  {any} component
	 */
	register(data, component) {
		this.routes.push(new RouteDefinition(data.id, data.url, data.arguments, component, data.title, data.actions, data.options, data.permissions, data.context))
	},

	/**
	 * Initialize router
	 */
	initialize($t) {
		this.$t = $t
		//Set timeout to allow other services to handle popstate
		window.addEventListener('popstate', this._popstateListener)

		let lastRoute = localStorage.getItem('Interface.last_route')
		if(lastRoute && window.location.pathname == '/') this.navigateUri(lastRoute, null, true)
		else this.handleRoute()
		Auth.events.logout.subscribe(() => this.unload())
		Realtime.listen('interface.navigationget').then(d => {
			this.usersOnRoute = d
			this.usersOnRouteChange.emit(this.usersOnRoute)
		})
	},

	/**
	 * Reload route
	 */
	reload() {
		this.change.emit()
	},

	/**
	 * Handle route change
	 */
	handleRoute(url = null, fromNavigation = false, animate = null) {
		if(!url) url = window.location.pathname

		if(Auth.isAuthenticated()) {
			Realtime.emit('interface.navigationset', url)
			localStorage.setItem('Interface.last_route', url)
		}

		if(!this.handleRouteChange) {
			this.handleRouteChange = true
			return false
		}
		this.selectModeDisable()
		if(this.dialogLeave) return this._handleDialogLeave(url, fromNavigation)

		url = url.substring(1)

		let newRoute = null
		for(let route of this.routes) {
			let match = url.match(route.regex)
			if(match && Auth.isGranted(route.permissions)) {
				newRoute = new Route(route, animate)
				let argValues = {}
				for(let index in route.args) {
					let argName = route.args[index].name
					let value = decodeURI(match[parseInt(index) + 1])
					if(value === ':' + argName) value = null
					argValues[argName] = value
				}
				newRoute.bind(argValues)
				break
			}
		}

		if(newRoute && this.customHandler && this.customHandler(newRoute)) return

		this.currentRoute = newRoute
		this.params = this.currentRoute ? this.currentRoute.args : {}
		ViewServices.routeParams = this.params

		//Close dialog stack
		while(Dialog.getCurrentDialog()) {
			Dialog.close(false, false)
		}

		this.change.emit()
		return true
	},

	/**
	 * Handle dialog leave
	 */
	_handleDialogLeave(url, fromNavigation) {
		this.dialogLeave = false
		if(!fromNavigation) {
			this.handleRouteChange = false
			history.forward()
		}
		this.unsavedDataDialogOpened = true
		Dialog.confirm({
			title: this.$t('navigation.unsavedData.title'),
			content: this.$t('navigation.unsavedData.content'),
			accept: this.$t('navigation.unsavedData.accept'),
			cancel: this.$t('navigation.unsavedData.cancel'),
			required: false,
			handleRouting: false
		}).then(() => {
			this.dialogLeave = true
			this.unsavedDataDialogOpened = false
			this.allowLeave()
			this.navigateUri(url)
		}, () => {
			this.dialogLeave = true
			this.unsavedDataDialogOpened = false
			this.preventLeave()
		})
		return false
	},

	/**
	 * Navigate to url
	 * @param  {string} url
	 * @param  {bool}   force if false, does not navigate to same uri, if true reloads page if same uri
	 */
	navigate(url, force = false) {
		if(typeof url === 'string' && url.substr(0, 1) == '[') url = this.parseLinkString(url)
		if(!Array.isArray(url)) url = [url]
		this.navigateUri(this.generateUri(url[0], url[1]), null, force)
	},

	/**
	 * Parse raw v-link value
	 * @param  {String} url
	 * @return {Array}
	 */
	parseLinkString(url) {
		let ret = null
		eval('ret = ' + url)
		return ret
	},

	/**
	 * Navigate to URI
	 * @param {string} uri
	 * @param {string} animate
	 * @param  {bool}   force if false, does not navigate to same uri, if true reloads page if same uri
	 */
	navigateUri(uri, animate = null, force = false) {
		if(window.location.pathname != uri) {
			if(this.handleRoute(uri, true, animate)) history.pushState({animate: animate}, '', uri)
		}
		else if(force) this.reload()
	},

	/**
	 * Generate uri for route and args
	 * @param  {string} url
	 * @param  {any}    args
	 * @param  {bool}   autoBind if true auto binds current route argument to url arguments, does not override given args
	 * @return {string}
	 */
	generateUri(url, args, autoBind = false) {
		let definition = this.getRouteByUrl(url)
		if(definition) {
			let route = new Route(definition)
			if(!args) args = {}
			if(autoBind && this.currentRoute) {
				for(let newRouteArg of definition.args) {
					if(typeof args[newRouteArg.name] === 'undefined' && this.currentRoute.args[newRouteArg.name]) args[newRouteArg.name] = this.currentRoute.args[newRouteArg.name]
				}
			}
			route.bind(args)
			return route.getUri()
		}
		else throw Error('Route "' + url + '" not found')
	},

	/**
	 * Check if user has access to route
	 * @param  {string} url
	 * @return {bool}
	 */
	canAccess(url) {
		let definition = this.getRouteByUrl(url)
		if(!definition) return false
		return Auth.isGranted(definition.permissions)
	},

	/**
	 * Get route definition by url
	 * @param  {string} url
	 * @return {RouteDefinition}
	 */
	getRouteByUrl(url) {
		for(let route of this.routes) {
			if(route.url == url) return route
		}
		return null
	},

	/**
	 * Unload router
	 */
	unload() {
		window.removeEventListener('popstate', this._popstateListener)
		this.reset()
		this.currentRoute = null
	},

	/**
	 * Unregister all routes
	 */
	reset() {
		this.routes = []
	},

	/**
	 * Show not found page
	 */
	notFound() {
		this.currentRoute = null
		this.change.emit()
	},

	/**
	 * Set select mode
	 * @param  {String} title
	 */
	selectModeEnable(title = null, data = null) {
		this.selectMode = {enabled: true, title: title, data: data}
		this.selectModeChange.emit(this.selectMode)
	},

	/**
	 * Disable select mode
	 */
	selectModeDisable() {
		this.selectMode = null
		this.selectModeChange.emit(this.selectMode)
	},

	/**
	 * Show users currently on route on the top bar
	 * @param  {boolean} value
	 */
	showUsersOnRoute(value) {
		this.showUsersOnRouteEnabled = value
	},

	/**
	 * Prevent user from leaving page (shows dialog)
	 */
	preventLeave() {
		if(localStorage.getItem('Interface.showDesignerToolbar')) return
		if(!this.dialogLeave) addEventListener('beforeunload', this._beforeUnload, {capture: true})
		this.dialogLeave = true
	},

	/**
	 * Cancels preventLeave
	 */
	allowLeave() {
		if(this.dialogLeave) removeEventListener('beforeunload', this._beforeUnload, {capture: true})
		this.dialogLeave = false
	},

	_beforeUnload(event) {
		event.preventDefault()
		return event.returnValue = 'Changes you have made will not be saved, are you sure you want to leave ?'
	},

	/**
	 * Push custom state in router for customHandler
	 * @param  {string} url
	 * @param  {Object} args
	 */
	customHandlerPush(url, args = {}) {
		if(!Router.customHandler) throw Error('No customHandler defined')
		history.pushState({animate: null}, '', Router.generateUri(url, args))
	}
}

ViewServices.router = Router