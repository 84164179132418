<template>
	<slot></slot>
</template>

<script>
export default {
	name: 'BlStandaloneSortableItem',
	props: ['index'],
	provide() {
		return {
			blFormCollectionElement: () => this.$el.nextElementSibling,
			blFormCollectionIndex: () => this.index
		}
	}
}
</script>

<style scoped lang="scss">
</style>