import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Components
 */
import BlBundlePreview from './components/BundlePreview.vue'
import InterfaceIntegratedEditor from './components/InterfaceIntegratedEditor.vue'
import InterfaceIntegratedEditorCreateDialog from './components/InterfaceIntegratedEditorCreateDialog'
import InstallPostInstall from './components/PostInstall'

Registry.components(BlBundlePreview, InterfaceIntegratedEditor, InterfaceIntegratedEditorCreateDialog, InstallPostInstall)

/**
 * Directives
 */

/**
 * Services
 */
import { MonacoLoader as MonacoLoaderService } from './services/MonacoLoader.js'

export const MonacoLoader = MonacoLoaderService