import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Components
 */
import BlDocumentFormPreview from './components/DocumentFormPreview.vue'
import BlDocumentDialogPreview from './components/DocumentDialogPreview.vue'
import BlDocumentPrintLoading from './components/DocumentPrintLoading.vue'
import BlDocumentSignatureWindow from './components/BlDocumentSignatureWindow.vue'
import BlDocumentSignatureAddRecipientForm from './components/BlDocumentSignatureAddRecipientForm.vue'
import BlDocumentSignatureStatus from './components/BlDocumentSignatureStatus.vue'

Registry.components(BlDocumentFormPreview, BlDocumentDialogPreview, BlDocumentPrintLoading, BlDocumentSignatureWindow, BlDocumentSignatureAddRecipientForm, BlDocumentSignatureStatus)

/**
 * Directives
 */

/**
 * Services
 */
import { Document as DocumentService } from './services/Document.js'
import { DocumentBridge as DocumentBridgeService } from './services/DocumentBridge.js'

export const ViewLoader = DocumentService 
export const DocumentBridge = DocumentBridgeService