<template>
	<label>{{ field.label }}</label>
	<div class="dayOfWeekSelector" :ref="dayOfWeekSelectorDiv">
		<button type="button" v-for="(isoDay, localDay) in dateFormat.isoDayMatch" :key="isoDay" @click="updateDaySelected(isoDay, true)" :class="{selected:(model[isoDay] && model[isoDay][0])}" v-bl-tooltip="dateFormat.dayLong[localDay]">
			{{ dateFormat.dayShort[localDay] }}
		</button>
	</div>

	<template v-for="(isoDay, localDay) in dateFormat.isoDayMatch" :key="isoDay">
		<div class="dayPeriod" v-if="model[isoDay] && model[isoDay][0]">
			<label>{{ dateFormat.dayLong[localDay] }}</label>
			<div>
				<div v-for="(period, periodIndex) in model[isoDay][1]" :key="periodIndex">
					<BlStandaloneField @change="periodUpdate(isoDay, period ,true)" @touched="field.setTouched()" type="time" :label="' '" v-model="period.from" :ref="`period${isoDay}`" />
					<div class="timeSeparator">–</div>
					<BlStandaloneField @change="periodUpdate(isoDay, period,true)" @touched="field.setTouched()" type="time" :label="' '" v-model="period.to" />
					<button type="button" class="bl-icon-button" v-if="isPeriodValid(period)" @click="remove(isoDay, periodIndex)">delete</button>
				</div>
			</div>
		</div>
	</template>
</template>

<script>
import { DateFormat } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldWeekPeriod',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: [],
			dateFormat: {}
		}
	},
	created() {
		this.dateFormat = {
			isoDayMatch: DateFormat.isoDayMatch,
			dayShort: DateFormat.dayShort,
			dayLong: DateFormat.dayLong,
		}
		this.field.emitter.focus.subscribe(() => this.$refs.dayOfWeekSelectorDiv.focus())
		this.field.emitter.change.subscribe(() => this.parseDataForForm())
		this.parseDataForForm()
	},
	methods: {
		updateDaySelected(day, fromInput = false, defaultValue = false) {
			let forceUpdateField = false
			this.model[day][0] = (fromInput ? !this.model[day][0] : defaultValue)

			if(this.model[day][0] && !this.model[day][1].length) {
				this.model[day][1] = [{ from: '', to: '' }]
				forceUpdateField = true
			}
			else if(!this.model[day][0] && this.model[day][1].length) {
				forceUpdateField = true
				this.model[day][1] = []
			}

			if(forceUpdateField && fromInput) this.updateFieldValue()
		},
		periodUpdate(isoDay, period = null, fromInput = false) {
			let periods = this.model[isoDay][1]
			let allowAdd = false
			const last = periods[periods.length - 1]

			if(!last) allowAdd = true
			else if(this.field.options.multipleHours && (last.from.length && last.to.length)) allowAdd = true

			if(allowAdd) {
				periods.push({ from: '', to: '' })
				if(fromInput) this.$nextTick(() => {
					this.$refs[`period${isoDay}`].$el.querySelector('input').focus()
				})
			}
			if(period && this.isPeriodValid(period) && fromInput) this.updateFieldValue()
		},
		initFieldValue() {
			let ret = []
			for(let i = 0; i < 7; i++) ret.push([false, []])
			return ret
		},
		parseDataForForm() {
			this.model = this.field.value ? JSON.parse(JSON.stringify(this.field.value)) : this.initFieldValue()
			for(let isoIndex in this.model) {
				if(this.model[isoIndex][0]) this.periodUpdate(isoIndex)
			}
		},
		updateFieldValue() {
			for(let isoIndex in this.model) {
				if(this.model[isoIndex][0]) this.model[isoIndex][1] = this.model[isoIndex][1].filter((p) => (p.form != '' && p.to != ''))
			}
			this.field.setValue(this.model)
		},
		isPeriodValid(period) {
			return period.from != '' && period.to != ''
		},
		remove(index, periodIndex) {
			this.model[index][1].splice(periodIndex, 1)
			this.updateFieldValue()
		}
	}
}
</script>

<style scoped lang="scss">

.dayOfWeekSelector {
	margin: 10px 0;

	> button {
		display: inline-block;
		background-color: var(--bl-background);
		border-radius: 50%;
		width: 24px;
		line-height: 24px;
		margin: 0 5px;
		text-align: center;
		font-size: 14px;
		transition: color 0.2s, background-color 0.2s;
		border: 0;
		cursor: pointer;
		font-family: Roboto;
		padding: 0;
	}

	> button.selected {
		color: var(--bl-on-secondary);
		background-color: var(--bl-secondary);
		font-weight: 500;
	}
}

.dayPeriod {
	display: flex;
	margin-top: 10px;

	> label {
		width: 100px;
		margin-top: 23px;
	}

	> div > div {
		display: flex;
		flex: 0.1;
		background-color: var(--bl-background);
		width: 155px;
		padding: 5px 5px 0px 7px;
		margin-top: 5px;
		border-radius: var(--bl-border-radius);

		.bl-input-time-container {
			width: 50px;
		}

		> button {
			margin-left: 14px;
			margin-right: -44px;
			margin-top: -4px;
			font-size: 20px;
		}

		.timeSeparator {
			margin-left: 22px;
			margin-right: 5px;
			margin-top: 13px;
		}
	}
}
</style>
