<template>
	<slot></slot>
</template>

<script>
export default {
	name: 'BlFormFieldCollectionItem',
	props: ['field', 'index'],
	provide() {
		return {
			blForm: this.field,
			blFormCollectionIndex: () => this.index,
			blFormCollectionElement: () => this.$el.nextElementSibling
		}
	}
}
</script>

<style scoped>
</style>