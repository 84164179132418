import { Dialog, ViewServices } from 'InterfaceBundle'

export const ModelExporter = {
	exportModels: [],
	open(index) {
		const args = {
			model: this.exportModels[index].model,
			defaultFields: this.exportModels[index].getFields().map(f => Object.keys(f)[0]),
			defaultFilters: this.exportModels[index].getRequestParams().data.filters
		}
		Dialog.custom({
			component: 'BlModelExporter',
			componentProps: args,
			fullScreen: true,
			closeButton: false,
			class: 'modelExporterDialogContainer'
		})
	}
}

ViewServices.modelExporter = ModelExporter