<template>
	<BlRangeSlider v-if="ready" :min="min" :max="max" v-model="value" @change="setFilter()" :compact="true" :step="0.01" />
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlListFilterRangeNumber',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			ready: false,
			value: null,
			min: null,
			max: null
		}
	},
	created() {
		let req = {
			min: {
				model: this.metadata.model.name,
				fields: [{name: this.metadata.field.name, formatted: false}],
				filters: ['&', [this.metadata.field.name, '!NULL', '0']],
				sort: [{field: this.metadata.field.name, order: 'ASC'}],
				limit: 1
			},
			max: {
				model: this.metadata.model.name,
				fields: [{name: this.metadata.field.name, formatted: false}],
				filters: ['&', [this.metadata.field.name, '!NULL', '0']],
				sort: [{field: this.metadata.field.name, order: 'DESC'}],
				limit: 1
			}
		}

		Api.post('api/', req).then(resp => {
			this.min = parseFloat(resp.min.data[0][this.metadata.field.name])
			this.max = parseFloat(resp.max.data[0][this.metadata.field.name])
			if(this.min == this.max) this.max++
			this.ready = true
			this.value = this.initialValue ? this.initialValue : [this.min, this.max]
		})
	},
	methods: {
		setFilter() {
			if(this.value && (this.value[0] != this.min || this.value[1] != this.max)) this.$emit('blListFilterChange', [['&', [this.metadata.field.name, '>=', this.value[0]], [this.metadata.field.name, '<=', this.value[1]]], this.value])
			else this.$emit('blListFilterChange', null)
		}
	}
}
</script>

<style scoped lang="scss">
</style>