<template>
	<div style="position: relative;">
		<BlSelect :data='data' v-model="value" @change="setFilter()" :loading="loading" />
		<span v-if="value" class="suffix material-icons clear" @click="clear()" style="visibility: visible; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlListFilterSelectManyToMany',
	props: ['initialValue', 'metadata', 'options'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: null,
			loading: true,
			data: {}
		}
	},
	created() {
		this.value = this.initialValue
		let req = {
			data: {
				model: this.metadata.field.options.target,
				fields: [{name: 'id'}, {name: '__toString'}],
				limit: -1,
				orderBy: [{name: '__toString'}],
				route: false,
				metadata: false
			}
		}
		if(this.options.filters) req.data.filters = this.options.filters

		this.data = {}
		Api.post('api/', req).then(resp => {
			this.data = {}
			this.data['<em style="padding-right: 5px; color: var(--bl-legend);">' + this.$t('model.list.filterSelectAll') + '</em>'] = null
			for(let item of resp.data.data) {
				if(!this.data[' ' + item['__toString']]) this.data[' ' + item['__toString']] = []
				this.data[' ' + item['__toString']].push(item['id'])
			}
			for(let key of Object.keys(this.data)) {
				if(this.data[key]) this.data[key] = this.data[key].join(',')
			}
			this.loading = false
		})
	},
	methods: {
		setFilter() {
			if(this.value) this.$emit('blListFilterChange', [[this.metadata.field.name + '.id', 'IN', this.value.split(',')], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = null
			this.setFilter()
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
		cursor: pointer;
	}
</style>