<template>
	<div>
		<BlSelect :data="field.options.choices" :label="field.label" v-model="model" @change="field.setValue(model)" :autocomplete="field.options.autocomplete" ref="field" @focus="field.setTouched()" :errored="field.isErrored() && field.getTouched()" :multiple="field.options.multiple" :tabindex="tabindex" />
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldChoice',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: !this.field.value && this.field.options.multiple ? [] : this.field.value
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.$refs.searchInput.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped>
</style>