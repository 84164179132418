export var BlListRegistry = {
	_registry: {},
	register(model) {
		if(!this._registry[model]) this._registry[model] = []
		let key = btoa(window.location.pathname + '|' + model + '|' + this._registry[model].length)
		this._registry[model].push(key)
		return key
	},
	unregister(uid) {
		const model = atob(uid).split('|')[1]
		this._registry[model] = this._registry[model].filter(i => i != uid)
		if(!this._registry[model].length) delete this._registry[model]
	}
}