<template>
	<div class="img-container" :class="{'has-svg': !!svg}">
		<img class="img" v-if="svg" :src="svg" />
	</div>
</template>

<script>
export default {
	props: ['svg'],
	name: 'BlViewSignature'
}
</script>

<style lang="scss" scoped>
.img-container {
	min-height: 16px;
	display: inline-block;
	padding: 1px;

	.img {
		max-height: 76px;
		width: 100%;
	}
}

.has-svg {
	border: 1px solid var(--bl-border);
	border-radius: var(--bl-border-radius);
}
</style>