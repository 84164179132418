import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'
import { Variables } from './Variables.js'
import { Auth } from 'AuthBundle'
import { Dialog } from './Dialog.js'

/**
 * Manages windows for mobile and desktop
 */
export const WindowManager = {
	windows: [],
	change: new EventEmitter(),
	initialized: false,

	/**
	 * Open window
	 * @param  {string} component
	 */
	open(component, properties = {}) {
		if(Variables.mobile) {
			let ret = new EventEmitter()
			properties.controls = {
				close: data => Dialog.close(data)
			}
			Dialog.custom({
				fullScreen: true,
				closeButton: false,
				component: component,
				componentProps: properties
			}).then(data => ret.emit(data)).catch(() => ret.emit(null))
			return ret
		}
		else {
			if(!this.initialized) {
				Auth.events.logout.subscribe(() => this.windows = [])
			}
			let win = {id: this.windows.length, component: component, properties: properties, status: new EventEmitter(), fullscreen: false}
			properties.controls = {
				close: data => this.close(win.id, data),
				fullscreen: () => this.fullscreen(win.id, true),
				smallscreen: () => this.fullscreen(win.id, false)
			}
			this.windows.push(win)
			this.change.emit()
			return win.status
		}
	},

	/**
	 * Find window by id
	 * @param  {integer} id
	 * @return {object}
	 */
	findById(id) {
		for(let win of this.windows) {
			if(id == win.id) return win
		}
		throw new Error('Window not found')
	},

	/**
	 * Close window
	 * @param  {integer} id
	 */
	close(id, data = null) {
		this.findById(id).status.emit(data)
		this.windows = this.windows.filter(w => w.id != id)
		this.change.emit()
	},

	/**
	 * Make window fullscreen
	 * @param  {integer} id
	 * @param  {boolean} value
	 */
	fullscreen(id, value) {
		this.findById(id).fullscreen = value
		this.change.emit()
	}
}

ViewServices.windowManager = WindowManager