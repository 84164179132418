/**
 * Style
 */
import './styles/card.scss'
import './styles/fonts.scss'
import './styles/global.scss'
import './styles/input.scss'
import './styles/table.scss'
import './styles/browser-specific.scss'
import './styles/button.scss'
import './styles/loader.scss'
import './styles/checkbox.scss'
import './styles/tabs.scss'
import './styles/tooltip.scss'
import './styles/toggle.scss'
import './styles/accessibility.scss'
import './styles/icon-selector.scss'
import './styles/grid.scss'
import './styles/resize.scss'
import './styles/select.scss'
import './styles/list.scss'
import './styles/chip.scss'

/**
 * Plugins
 */
import I18n from './plugins/I18n.js'
RegistryService.use(I18n)

/**
 * Components
 */
import InterfaceMainMenu from './components/InterfaceMainMenu.vue'
import InterfaceMobileMenu from './components/InterfaceMobileMenu.vue'
import InterfaceMainMenuItems from './components/InterfaceMainMenuItems.vue'
import BlButton from './components/InterfaceButton.vue'
import InterfaceRouterContainer from './components/InterfaceRouterContainer.vue'
import InterfaceLoading from './components/InterfaceLoading.vue'
import InterfaceGlobalLoading from './components/InterfaceGlobalLoading.vue'
import InterfaceTabs from './components/InterfaceTabs.vue'
import InterfaceTab from './components/InterfaceTab.vue'
import InterfaceTabHeader from './components/InterfaceTabHeader.vue'
import InterfaceTabBody from './components/InterfaceTabBody.vue'
import InterfaceSelect from './components/InterfaceSelect.vue'
import InterfaceSelectMobile from './components/InterfaceSelectMobile.vue'
import InterfaceDialogContainer from './components/InterfaceDialogContainer.vue'
import InterfaceRangeSlider from './components/InterfaceRangeSlider.vue'
import InterfaceCalendarInput from './components/InterfaceCalendarInput.vue'
import InterfaceNotFound from './components/InterfaceNotFound.vue'
import InterfaceNotificationContainer from './components/InterfaceNotificationContainer.vue'
import InterfaceMenu from './components/InterfaceMenu.vue'
import InterfaceUsersOnRoute from './components/InterfaceUsersOnRoute.vue'
import InterfaceWindows from './components/InterfaceWindows.vue'
import InterfaceWysiwyg from './components/InterfaceWysiwyg.vue'
import InterfacePersistentMessages from './components/InterfacePersistentMessages.vue'
import InterfaceEmojiPicker from './components/InterfaceEmojiPicker.vue'
import InterfaceSnackbarContainer from './components/InterfaceSnackbarContainer.vue'
import InterfaceMap from './components/InterfaceMap.vue'
import InterfaceFileSnackbar from './components/InterfaceFileSnackbar.vue'
import InterfaceDesignerToolbar from './components/InterfaceDesignerToolbar.vue'
import InterfaceUpdateSnackbar from './components/InterfaceUpdateSnackbar.vue'
import InterfaceNoData from './components/InterfaceNoData.vue'
import InterfaceUpgrading from './components/InterfaceUpgrading.vue'
import InterfaceShortcut from './components/InterfaceShortcut.vue'

RegistryService.components(BlButton, InterfaceMainMenu, InterfaceMobileMenu, InterfaceMainMenuItems, InterfaceRouterContainer, InterfaceLoading, InterfaceGlobalLoading, InterfaceTabs, InterfaceTab, InterfaceTabHeader, InterfaceTabBody, InterfaceSelect, InterfaceSelectMobile, InterfaceDialogContainer, InterfaceRangeSlider, InterfaceCalendarInput, InterfaceNotFound, InterfaceNotificationContainer, InterfaceMenu, InterfaceUsersOnRoute, InterfaceWindows, InterfaceWysiwyg, InterfacePersistentMessages, InterfaceEmojiPicker, InterfaceSnackbarContainer, InterfaceMap, InterfaceFileSnackbar, InterfaceDesignerToolbar, InterfaceUpdateSnackbar, InterfaceNoData, InterfaceUpgrading, InterfaceShortcut)

/**
 * Directives
 */
import InputDirective from './directives/Input.js'
import LinkDirective from './directives/Link.js'
import TooltipDirective from './directives/Tooltip.js'
import MainMenuActive from './directives/MainMenuActive.js'
import Draggable from './directives/Draggable.js'
import GridContainer from './directives/GridContainer.js'
import Resize from './directives/Resize.js'
import Dropdown from './directives/Dropdown.js'
import MobileInput from './directives/MobileInput.js'
import I18nDirective from './directives/I18n.js'

RegistryService.directive('blInput', InputDirective)
RegistryService.directive('link', LinkDirective)
RegistryService.directive('blTooltip', TooltipDirective)
RegistryService.directive('blMainMenuActive', MainMenuActive)
RegistryService.directive('blDraggable', Draggable)
RegistryService.directive('blGridContainer', GridContainer)
RegistryService.directive('blResize', Resize)
RegistryService.directive('blDropdown', Dropdown)
RegistryService.directive('blMobileInput', MobileInput)
RegistryService.directive('translate', I18nDirective)

/**
 * Services
 */
import { ViewLoader as ViewLoaderService } from './services/ViewLoader.js'
import { Registry as RegistryService } from './services/Registry.js'
import { Router as RouterService } from './services/Router.js'
import { ViewServices as ViewServicesService } from './services/ViewServices.js'
import { Snackbar as SnackbarService } from './services/Snackbar.js'
import { Variables as VariablesService } from './services/Variables.js'
import { ScriptLoader as ScriptLoaderService } from './services/ScriptLoader.js'
import { DateFormat as DateFormatService } from './services/DateFormat.js'
import { Realtime as RealtimeService } from './services/Realtime.js'
import { Dialog as DialogService } from './services/Dialog.js'
import { EventEmitter as EventEmitterService } from './services/EventEmitter.js'
import { WindowManager as WindowManagerService } from './services/WindowManager.js'
import { DragDrop as DragDropService } from './services/DragDrop.js'
import { Colors as ColorsService } from './services/Colors.js'
import { MapService as MapServiceService } from './services/MapService.js'
import { ViewCache as ViewCacheService } from './services/ViewCache.js'
import { Grid as GridService } from './services/Grid.js'
import { NativeProxy as NativeProxyService } from './services/NativeProxy.js'
import { ServiceWorker as ServiceWorkerService } from './services/ServiceWorker.js'

export const ViewLoader = ViewLoaderService
export const Registry = RegistryService
export const Router = RouterService
export const ViewServices = ViewServicesService
export const Variables = VariablesService
export const ScriptLoader = ScriptLoaderService
export const DateFormat = DateFormatService
export const Realtime = RealtimeService
export const Dialog = DialogService
export const EventEmitter = EventEmitterService
export const WindowManager = WindowManagerService
export const DragDrop = DragDropService
export const Snackbar = SnackbarService
export const Colors = ColorsService
export const MapService = MapServiceService
export const ViewCache = ViewCacheService
export const Grid = GridService
export const NativeProxy = NativeProxyService
export const ServiceWorker = ServiceWorkerService

/**
 * Virtual scroller
 */
import { RecycleScroller } from 'vue-virtual-scroller'
RegistryService.component(RecycleScroller)
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'