import { ViewServices, Realtime, EventEmitter } from 'InterfaceBundle'
import { Auth } from 'AuthBundle'

/**
 * Model change event helpers
 */
export var ModelChangeEventHelpers = {
	activeSubs: [],

	/**
	 * On credentials changed, reemit subscription event to socket
	 */
	initialize() {
		Auth.events.credentialsChanged.subscribe(() => {
			//Set timeout to make sure this is executed after realtime socket closing
			setTimeout(() => {
				for(let sub of ModelChangeEventHelpers.activeSubs) {
					//Resubscribe
					Realtime.emit('model.unlisten', sub._uid)
					sub._sub.unsubscribe()
					sub._uid = Realtime.emit('model.listen', [sub._model])._uid
					sub._sub = Realtime.listen('model.change')
					ModelChangeEventHelpers._bindSubscription(sub)
				}
			})
		})
	},

	/**
	 * Check if event has instance
	 * @param  {string}  data
	 * @param  {string}  model
	 * @param  {integer} id
	 * @return {Boolean}
	 */
	hasInstance(data, model, id) {
		return this.parseData(data).filter(i => i.model == model && i.id == id).length > 0
	},

	/**
	 * Check if event has model
	 * @param  {string}  data
	 * @param  {string}  model
	 * @return {Boolean}
	 */
	hasModel(data, model) {
		return this.parseData(data).map(i => i.model).includes(model)
	},

	/**
	 * Parse data string
	 * @param  {string} data
	 * @return {Array}
	 */
	parseData(data) {
		return data.items.split(',').map(i => {
			let parsed = i.split('|')
			return {model: parsed[0], id: parsed[1] ? parseInt(parsed[1]) : null}
		})
	},

	/**
	 * Listen to model changes
	 * @param  {string}  model
	 * @param  {integer} id
	 * @return {EventEmitter}
	 */
	listen(model, id = null) {
		let uid = Realtime.emit('model.listen', [model])._uid
		let sub = Realtime.listen('model.change')
		let ret = new EventEmitter()
		ret._sub = sub
		ret._model = model
		ret._modelId = id
		ret._uid = uid
		ModelChangeEventHelpers._bindSubscription(ret)
		ModelChangeEventHelpers.activeSubs.push(ret)
		return ret
	},

	/**
	 * Bind model change subscription to client sub
	 * @param  {object} sub
	 */
	_bindSubscription(sub) {
		sub._sub.then(resp => {
			if(
				(sub._modelId !== null && ModelChangeEventHelpers.hasInstance(resp, sub._model, sub._modelId)) ||
				(sub._modelId === null && ModelChangeEventHelpers.hasModel(resp, sub._model))
			) sub.emit(resp)
		})
	},

	/**
	 * Unsubscribe from model change
	 * @param  {EventEmitter} sub
	 */
	unsubscribe(sub) {
		for(let i in ModelChangeEventHelpers.activeSubs) {
			if(sub._uid == ModelChangeEventHelpers.activeSubs[i]._uid) {
				ModelChangeEventHelpers.activeSubs.splice(i, 1)
				break
			}
		}
		Realtime.emit('model.unlisten', sub._uid)
		sub._sub.unsubscribe()
		sub.unsubscribe()
	}
}

ModelChangeEventHelpers.initialize()
ViewServices.modelChangeEventHelpers = ModelChangeEventHelpers