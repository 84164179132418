<template>
	<table class="searchContainer bl-datatable" v-if="search && metadata">
		<thead>
			<tr>
				<th style="width: 1px;">
					<icon>filter_alt</icon>
				</th>
				<th v-for="col in Object.keys(search)" :key="col">
					<label>{{ metadata[col].label }}</label>
					<component :is="getFilterComponentName(col)" :initialValue="filtersState[col]" :metadata="{field: metadata[col], model: modelMeta}" :options="getFilterOptions(col)" @blListFilterChange="updateFilter(col, $event)" />
				</th>
			</tr>
		</thead>
	</table>
	<div class="kanbanContainer" ref="container" :class="{dragging: this.draggedItem}">
		<div v-if="ghostItem" ref="ghostItem" class="bl-card ghostItem" :style="{width: ghostItem.width}" v-bl-draggable="{model: ghostItem, maxX: ghostItem.maxX, maxY: ghostItem.maxY}">
			<slot name="item" :item="ghostItem.item"></slot>
		</div>
		<div v-for="action in actions" :key="action.reference" :actionLabel="action.reference" :class="{unavailable: action.unavailable}">
			<div class="bl-card actionHeader" :style="{'--action-color': action.color}">
				<h1>
					<icon>{{ action.icon }}</icon>
					{{ action.labelStatus }}
				</h1>
				<slot name="header" :action="action"></slot>
			</div>
			<div class="bl-card ghost" v-if="ghostItem && ghostItem.item[statusField]._cp.status.reference != action.reference" :style="{height: ghostItem.height}"><span></span></div>
			<div v-for="item in data[action.reference]" class="bl-card" :class="{dragged: item._dragged, animate: item._animate}" :key="item.id" @mousedown="startDrag(item, $event)" :id="'kanban_item_' + item.id">
				<slot name="item" :item="item"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { EventEmitter, Realtime } from 'InterfaceBundle'

export default {
	name: 'BlKanban',
	props: {
		model: {
			type: String,
			required: true
		},
		statusField: {
			type: String,
			required: true
		},
		fields: {
			type: Array,
			default: () => []
		},
		sort: {},
		defaultFilters: {},
		search: {},
		aggregations: {}
	},
	data() {
		return {
			currentRequest: true,
			actions: [],
			data: {},
			metadata: null,
			modelMeta: null,
			ghostItem: null,
			filtersState: {}
		}
	},
	mounted() {
		this.filters = {}
		this.restoreNavigation()
		this.mouseUpCb = e => this.endDrag(e)
		this.loadData().once(() => {
			this.rtListener = ModelChangeEventHelpers.listen(this.metamodel.name)
			this.rtListener.subscribe(() => this.loadData())
		})
	},
	unmounted() {
		if(this.rtListener) ModelChangeEventHelpers.unsubscribe(this.rtListener)
		this.endDrag()
	},
	methods: {
		/**
		 * Load data from API
		 */
		loadData() {
			if(this._firstDataLoadDone) this.saveNavigation()
			else this._firstDataLoadDone = true
			const ret = new EventEmitter()
			this.currentRequest = true

			let req = {
				fields: this.fields.map(f => typeof f === 'string' ? {name: f} : f),
				model: this.model,
				limit: -1
			}
			if(this.metadata) req.metadata = false
			if(!req.fields.filter(f => f.name == this.statusField).length) req.fields.push({name: this.statusField})
			if(this.sort) req.sort = this.sort

			let listFilters = Object.values(this.filters).length ? ['&'].concat(Object.values(this.filters)) : null
			let defaultFilters = this.defaultFilters && this.defaultFilters.length ? ['&'].concat([this.defaultFilters]) : null
			if(listFilters && defaultFilters) req.filters = ['&', listFilters, defaultFilters]
			else if(listFilters) req.filters = listFilters
			else if(defaultFilters) req.filters = defaultFilters
			req = {data: req}
			if(this.aggregations) req.aggregations = this.getAggregationRequest(JSON.parse(JSON.stringify(req.data)))

			Api.post('api/', req, {}, 'interface.kanban.' + this.getUid()).then(resp => {
				this.rawData = resp.data.data
				this.parseData()
				if(!this.metadata) {
					this.metamodel = resp.data.model
					this.metadata = resp.data.metadata
					this.modelMeta = resp.data.model
					this.loadWorkflow().once(() => {
						this.currentRequest = false
						if(this.aggregations) this.bindAggregations(resp.aggregations.data)
						ret.emit()
					})
				}
				else {
					if(this.aggregations) this.bindAggregations(resp.aggregations.data)
					this.currentRequest = false
					ret.emit()
				}
			}, () => this.currentRequest = false)
			return ret
		},
		bindAggregations(data) {
			for(let action of this.actions) {
				action.aggregates = null
				for(let item of data) {
					if(action.reference == item.node) {
						action.aggregates = item.children
						break
					}
				}
			}
		},
		getAggregationRequest(request) {
			delete request.limit
			delete request.fields
			delete request.sort
			delete request.metadata

			request.aggregations = {
				values: this.aggregations,
				group: [{
					field: this.statusField,
					formatted: false
				}]
			}

			return request
		},
		parseData() {
			this.data = {}
			for(let item of this.rawData) {
				if(!item[this.statusField]) continue
				const key = item[this.statusField]._cp.status.reference
				if(!this.data[key]) this.data[key] = []
				this.data[key].push(item)
			}
		},
		/**
		 * Load workflow datum
		 */
		loadWorkflow() {
			const ret = new EventEmitter()
			let req = {}
			req['context("workflow"):model.get("internals.workflow").findOneByReference("' + this.metadata[this.statusField].options.workflow + '")'] = {
				actions: {
					'loop("action"):local.workflow.actions': {
						reference: null,
						labelStatus: 'local.action.getLabelStatus()',
						icon: null,
						color: null
					}
				}
			}
			Api.post('api/structure/' , req).then(resp => {
				this.actions = resp.actions
				ret.emit()
			})
			return ret
		},
		/**
		 * Get uid for kanban table
		 * @return {String}
		 */
		getUid() {
			let pathname = window.location.pathname.split('/').filter(item => item.length)[0]
			return btoa(pathname + '|' + this.model)
		},
		/**
		 * Start drag
		 */
		startDrag(item, ev) {
			if(this.currentRequest) return
			this.availableActions = item[this.statusField]._cp.actions.map(a => a.reference)
			for(let action of this.actions) action.unavailable = !this.availableActions.includes(action.reference) && item[this.statusField]._cp.status.reference != action.reference
			const srcItem = this.$refs.container.querySelector('#kanban_item_' + item.id)
			const bounding = srcItem.getBoundingClientRect()
			const boundingContainer = this.$refs.container.getBoundingClientRect()
			this.ghostItem = {
				item: JSON.parse(JSON.stringify(item)),
				width: (srcItem.offsetWidth - 12) + 'px',
				height: (srcItem.offsetHeight - 12) + 'px',
				x: (bounding.left - boundingContainer.left) - 5,
				y: (bounding.top - boundingContainer.top) - 5,
				maxX: boundingContainer.width,
				maxY: boundingContainer.bottom
			}
			item._dragged = true
			this.draggedItem = item
			document.addEventListener('mouseup', this.mouseUpCb)
			this.$nextTick(() => {
				const e = new ev.constructor(ev.type, ev)
				this.$refs.ghostItem.dispatchEvent(e)
			})
		},
		/**
		 * End drag
		 */
		endDrag(ev) {
			if(this.draggedItem) {
				let actionTo = null
				let target = ev.target
				while(target) {
					if(target.getAttribute('actionLabel')) {
						actionTo = target.getAttribute('actionLabel')
						break
					}
					target = target.parentElement
				}
				const currentKey = this.draggedItem[this.statusField]._cp.status.reference
				if(actionTo && actionTo != currentKey && this.availableActions.includes(actionTo)) {
					this.draggedItem[this.statusField]._cp.status.reference = actionTo
					this.parseData()
					const draggedItem = this.draggedItem
					draggedItem._animate = true
					setTimeout(() => draggedItem._animate = false, 400)
					this.currentRequest = true
					Realtime.emit('workflow.start', {
						name: this.draggedItem[this.statusField]._cp.name,
						action: actionTo,
						instance: this.draggedItem[this.statusField]._cp.instance,
						property: this.draggedItem[this.statusField]._cp.property
					}).then(() => this.loadData())
				}
				delete this.draggedItem._dragged
				this.draggedItem = null
				this.ghostItem = null
			}
		},
		/**
		 * Get filter component name
		 * @param  {String} column
		 * @return {String}
		 */
		getFilterComponentName(column) {
			let filterName = this.search[column]
			if(typeof filterName == 'object') {
				if(filterName.component) return filterName.component
				filterName = filterName.type
			}
			filterName = filterName.charAt(0).toUpperCase() + filterName.slice(1)
			let typeName = this.metadata[column].filterType
			typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
			return 'BlListFilter' + filterName + typeName
		},
		/**
		 * Get filter options
		 * @param  {string} column
		 * @return {object}
		 */
		getFilterOptions(column) {
			if(!this.metadata[column]) return null
			let filter = this.search[column]
			if(typeof filter == 'object' && filter.options) return filter.options
			return {}
		},
		updateFilter(col, event) {
			if(event) {
				this.filters[col] = event[0]
				this.filtersState[col] = event[1]
			}
			else {
				delete this.filters[col]
				delete this.filtersState[col]
			}
			this.loadData()
		},
		/**
		 * Save filters in local storage
		 */
		saveNavigation() {
			let value = {
				filters: this.filters,
				filtersState: this.filtersState
			}
			localStorage.setItem('interface.kanban.' + this.getUid(), JSON.stringify(value))
		},
		/**
		 * Restore navigation
		 */
		restoreNavigation() {
			let restore = localStorage.getItem('interface.kanban.' + this.getUid())
			if(!restore) return
			restore = JSON.parse(restore)
			if(restore.filters) this.filters = restore.filters
			if(restore.filtersState) this.filtersState = restore.filtersState
		}
	}
}
</script>

<style scoped lang="scss">
	@keyframes popInItem {
		0% {
			transform: scale(1);
		}
		65% {
			transform: scale(1.01);
		}
		100% {
			transform: scale(1);
		}
	}

	.kanbanContainer {
		position: relative;
		display: flex;
		margin: 5px;

		> .ghostItem {
			pointer-events: none;
			position: absolute;
			z-index: 100;
		}

		> div:not(.ghostItem) {
			flex: auto;
			padding-left: 5px;
			padding-right: 5px;
			padding-bottom: 5px;
			margin: 0;

			> div.bl-card {
				margin-left: 0;
				margin-right: 0;
			}

			> div.actionHeader {
				background-color: color-mix(in srgb, var(--action-color) 10%, var(--bl-surface));
				border: 1px solid var(--action-color);
			}

			> div.dragged {
				opacity: 0;
			}

			> div.animate {
				animation: popInItem .2s;
			}

			> div.ghost {
				display: none;
			}
		}
	}

	.kanbanContainer.dragging {
		user-select: none;

		> div:not(.ghostItem):hover {
			transition: background-color .2s;
			background-color: var(--bl-selected-bg);
			border-radius: var(--bl-border-radius);
			outline: 1px dashed var(--bl-selected-fg);

			> div.ghost {
				display: block;
				opacity: .7;
			}
		}

		> div.unavailable:not(.ghostItem):hover {
			transition: background-color .2s;
			outline-color: var(--bl-error);
			background-color: color-mix(in srgb, var(--bl-error) 10%, var(--bl-surface));

			> div.ghost {
				display: none;
			}
		}
	}

	.searchContainer {
		th {
			padding-top: 7px;
			padding-bottom: 7px;
		}

		th:first-child {
			border-bottom-left-radius: var(--bl-border-radius);
		}

		th:last-child {
			border-bottom-right-radius: var(--bl-border-radius);
		}
	}
</style>