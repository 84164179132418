<template>
	<div class="bl-card">
		<icon>deployed_code_history</icon>
		<div class="bl-loader-line" style="width: 100px; border-radius: 2px;"></div>
		<h1>{{ i18nAvailable ? $t('interface.upgrading.title') : 'Update in progress' }}</h1>
		<p>{{ i18nAvailable ? $t('interface.upgrading.content') : 'Please wait, the page will reload once the update is complete' }}</p>
	</div>
</template>

<script>
import { ViewLoader } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'InterfaceUpgrading',
	data() {
		return {
			i18nAvailable: false
		}
	},
	created() {
		this.i18nAvailable = ViewLoader.data?.i18n ? true : false
		this.reloadInterval = setInterval(() => Api.get('interface/manifest.json'), 1000)
	},
	unmounted() {
		clearTimeout(this.reloadInterval)
	}
}
</script>

<style scoped lang="scss">
.bl-card {
	height: 100%;
	margin: 0;
	border: 0;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> :is(h1, p, icon) {
		color: var(--bl-on-surface);
	}
}

icon {
	font-size: 100px;
	opacity: .8;
	margin-bottom: 10px;
}

h1 {
	margin: 2px;
	font-size: 24px;
}

p {
	margin: 0;
	opacity: .8;
	font-size: 12px;
}
</style>