<template>
	<div class="workflowContainer">
		<div class="backgroundColor" :style="{backgroundColor: status.color}"></div>
		<div class="container">
			<div class="status">{{ status.labelStatus }}</div>
			<div class="actions">
				<BlButton v-bl-tooltip="action.labelAction" v-for="action in actions" :key="action.reference" @click="runAction(action)" :label="action.labelAction" :icon="action.icon" :loading="action.loading" :color="action.color" />
			</div>
		</div>
	</div>
</template>

<script>

import { Realtime } from 'InterfaceBundle'

export default {
	name: 'BlWorkflowView',
	props: ['status', 'actions', 'name', 'property', 'instance'],
	methods: {
		runAction(action) {
			action.loading = true
			Realtime.emit('workflow.start', {
				name: this.name,
				action: action.reference,
				instance: this.instance,
				property: this.property
			}).then(() => action.loading = false)
		}
	}
}
</script>

<style scoped lang="scss">
.workflowContainer {
	.backgroundColor {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		display: none;
	}

	.container {
		display: flex;
		align-items: center;
		padding: 10px;
		position: inherit;
	}

	border-radius: var(--bl-border-radius);
	background-color: #333;
	color: #FFF;
	padding: 0;
	position: relative;
	overflow: hidden;

	.status {
		font-family: Product sans;
		font-size: 16px;
		flex: 1;
		padding: 10px;
	}

	.actions {
		display: flex;
	}

	.actions > button {
		margin-left: 20px;
	}
}

.bl-datatable .workflowContainer {
	margin: -8px 0 -12px 0;
	background-color: transparent;
	display: inline-block;

	.container {
		padding: 4px;
	}

	.backgroundColor {
		display: block;
	}

	.status {
		float: left;
		padding: 0 5px;
		font-size: 14px;
		font-family: Roboto;
		line-height: 25px;
	}

	:deep button {
		background-color: transparent !important;
		min-width: 0;
		margin-left: 1px;
		height: 23px;
		min-height: 25px;
		width: 25px;
		padding-left: 13px;
		padding-right: 0;

		i.material-icons {
			color: white;
			margin-right: 0;
			margin-left: -9px;
		}

		span {
			display: none;
		}

		svg {
			width: 25px;
			margin-top: -5px;
		}
	}
}
</style>