<template>
	<div style="position: relative;">
		<BlSelect :data="data" v-model="value" @change="setFilter()" />
		<span v-if="value || value === false" class="suffix material-icons clear" @click="clear()" style="visibility: visible; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
export default {
	name: 'BlListFilterSelectBoolean',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: null,
			data: {}
		}
	},
	created() {
		this.value = this.initialValue
		this.data[this.$t('model.booleanYes')] = true
		this.data[this.$t('model.booleanNo')] = false
	},
	methods: {
		setFilter() {
			if(this.value || this.value === false) this.$emit('blListFilterChange', [[this.metadata.field.name, '=', this.value], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = null
			this.setFilter()
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
		cursor: pointer;
	}
</style>