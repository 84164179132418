<template>
	<div style="width: 100%;">
		<div class="iconContainer">
			<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="var(--bl-on-primary)" d="M 11 40 Q 9.8 40 8.9 39.1 Q 8 38.2 8 37 V 29.85 H 11 V 37 Q 11 37 11 37 Q 11 37 11 37 H 37 Q 37 37 37 37 Q 37 37 37 37 V 29.85 H 40 V 37 Q 40 38.2 39.1 39.1 Q 38.2 40 37 40 Z H 25.5 Z"/></svg>
			<svg xmlns="http://www.w3.org/2000/svg" class="arrow" height="48" width="48"><path fill="var(--bl-on-primary)" d="M 24 32.35 L 14.35 22.7 L 16.5 20.55 L 22.5 26.55 V 8 H 25.5 V 26.55 L 31.5 20.55 L 33.65 22.7 Z"/></svg>
		</div>
		<span style="flex: 1">{{ $t('interface.updating') }}</span>
	</div>
</template>

<script>
export default {
	name: 'InterfaceUpdateSnackbar'
}
</script>

<style scoped lang="scss">
	div {
		display: flex;
		align-items: center;

		svg {
			position: absolute;
			transform: scale(.6);
		}

		svg.arrow {
			animation: dragOverAnimateArrow 1.5s infinite cubic-bezier(0.42, 0.0, 0.58, 1.0);
		}

		.iconContainer {
			width: 45px;
			margin-left: -15px;
		}
	}

	@keyframes dragOverAnimateArrow {
		0% {
			opacity: 1;
			transform: scale(.6) translateY(-10px);
		}

		50% {
			opacity: .4;
			transform: scale(.6) translateY(0);
		}

		100% {
			opacity: 1;
			transform: scale(.6) translateY(-10px);
		}
	}
</style>