import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Components
 */
import LogViewer from './components/LogViewer.vue'
import LogAnalyser from './components/LogAnalyser.vue'
import LogDetail from './components/LogDetail.vue'

Registry.components(LogViewer, LogAnalyser, LogDetail)

/**
 * Directives
 */

/**
 * Services
 */