<template>
	<div class="downloaderContainer">
		<div class="bl-loader-line" style="margin-top: 0;"></div>
		<p>
			<icon>print</icon>
			<span>{{ $t(multi ? 'Preparing documents' : 'Preparing document') }}</span>
		</p>
	</div>
</template>

<script>
export default {
	name: 'BlDocumentPrintLoading',
	props: ['name', 'icon', 'multi']
}
</script>

<style scoped lang="scss">
	.downloaderContainer {
		margin-top: -25px;
		margin-left: -25px;
		width: calc(100% + 50px);
		height: calc(100% + 50px);
		display: flex;
		flex-direction: column;
		position: relative;
		border-top-right-radius: var(--bl-border-radius);
		border-top-left-radius: var(--bl-border-radius);
		overflow: hidden;

		p {
			color: var(--bl-legend);
			text-align: center;
			margin-top: 40px;

			icon {
				font-size: 40px;
			}

			span {
				display: block;
				margin-top: 5px;
				font-size: 12px;
			}
		}
	}
</style>