<template>
	<div style="display: inline-block;">
		<div :style="{'--bundle-color': color}" class="bundle">
			<icon>{{ icon }}</icon>
			<div>{{ name }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlBundlePreview',
	props: ['name', 'version', 'color', 'icon']
}
</script>

<style scoped lang="scss">
	div.bundle {
		background: linear-gradient(200deg, var(--bundle-color) 0%, color-mix(in lch, var(--bl-surface) 25%, var(--bundle-color)) 100%);
		border-radius: var(--bl-border-radius);
		display: flex;
		align-items: center;
		padding: 4px 7px;
		text-shadow: 0 0 10px #ffffffe0;
		color: #00000085;

		icon {
			margin-right: 5px;
			font-size: 18px;
		}

		div {
			font-weight: 500;
		}
	}
</style>

<style lang="scss">
	table.bl-datatable > tbody > tr > td > div > div.bundle {
		margin: -10px 0;
	}
</style>