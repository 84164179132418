<template>
	<ul :class="{mobile: mobile}">
		<li v-if="isOffline">
			<icon>wifi_off</icon>
			<span>{{ $t('navigation.offline') }}</span>
		</li>
		<li v-if="impersonating">
			<icon>supervisor_account</icon>
			<span>{{ $t('navigation.impersonating') }}</span>
			<BlButton class="outlined dense" :label="$t('navigation.impersonating.stop')" @click="stopImpersonating()" />
		</li>
	</ul>
</template>

<script>
import { Variables, Realtime } from 'InterfaceBundle'
import { Auth } from 'AuthBundle'

export default {
	name: 'InterfacePersistentMessages',
	data() {
		return {
			isOffline: false,
			realtimeSub: null,
			mobile: Variables.mobile,
			impersonating: Auth.isImpersonating()
		}
	},
	methods: {
		stopImpersonating() {
			Auth.impersonate(null)
			location.reload()
		}
	},
	created() {
		this.realtimeSub = Realtime.status()
		this.realtimeSub.then(v => {
			if(v) {
				if(this.offlineTimeout) clearTimeout(this.offlineTimeout)
				this.offlineTimeout = null
				this.isOffline = false
			}
			else if(!this.offlineTimeout) {
				this.offlineTimeout = setTimeout(() => this.isOffline = true, 3000)
			}
		})
	},
	unmounted() {
		this.realtimeSub.unsubscribe()
	}
}
</script>

<style scoped lang="scss">
ul:empty {
	display: none;
}

ul {
	background-color: rgba(0, 0, 0, .8);
	width: 300px;
	transition: margin-bottom .1s;
	backdrop-filter: blur(10px);
	z-index: 2000;
	color: white;
	border-radius: var(--bl-border-radius);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	position: fixed;
	top: 0;
	left: 50%;
	margin: 0;
	margin-left: -150px;
	padding: 0;
	list-style: none;

	li {
		display: flex;
		align-items: center;
		padding: 12px;
		height: 30px;

		icon {
			margin-right: 10px;
		}

		span {
			text-align: center;
			flex: 1;
			font-family: Product sans;
			font-weight: 700;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	li:not(:first-child) {
		padding-top: 0;
	}
}

ul.mobile {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 72px;
	top: auto;
	margin: 0;
	margin-top: -168px;
	border-radius: 0;
}
</style>