<template>
	<div class="blSidebarContainer">
		<div class="menuContainer">
			<template v-for="(item, i) in items" :key="i">
				<hr v-if="item.separator" />
				<button v-else-if="item.link" v-link="item.link" v-blMainMenuActive class="bl-icon-button" v-bl-tooltip="item.title">{{ item.icon }}</button>
				<button v-else class="bl-icon-button appButton" v-bl-tooltip="item.title" @click="activeApp = activeApp == item.app ? null : item.app" :style="{'--button-color': item.color}" :class="{active: item.app == activeApp}">{{ item.icon }}</button>
			</template>
		</div>
		<div v-if="activeApp" class="sidebarAppContainer">
			<component :is="activeApp" :widget="true" />
		</div>
	</div>
</template>

<script>
import { Colors } from 'InterfaceBundle'

export default {
	name: 'BlInterfaceSidebar',
	data() {
		return {
			colors: Colors,
			activeApp: null,
			items: [
				{title: 'Clients', link: ['customer/list'], icon: 'folder_shared'},
				{title: 'Produits', link: ['product/list'], icon: 'inventory_2'},
				{title: 'Contacts', link: ['crmcontact/list'], icon: 'contacts'},
				{separator: true},
				{title: 'Devis', link: ['salesquotation/list'], icon: 'request_quote'},
				{title: 'Factures', link: ['salesinvoice/list'], icon: 'receipt_long'},
				{title: 'Bons de livraison', link: ['salesdelivery/list'], icon: 'package'},
				{separator: true},
				{title: 'Agenda', app: 'AgendaContainer', icon: 'event', color: '#a90404'},
				{title: 'Files', app: 'BlFileExplorer', icon: 'folder_open', color: '#1d92d1'},
				{title: 'Price calculator', app: 'PricelistWidget', icon: 'calculate', color: '#2cb727'}
			]
		}
	}
}
</script>

<style scoped lang="scss">
	.blSidebarContainer {
		display: flex;
		height: 100%;
	}

	.menuContainer {
		padding: 5px;
		display: flex;
		flex-direction: column;

		> * {
			margin-top: 2px;
			margin-bottom: 2px;
		}

		> hr {
			display: block;
			width: 60%;
			padding: 0;
		}

		> button {
			width: 48px;
		}

		> button {
			color: color-mix(in lch, var(--bl-surface) 20%, var(--bl-legend));
		}

		> button:not(.appButton).active {
			color: var(--bl-primary);
		}

		> button.active {
			font-family: 'Material Icons Filled';
		}

		.appButton {
			position: relative;
		}

		.appButton.active {
			background: linear-gradient(200deg, var(--button-color) 0%, color-mix(in lch, var(--bl-surface) 40%, var(--button-color)));
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.appButton::after {
			background-color: var(--button-color);
		}

		.appButton.active::before {
			content: ' ';
			background-color: var(--button-color);
			margin-left: 40px;
			height: 28px;
			width: 3px;
			display: block;
			position: absolute;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}

	.sidebarAppContainer {
		width: 300px;
		background-color: var(--bl-surface);
		border-left: 1px solid var(--bl-border);
		box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.1);
	}
</style>