import { EventEmitter } from 'Bundles/InterfaceBundle/services/EventEmitter.js'
import { Auth } from 'AuthBundle'
import { Variables, NativeProxy } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export const Login = {
	login(username, password, provider) {
		return 	this._do(username, password, provider, false)
	},
	relogin(username, password, provider) {
		return 	this._do(username, password, provider, true)
	},
	_do(username, password, provider, relogin) {
		const ret = new EventEmitter()
		let req = {username, password}
		if(provider) req.provider = provider

		if(NativeProxy.isEnabled()) {
			const definition = NativeProxy.getDeviceDefinition()
			req.nativeDeviceId = definition.name + ' (' + definition.macAddress + ')'
		}

		Api.request('POST', 'auth/login', req, {}, false).then(resp => {
			Auth.login(resp, relogin)
			ret.emit({'valid': true})
		}).catch(err => {
			if(err.status == 503) Variables.setUpgrading(true)
			ret.emit({'error': err})
		})

		return ret
	}
}