import { EventEmitter } from 'Bundles/InterfaceBundle/services/EventEmitter.js'

export const Auth = {
	events: {
		login: new EventEmitter(),
		logout: new EventEmitter(),
		beforeLogout: new EventEmitter(),
		credentialsChanged: new EventEmitter()
	},
	permissions: [],
	superuser: null,

	/**
	 * Api get
	 */
	getToken() {
		return localStorage.getItem('Auth.token')
	},

	/**
	 * Api get
	 */
	getRefreshToken() {
		return localStorage.getItem('Auth.refresh_token')
	},

	/**
	 * Check if user is authenticated
	 * @return {boolean}
	 */
	isAuthenticated() {
		return localStorage.getItem('Auth.token') ? true : false
	},

	/**
	 * Login user
	 * @param  {any} data
	 */
	login(data, relogin = false) {
		localStorage.setItem('Auth.token', data.token)
		localStorage.setItem('Auth.refresh_token', data.refresh_token)
		localStorage.removeItem('Auth.reloginDialogOpened')
		if(relogin) this.events.credentialsChanged.emit(data)
		else this.events.login.emit(data)
	},

	/**
	 * Refresh token
	 * @param  {String} token
	 */
	refreshToken(token) {
		localStorage.setItem('Auth.token', token)
		this.events.credentialsChanged.emit({token: token})
	},

	/**
	 * Logout user
	 */
	logout() {
		this.events.beforeLogout.emit()
		for(let key of Object.keys(localStorage)) {
			if(key.substr(0, 11) != 'Persistent.') localStorage.removeItem(key)
		}
		history.pushState({}, '', '/')
		this.events.logout.emit()
	},

	/**
	 * Check if user is granted permission
	 * @param  {string}  role
	 * @return {Boolean}
	 */
	isGranted(permissionExpr) {
		if(this.superuser) return true
		if(!permissionExpr) return false
		if(!permissionExpr.match(/^[A-Za-z0-9|&() _!]*$/)) throw Error('Invalid permission expression "' + permissionExpr + '"')

		//Expression parsing
		let expression = ' ' + permissionExpr.replaceAll('|', ' || ').replaceAll('&', ' && ').replaceAll('(', ' ( ').replaceAll(')', ' ) ').replaceAll('!', ' ! ') + ' '
		const exprRoles = permissionExpr.split(/[!|&() ]/)
		for(let exprRole of exprRoles) {
			if(!exprRole.length) continue
			expression = expression.replaceAll(' ' + exprRole + ' ', '(this.permissions.includes(\'' + exprRole + '\'))')
		}

		let result = null
		eval('result = ' + expression)
		return result
	},

	/**
	 * Get user id of the impersonated user (null if no impersonation)
	 * @return {?Boolean}
	 */
	isImpersonating() {
		return localStorage.getItem('Auth.impersonate')
	},

	/**
	 * Impersonate user id, pass null to stop impersonating
	 * @param {integer|null} userId
	 */
	impersonate(userId) {
		if(userId) localStorage.setItem('Auth.impersonate', userId)
		else localStorage.removeItem('Auth.impersonate')
	},

	/**
	 * Set device type
	 * @param {integer} deviceType
	 */
	setDeviceType(deviceType) {
		localStorage.setItem('Persistent.authDeviceType', deviceType)
	},

	/**
	 * Get device type
	 */
	getDeviceType() {
		return localStorage.getItem('Persistent.authDeviceType')
	}
}