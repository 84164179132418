function blMobileInputDirectiveEvent(e) {
	e.target.composing = false
}

export default {
	mounted(el) {
		el.addEventListener('input', blMobileInputDirectiveEvent, {capture: true})
	},
	unmounted(el) {
		el.removeEventListener('input', blMobileInputDirectiveEvent, {capture: true})
	}
}