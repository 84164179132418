<template>
	<div style="display: flex; width: 100%; flex: 1; max-height: calc(100% - 61px); position: relative;">
		<div class="bl-card" style="padding: 0; flex: 1; margin-right: 0;" ref="container" @click="stopConnection()">
			<div class="action" v-for="action in actions" :key="action.id" :ref="setActionRef" :data-key="action.id" v-bl-draggable="{handle: 'h4', model: action, xProperty: 'positionX', yProperty: 'positionY', moved: actionMoved}" :class="{animate: action.animate}" :style="{'--action-color': '#' + action.color}">
				<div class="connectionHook material-icons" @click="createConnection(action)" v-if="connectionMode && action.type == 1 && connectionMode.action.id != action.id">link</div>
				<h4>
					<i class="material-icons">{{ action.type == 1 ? 'sync_alt' : 'pending_actions' }}</i>
					<span>{{ action.label }}</span>
					<button class="bl-icon-button" v-bl-tooltip="'Delete'" @click.stop="deleteAction(action)">delete</button>
				</h4>
				<div class="bindings">
					<div v-bl-input v-for="binding in action.bindings.filter(b => b.type === 0)" :key="binding.id">
						<label>{{ binding.name }}</label>
						<input v-model="binding.value" type="text" />
					</div>
					<div class="bl-input bl-input__value" v-for="binding in action.bindings.filter(b => b.type === 2)" :key="binding.id">
						<label>{{ binding.name }}</label>
						<div style="margin: 7px 0 0 7px; display: flex; align-items: center;"><icon style="margin-right: 5px; font-size: 18px;">input</icon>local.{{ binding.reference }}</div>
					</div>
				</div>
				<div class="outputs" v-if="action.outputs.length">
					<div :class="getOutputClass(output)" v-for="output in action.outputs" :key="output.id" @click.stop="newConnection(output, action)">
						<i v-if="output.icon" class="material-icons">{{ output.icon }}</i>
						<span>{{ output.name }}</span>
					</div>
				</div>
			</div>
			<div v-if="deleteConnectionButton && !connectionMode" :style="{top: deleteConnectionButton.top + 'px', left: deleteConnectionButton.left + 'px', opacity: deleteConnectionButton.hidden ? 0 : 1}" class="deleteConnectionButton" @click="deleteConnection(deleteConnectionButton.connection)" v-bl-tooltip="'Delete'">
				<i class="material-icons">delete</i>
			</div>
			<svg style="min-width: calc(100% - 4px); min-height: calc(100% - 4px);" :style="{width: maxWidth + 'px', height: maxHeight + 'px'}">
				<line @mouseover="showDeleteConnection(connection)" @mouseout="hideDeleteConnection()" v-for="connection in connections" :key="connection.id" :x1="connection.x1" :y1="connection.y1" :x2="connection.x2" :y2="connection.y2" stroke="transparent" stroke-width="30" />
				<line style="pointer-events: none;" v-for="connection in connections" :key="connection.id" :x1="connection.x1" :y1="connection.y1" :x2="connection.x2" :y2="connection.y2" stroke="var(--bl-legend)" stroke-width="2" @click="deleteConnection(connection)" />
				<line v-if="connectionMode" :x1="connectionMode.x1" :y1="connectionMode.y1" :x2="connectionMode.x2" :y2="connectionMode.y2" stroke="var(--bl-legend)" style="opacity: .7;" stroke-width="2" stroke-dasharray="2" />
			</svg>
		</div>
		<div class="bl-card" style="width: 300px; padding: 0; position: initial;">
			<div style="padding: 5px;">
				<div v-bl-input :class="{errored: submitted && !flow.name}">
					<label>Name</label>
					<input type="text" v-model="flow.name" @blur="submitted = true" />
				</div>
				<div v-bl-input style="margin-top: 10px;">
					<label>Permissions</label>
					<input type="text" v-model="flow.permissions" @blur="submitted = true" />
				</div>
				<div v-for="(element, index) in availableElements" :key="element.elementId" class="newElementContainer">
					<h1 v-if="index == 0 || availableElements[index - 1].category != element.category">{{ element.category }}</h1>
					<div class="newElement" v-bl-draggable="{copy: true, model: newElementPosition, dropped: addActionDrop, start: () => newElementPosition.element = element}">
						<b>
							<i class="material-icons">{{ element.type == 1 ? 'sync_alt' : 'pending_actions' }}</i>
							<span>{{ element.label }}</span>
						</b>
						<p>{{ element.comment }}</p>
					</div>
				</div>
				<div style="text-align: center">
					<BlButton label="Save" :disabled="currentRequest" @click="save()" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Router, Dialog, Snackbar, DragDrop, Colors } from 'InterfaceBundle'

export default {
	name: 'BlFlowForm',
	data() {
		return {
			actionRefs: {},
			actions: [],
			currentRequest: false,
			connections: [],
			availableElements: [],
			connectionMode: null,
			container: null,
			maxWidth: 0,
			maxHeight: 0,
			dragNewElementOver: null,
			deleteConnectionButton: null,
			maxContainerWidth: null,
			flow: {},
			newElementPosition: {},
			submitted: false
		}
	},
	methods: {
		showDeleteConnection(connection) {
			this.deleteConnectionButton = {
				left: Math.abs(connection.x1 - connection.x2) / 2 + (connection.x1 > connection.x2 ? connection.x2 : connection.x1),
				top: Math.abs(connection.y1 - connection.y2) / 2 + (connection.y1 > connection.y2 ? connection.y2 : connection.y1),
				connection: connection,
				hidden: false
			}
		},
		hideDeleteConnection() {
			this.deleteConnectionButton.hidden = true
		},
		/**
		 * Handle keyboard shortcuts
		 * @param  {object} event
		 */
		handleKeyboardShortcuts(event) {
			if(event.key == 's' && event.ctrlKey) {
				event.preventDefault()
				event.stopPropagation()
				this.save()
				return false
			}
		},
		/**
		 * Binding element reference for actions to create connection lines
		 * @param {Element} el
		 */
		setActionRef(el) {
			if(el) this.actionRefs[el.getAttribute('data-key')] = el
		},
		/**
		 * Get action output class dependending on context
		 * @param  {Object} output
		 * @return {String}
		 */
		getOutputClass(output) {
			let ret = ['output_' + output.id]
			if(this.connectionMode && this.connectionMode.output == output) ret.push('active')
			return ret
		},
		/**
		 * Callback function when action is moved to recalculate connections
		 */
		actionMoved() {
			Router.preventLeave()
			this.handleConnections()
			this.setMaxWidthHeight()
		},
		/**
		 * Get max width and height of content to adjust svg size
		 */
		setMaxWidthHeight() {
			this.maxWidth = 0
			this.maxHeight = 0
			for(let item of Object.values(this.actionRefs)) {
				let left = item.offsetLeft + item.offsetWidth
				if(left > this.maxWidth) this.maxWidth = left
				let height = item.offsetTop + item.offsetHeight
				if(height > this.maxHeight) this.maxHeight = height
			}
		},
		/**
		 * Recalculate all connections positions
		 */
		handleConnections() {
			for(let connection of this.connections) {
				let toElement = this.actionRefs[connection.toaction]
				connection.x1 = toElement.offsetLeft + (toElement.offsetWidth / 2)
				connection.y1 = toElement.offsetTop

				this.setAxisValues(connection, connection.fromaction, connection.fromproperty)
			}
		},
		/**
		 * Add action from element
		 * @param {Object} element
		 */
		addActionDrop() {
			let position = DragDrop.getPositionRelativeTo(this.newElementPosition, this.$refs.container)
			let action = JSON.parse(JSON.stringify(this.newElementPosition.element))
			action.positionX = position.x
			action.positionY = position.y
			action.color = Colors.get(action.label)
			action.id = 'new_' + this.actions.length
			action.animate = true
			this.actions.push(action)
			Router.preventLeave()
			this.newElementPosition.x = null
			this.newElementPosition.y = null
		},
		/**
		 * Start new connection creation (on output click)
		 * @param  {Object} output action output
		 * @param  {Object} action
		 */
		newConnection(output, action) {
			if(this.connectionMode) this.stopConnection()
			else {
				this.container = this.$refs.container.getBoundingClientRect()
				this.connectionMode = {output: output, action: action}
				this.setAxisValues(this.connectionMode, action.id, output.id)
				this.connectionMode.x1 = this.connectionMode.x2
				this.connectionMode.y1 = this.connectionMode.y2
				window.addEventListener('mousemove', this.handleMouseMoveConnection)
			}
		},
		/**
		 * Finish connection creation (on receiving action click)
		 * @param  {Object} action
		 */
		createConnection(action) {
			let connection = {
				id: 'new_' + this.connections.length,
				fromaction: this.connectionMode.action.id,
				fromproperty: this.connectionMode.output.id,
				toaction: action.id
			}

			//Check if connection exists
			let exists = false
			for(let existing of this.connections) {
				if(existing.fromaction == connection.fromaction && existing.fromproperty == connection.fromproperty && existing.toaction == connection.toaction) exists = true
			}
			if(exists) {
				Dialog.alert({
					title: 'Connection already exists',
					closeButton: false
				})
			}
			else {
				this.connections.push(connection)
				this.handleConnections()
			}
			this.stopConnection()
			Router.preventLeave()
		},
		/**
		 * Stop connection creation
		 */
		stopConnection() {
			if(this.connectionMode) {
				window.removeEventListener('mousemove', this.handleMouseMoveConnection)
				this.connectionMode = null
			}
		},
		/**
		 * Calculate line from (2) position
		 * @param {Object} connection
		 * @param {Object} fromAction
		 * @param {Object} fromProperty
		 */
		setAxisValues(connection, fromAction, fromProperty) {
			this.container = this.$refs.container.getBoundingClientRect()
			let fromElement = this.actionRefs[fromAction].querySelector('.output_' + fromProperty)
			let boudingClient = fromElement.getBoundingClientRect()
			connection.x2 = (boudingClient.left - this.container.left) + this.$refs.container.scrollLeft + (fromElement.offsetWidth / 2)
			connection.y2 = (boudingClient.top - this.container.top) + this.$refs.container.scrollTop + (fromElement.offsetHeight / 2)
		},
		/**
		 * Handle mouse move for new connection creation
		 * @param  {Object} event
		 */
		handleMouseMoveConnection(event) {
			this.connectionMode.x1 = event.x - this.container.left + this.$refs.container.scrollLeft
			this.connectionMode.y1 = event.y - this.container.top + this.$refs.container.scrollTop
		},
		/**
		 * Save flow
		 */
		save() {
			if(!this.flow.name) {
				this.submitted = true
				return
			}
			this.currentRequest = true
			Api.put('flow/' + (Router.params.id ? Router.params.id : ''), {data: {actions: this.actions, connections: this.connections, name: this.flow.name, permissions: this.flow.permissions}}).then(saveResponse => {
				this.actions = []
				if(Router.params.id) {
					this.loadData(resp => {
						this.actions = resp.actions.map(a => {
							a.color = Colors.get(a.label)
							return a
						})
						this.connections = resp.connections
						this.availableElements = resp.availableElements
						this.$nextTick(() => {
							this.handleConnections()
							this.setMaxWidthHeight()
						})
						this.currentRequest = false
						Router.allowLeave()
						Snackbar.open({text: 'Saved'})
					})
				}
				else {
					this.currentRequest = false
					Router.allowLeave()
					Snackbar.open({text: 'Saved'})
					Router.navigate(['internals/flow/form', {id: saveResponse.id}])
				}
			})
		},
		/**
		 * Delete action
		 * @param  {Object} action
		 */
		deleteAction(action) {
			this.connections = this.connections.filter(c => c.fromaction != action.id && c.toaction != action.id)
			this.actions = this.actions.filter(a => a.id != action.id)
			Router.preventLeave()
		},
		/**
		 * Delete connection
		 * @param  {Object} connection
		 */
		deleteConnection(connection) {
			this.deleteConnectionButton = null
			this.connections = this.connections.filter(c => c.id != connection.id)
			Router.preventLeave()
		},
		/**
		 * Load data from API
		 * @param  {Function} cb
		 */
		loadData(cb) {
			let req = {}

			req = {
				availableElements: {
					'loop("element"):model.get("internals.flowelement").findAll()|sort("category")': {
						elementId: 'local.element.id',
						type: null,
						label: 'local.element.name',
						comment: null,
						category: null,
						bindings: {
							'loop("binding"):local.element.properties': {
								'if:local.binding.type != 1': {
									id: null,
									name: null,
									value: 'null',
									type: null,
									reference: null
								}
							}
						},
						outputs: {
							'loop("output"):local.element.properties': {
								'if:local.output.type == 1': {
									id: null,
									name: null,
									icon: null
								}
							}
						}
					}
				}
			}

			if(Router.params.id) {
				req['context("flow"):model.get("internals.flow").findOneById(' + Router.params.id + ')'] = {
					id: null,
					name: null,
					permissions: null,
					connections: {
						'loop("connection"):local.flow.connections': {
							id: null,
							fromaction: 'local.connection.fromaction.id',
							fromproperty: 'local.connection.fromproperty.id',
							toaction: 'local.connection.toaction.id'
						}
					},
					availableElements: req.availableElements,
					actions: {
						'loop("action"):local.flow.actions': {
							id: null,
							type: 'local.action.element.type',
							label: 'local.action.element.name',
							positionX: null,
							positionY: null,
							elementId: 'local.action.element.id',
							outputs: {
								'loop("output"):local.action.element.properties': {
									'if:local.output.type == 1': {
										id: null,
										name: null,
										icon: null
									}
								}
							},
							bindings: {
								'loop("binding"):local.action.element.properties': {
									'if:local.binding.type != 1': {
										id: null,
										name: null,
										value: 'model.get("internals.flowactionbinding").findOneBy({"property": local.binding, "action": local.action}).value',
										type: null,
										reference: null
									}
								}
							}
						}
					}
				}

				delete req.availableElements
			}

			Api.post('api/structure/', req).then(resp => cb(resp))
		},
		getMaxContainerWidth() {
			return this.$el ? (this.$el.offsetWidth - 320) : 0
		}
	},
	created() {
		this.loadData(resp => {
			if(Router.params.id) {
				this.flow = resp
				if(!Router.params.id) {
					this.flow.name = ''
					this.flow.permissions = ''
				}
				this.$emit('flowData', this.flow)
				this.actions = resp.actions.map(a => {
					a.color = Colors.get(a.label)
					return a
				})
				this.connections = resp.connections
			}
			this.availableElements = resp.availableElements
			this.$nextTick(() => {
				this.handleConnections()
				this.setMaxWidthHeight()
			})
			document.addEventListener('keydown', this.handleKeyboardShortcuts)
		})
	},
	mounted() {
		this.maxContainerWidth = this.$el.offsetWidth - 320
	},
	unmounted() {
		this.stopConnection()
		Router.allowLeave()
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
	}
}
</script>

<style scoped lang="scss">
.bl-card {
	position: relative;
	overflow: auto;
}

@keyframes actionPopIn {
	0% {
		transform: scale(.7);
	}
}

.action {
	position: relative;
	transform: scale(1);
	transform-origin: top left;
	z-index: 2;
	border: 1px solid var(--bl-border);
	border-radius: var(--bl-border-radius);
	background: linear-gradient(0deg, var(--bl-surface) 0%, var(--bl-surface) 50%, rgba(0, 0 ,0, 0) 50%, rgba(0, 0, 0, 0) 100%);
	width: 400px;

	h4 {
		font-family: Product sans;
		margin: 0;
		padding: 10px;
		border-bottom: 1px solid var(--bl-border);
		cursor: move;
		display: flex;
		align-items: center;
		position: relative;
		min-height: 22px;
		border-top-right-radius: var(--bl-border-radius);
		border-top-left-radius: var(--bl-border-radius);

		i.material-icons {
			font-size: 18px;
			margin-right: 7px;
			color: var(--action-color);
		}

		span {
			font-weight: bold;
			flex: 1;
		}

		.bl-icon-button {
			margin: -13px;
			display: none;
		}
	}

	h4::before {
		background-color: var(--action-color);
		content: ' ';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		margin-left: -10px;
		opacity: .15;
		border-top-right-radius: var(--bl-border-radius);
		border-top-left-radius: var(--bl-border-radius);
	}

	h4:hover .bl-icon-button {
		display: block;
	}

	.connectionHook {
		width: 30px;
		height: 30px;
		left: calc(50% - 15px);
		border-radius: 50%;
		background-color: var(--bl-primary);
		margin: auto;
		margin-top: -15px;
		margin-bottom: -15px;
		color: var(--bl-on-primary);
		text-align: center;
		line-height: 30px;
		font-size: 18px;
		display: block;
		position: absolute;
		transition: all .2s;
		cursor: pointer;
		z-index: 2;
	}

	.connectionHook:hover {
		transform: scale(1.2);
	}

	.bindings {
		padding: 10px 15px 15px 15px;
		background-color: var(--bl-surface);
		border-bottom-right-radius: var(--bl-border-radius);
		border-bottom-left-radius: var(--bl-border-radius);
	}

	.bindings > * {
		margin-top: 5px;
	}

	.outputs {
		display: flex;
		margin-bottom: -15px;
		justify-content: space-around;

		div {
			cursor: pointer;
			display: flex;
			border: 1px solid var(--bl-border);
			border-radius: var(--bl-border-radius);
			padding: 5px 10px;
			background-color: var(--bl-surface);
			font-weight: bold;
			align-items: center;

			i.material-icons {
				font-size: 16px;
				margin-right: 6px;
			}
		}

		div.active {
			background-color: var(--bl-secondary);
			color: var(--bl-on-secondary);
		}
	}
}

.action.animate {
	animation: actionPopIn .1s;
}

.newElementContainer {

	h1 {
		font-size: 14px;
		text-align: center;
	}

	.newElement {
		z-index: 4;
		min-width: 278px;
		max-width: 278px;
		background-color: var(--bl-surface);
		border: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);
		padding: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		b {
			display: flex;
			align-items: center;
			font-weight: normal;

			i.material-icons {
				font-size: 14px;
				margin-right: 5px;
			}

			span {
				line-height: 14px;
			}
		}

		p {
			margin: 5px 0 0 0;
			padding: 0;
			color: var(--bl-legend);
			white-space: pre-wrap;
			font-size: 12px;
		}
	}
}

.deleteConnectionButton {
	background-color: var(--bl-surface);
	border: 1px solid var(--bl-border);
	border-radius: 50%;
	position: absolute;
	z-index: 50;
	width: 30px;
	height: 30px;
	text-align: center;
	cursor: pointer;
	margin-top: -15px;
	margin-left: -15px;

	i.material-icons {
		line-height: 30px;
	}
}

.deleteConnectionButton:hover {
	opacity: 1 !important;
}
</style>