<template>
	<div v-for="group in items" :key="group.title" :style="{display: isGranted(group.items) ? null : 'none'}">
		<h5>{{ $t(group.title) }}</h5>
		<div class="action" v-for="item in group.items" :key="item.label" v-link="item.link" v-blMainMenuActive="item.activeRegex ? {regex: item.activeRegex} : null">
			<i class="material-icons">{{ item.icon }}</i>
			<span>{{ $t(item.label) }}</span>
		</div>
	</div>
</template>

<script>

import { Router } from 'InterfaceBundle'

export default {
	name: 'BlMainMenuItems',
	inject: ['blMainMenuItems'],
	methods: {
		isGranted(items) {
			for(let item of items) {
				if(Router.canAccess(item.link[0])) return true
			}
			return false
		}
	},
	data() {
		return {
			items: []
		}
	},
	created() {
		for(let title in this.blMainMenuItems().items) {
			const item = {title: title, items: this.blMainMenuItems().items[title]}
			if(item.title.length) this.items.push(item)
			else this.items.unshift(item)
		}
		this.items = Object.values(this.items)
	}
}
</script>

<style scoped lang="scss">
</style>