import { ViewServices } from './ViewServices.js'

/**
 * Event emitter class
 */
export class EventEmitter {
	constructor() {
		this.subscriptions = []
		this.subscriptionsOnce = []
	}

	/**
	 * Subscribe once
	 * @param  {function} sub
	 */
	once(sub) {
		this.subscriptionsOnce.push(sub)
		return {unsubscribe: () => this.unsubscribe(sub)}
	}

	/**
	 * Subscribe to event
	 * @param  {function} sub
	 */
	subscribe(sub) {
		this.subscriptions.push(sub)
		return {unsubscribe: () => this.unsubscribe(sub)}
	}

	/**
	 * Unsubscribe to event
	 * @param  {function} sub
	 */
	unsubscribe(sub) {
		this.subscriptions = this.subscriptions.filter(s => s !== sub)
		this.subscriptionsOnce = this.subscriptionsOnce.filter(s => s !== sub)
	}

	/**
	 * Emit event
	 * @param  {any} event
	 */
	emit(event = null) {
		for(let sub of this.subscriptions) sub(event)
		for(let sub of this.subscriptionsOnce) sub(event)
		this.subscriptionsOnce = []
	}

	/**
	 * Remove all subscriptions
	 */
	clear() {
		this.subscriptionsOnce = []
		this.subscriptions = []
	}

	/**
	 * Return true if there is no subscriptions
	 */
	isEmpty() {
		return !this.subscriptionsOnce.length && !this.subscriptions.length
	}
}

ViewServices.EventEmitter = () => new EventEmitter()