<template>
	<div class="blSelectContainer blSelectContainerSingle" ref="blSelectContainer" v-if="!isMultiple" :class="{errored: errored, opened: showList, autocomplete: isAutocomplete, empty: emptyResults(), disabled: getDisabled()}">
		<div class="bl-input __suffix" :class="{'bl-input__value': valueLabel || search}">
			<label @click="$refs.searchInput.focus()" :style="{visibility: dropdownPosition == 'top' && showList ? 'hidden' : null}">{{ label }}</label>
			<span @click="$refs.searchInput.focus()" class="modelValue" v-if="!search && !isAutocomplete" v-html="valueLabel"></span>
			<input type="text" :class="{dropDownOpened: showList, top: dropdownPosition == 'top'}" v-model="search" ref="searchInput" @focus="openList()" @keydown="handleKeydown($event)" :disabled="getDisabled()" autoCapitalize="none" v-blMobileInput />
			<span class="suffix material-icons" @click="$refs.searchInput.focus()">arrow_drop_down</span>
		</div>
		<div v-if="showList && loading" class="bl-loader-line" :class="dropdownPosition"></div>
		<div v-if="showList" ref="dropDown">
			<RecycleScroller ref="recycleScroller" class="dropDown bl-light-scroll" :items="arrayData" :item-size="itemSize" key-field="key" v-slot="{ item }" :class="{top: dropdownPosition == 'top'}" :style="{visibility: arrayData.length ? null : 'hidden'}">
				<li :class="{active: isActive(item.value), hovered: item.value == hoveredValue, separator: item.separator}" @click="setValue(item.value)" @mouseover="hoveredValue = item.value" v-html="item.label"></li>
			</RecycleScroller>
			<li @click="$refs.searchInput.focus()" v-if="emptyResults() && !isAutocomplete" class="emptyDataSet">{{ $t(loading ? 'interface.selectLoading' : 'interface.selectNoResult') }}</li>
		</div>
	</div>
	<div class="blSelectContainer blSelectContainerMultiple" ref="blSelectContainer" v-if="isMultiple" :class="{errored: errored, disabled: getDisabled()}">
		<div class="bl-input __suffix" :class="{'bl-input__value': valueLabel || search}" v-if="isCombo">
			<label @click="$refs.searchInput.focus()" :style="{visibility: dropdownPosition == 'top' && showList ? 'hidden' : null}">{{ label }}</label>
			<span @click="$refs.searchInput.focus()" class="modelValue" v-if="!search && !isAutocomplete" v-html="valueLabel"></span>
			<input type="text" :class="{dropDownOpened: showList, top: dropdownPosition == 'top'}" v-model="search" ref="searchInput" @focus="openList()" @keydown="handleKeydown($event)" :disabled="getDisabled()" autoCapitalize="none" v-blMobileInput />
			<span class="suffix material-icons" @click="$refs.searchInput.focus()">arrow_drop_down</span>
		</div>
		<div class="bl-input-multiple" :class="{'bl-input__value': search || modelValue.length || showList, 'bl-input__active': showList}" v-else>
			<label @click="$refs.searchInput.focus()" :style="{visibility: dropdownPosition == 'top' && showList ? 'hidden' : null}">{{ label }}</label>
			<div :class="{dropDownOpened: showList, top: dropdownPosition == 'top'}" class="bl-input-multiple-content">
				<div v-for="(item, index) in modelValue" :key="item" class="bl-formchip">
					<i class="material-icons" @click="removeValue(index)">close</i>
					<span v-html="getLabel(item)"></span>
				</div>
				<input type="text" v-model="search" ref="searchInput" @focus="openList()" @click="openList()" @keydown="handleKeydown($event)" :style="{paddingLeft: modelValue.length ? null : '8px'}" :disabled="getDisabled()" autoCapitalize="none" v-blMobileInput />
				<span class="suffix material-icons" @click="$refs.searchInput.focus()">arrow_drop_down</span>
			</div>
		</div>
		<div v-if="showList" ref="dropDown">
			<div v-if="loading" class="bl-loader-line" style="position: absolute; z-index: 101;"></div>
			<RecycleScroller ref="recycleScroller" class="dropDown bl-light-scroll" :items="arrayData" :item-size="itemSize" key-field="key" v-slot="{ item }" :class="{top: dropdownPosition == 'top'}" :style="{visibility: arrayData.length ? null : 'hidden'}">
				<li :class="{active: isActive(item.value), hovered: item.value == hoveredValue, separator: item.separator}" @click="setValue(item.value)" @mouseover="hoveredValue = item.value" :title="item.value ? item.label : ''">
					<span v-html="item.label"></span>
					<template v-if="isCombo && item.value">
						<span style="flex: 1;"></span>
						<input type="checkbox" class="bl-checkbox" @click="setComboMode(item.value); $event.stopPropagation()" :checked="comboMode && isActive(item.value)" />
					</template>
				</li>
			</RecycleScroller>
			<li @click="setValue(search)" v-if="emptyResults() && isAutocomplete && !loading" class="emptyDataSet">{{ search }}</li>
			<li @click="$refs.searchInput.focus()" v-if="emptyResults() && !isAutocomplete" class="emptyDataSet">{{ $t(loading ? 'interface.selectLoading' : 'interface.selectNoResult') }}</li>
		</div>
	</div>
</template>

<script>
import { Variables, Dialog } from 'InterfaceBundle'

export default {
	name: 'BlSelect',
	props: ['data', 'label', 'modelValue', 'multiple', 'combo', 'errored', 'autocomplete', 'specialChoices', 'disabled', 'loading', 'preferredChoices'],
	emits: ['update:modelValue', 'change', 'blur', 'focus', 'searchChange'],
	data() {
		return {
			isMultiple: false,
			isCombo: false,
			comboMode: false,
			search: '',
			lastSearch: '',
			valueLabel: null,
			showList: false,
			hoveredValue: undefined,
			arrayData: [],
			itemSize: 38,
			isAutocomplete: null,
			dropdownPosition: 'bottom'
		}
	},
	watch: {
		modelValue(newVal) {
			this.setValueLabel(newVal)
			if(this.isAutocomplete && !this.isMultiple) this.search = newVal
		},
		data() {
			this.setArrayData()
			//Check if current value exists in data list
			if(this.modelValue && !this.getDataValues().includes(this.modelValue) && !this.isAutocomplete && !this.isMultiple) this.setValue(null, false)
			this.setValueLabel(this.modelValue)
			this.setHoveredValue()
			this.setDropdownPosition()
			if(this.search) this.filterData()
		},
		search() {
			this.filterData()
			this.$emit('searchChange', this.search)
		}
	},
	methods: {
		getDataValues() {
			return this.data instanceof Map ? Array.from(this.data.values()) : Object.values(this.data)
		},
		getDataKeys() {
			return this.data instanceof Map ? Array.from(this.data.keys()) : Object.keys(this.data)
		},
		getDataByKey(key) {
			return this.data instanceof Map ? this.data.get(key) : this.data[key]
		},
		getPreferredChoices() {
			return this.preferredChoices && this.preferredChoices.length ? this.preferredChoices : null;
		},
		setArrayData() {
			this.arrayData = []
			for(let label of this.getDataKeys()) {
				if(label.includes('"bl-select-medium-value"')) this.itemSize = 50
				let value = this.getDataByKey(label)
				this.arrayData.push({value: value, label: label, key: value !== null ? value : '__null'})
			}
			if(this.getPreferredChoices()) {
				let preferredChoices = []
				let otherChoices = []
				for(let item of this.arrayData) {
					const indexOfPF = this.getPreferredChoices().indexOf(item.value)
					if(indexOfPF !== -1) {
						item._pcindex = indexOfPF
						preferredChoices.push(item)
					}
					else otherChoices.push(item)
				}
				preferredChoices.sort((a, b) => a._pcindex > b._pcindex ? 1 : -1)
				if(otherChoices.length) otherChoices[0].separator = true
				this.arrayData = preferredChoices.concat(otherChoices)
			}
			this.handleSpecialChoices()
		},
		handleSpecialChoices() {
			if(this.specialChoices && this.search) this.arrayData = this.arrayData.filter(c => !c.__s).concat(this.specialChoices.map(c => {
				if(!c.__s) c.__s = c.label
				if(!c.key) c.key = '__null'
				c.label = c.__s.replace('%s', this.search)
				return c
			}))
			if(this.isAutocomplete && this.search && Variables.mobile && !this.getDataValues().includes(this.search)) this.arrayData = [{label: this.search, value: this.search, key: this.search}, ...this.arrayData]
		},
		setValue(value, closeList = true, forceMultiple = false) {
			if(!forceMultiple && this.comboMode) this.comboMode = false
			if(Variables.mobile && closeList) this.closeMobileDialog()
			const lastHoveredValue = this.hoveredValue
			if(typeof value === 'function') {
				value(this.search)
				if(closeList) this.closeList()
				return
			}
			if(typeof value === 'object') value = null
			if(this.isMultiple) {
				if(!this.comboMode && this.isCombo) value = value || value === 0 ? [value] : []
				else if(this.isAutocomplete) value = (this.modelValue && Array.isArray(this.modelValue) ? this.modelValue : []).concat(value ? [value] : [this.search])
				else if(value && this.modelValue && Array.isArray(this.modelValue) && this.modelValue.includes(value)) value = this.modelValue.filter(v => v !== value)
				else value = this.modelValue.concat(value || value === 0 ? [value] : [])

				this.search = ''
				this.lastSearch = ''
				closeList = !forceMultiple && this.isCombo
				if(!closeList) this.$refs.searchInput.focus()
				//Restore scroll
				const scrollTop = this.$refs.recycleScroller?.getScroll().start
				setTimeout(() => {
					if(this.$refs.recycleScroller) this.$refs.recycleScroller.scrollToPosition(scrollTop)
					this.hoveredValue = lastHoveredValue
				})
				this.$nextTick(() => this.setDropdownPosition())
			}
			else if(this.isAutocomplete) {
				for(let label of this.getDataKeys()) {
					if(this.getDataByKey(label) == value) {
						this.search = label
						break
					}
				}
			}
			else {
				this.setValueLabel(value)
			}
			this.$emit('update:modelValue', value)
			if(closeList) this.closeList()
			this.$emit('change')
		},
		setComboMode(value) {
			if(!this.comboMode) {
				if(this.modelValue?.length) this.$emit('update:modelValue', [])
				this.comboMode = true
			}
			this.$nextTick(() => this.setValue(value, true, true))
		},
		getLabel(value) {
			for(let key of this.getDataKeys()) {
				if(this.getDataByKey(key) == value) return key
			}
			if(this.isAutocomplete) return value
		},
		removeValue(index) {
			let modelValue = this.modelValue
			modelValue.splice(index, 1)
			this.$emit('update:modelValue', modelValue)
			this.$emit('change')
			this.setDropdownPosition()
		},
		isActive(value) {
			if(this.isMultiple && this.isAutocomplete) return false
			else if(this.isMultiple) return this.modelValue && Array.isArray(this.modelValue) && this.modelValue.includes(value)
			else return this.modelValue === value
		},
		closeList() {
			//Autoselect on leave field on mobile
			if(Variables.mobile && this.showList && this.hoveredValue && !this.isMultiple) this.setValue(this.hoveredValue, false)

			this.$emit('blur')
			this.showList = false
			document.removeEventListener('click', this.handleBodyClick)
			document.removeEventListener('focusin', this.handleBodyClick)
			window.removeEventListener('blur', this.handleVisibilityChange)
			this.resetSearch()

			if(this.recycleScrollerElement && this.recycleScrollerContainer) {
				this.recycleScrollerContainer.appendChild(this.recycleScrollerElement)
			}
			this.$nextTick(() => {
				if(this.recycleScrollerElement && this.recycleScrollerElement.parentNode == document.body) document.body.removeChild(this.recycleScrollerElement)
			})
		},
		openList() {
			if(Variables.mobile) return this.mobileList()
			if(this.showList) return
			this.showList = true
			this.$emit('focus')
			this.setHoveredValue()
			document.addEventListener('click', this.handleBodyClick)
			document.addEventListener('focusin', this.handleBodyClick)
			window.addEventListener('blur', this.handleVisibilityChange)
			this.setDropdownPosition()
		},
		setDropdownPosition() {
			if(!this.showList) return
			this.$nextTick(() => {
				const boundingClient = this.$refs.dropDown.getBoundingClientRect()
				const maxPoint = boundingClient.top + document.documentElement.scrollTop + Math.min(300, this.getDataValues().length * 35 + 20)
				let maxHeight = window.innerHeight
				if(Variables.mobile) maxHeight -= 73
				this.dropdownPosition = maxPoint > maxHeight ? 'top' : 'bottom'
				const scroller = this.$refs.recycleScroller.$el
				this.$nextTick(() => {
					this.recycleScrollerElement = scroller
					this.recycleScrollerContainer = scroller.parentNode
					if(scroller.parentNode != document.body) {
						document.body.appendChild(scroller)
						scroller.style.position = 'absolute'
					}
					scroller.style.left = boundingClient.x + 'px'
					if(this.dropdownPosition == 'bottom') {
						scroller.style.top = boundingClient.y + 'px'
						scroller.style.bottom = null
					}
					else {
						scroller.style.bottom = window.innerHeight - this.$refs.blSelectContainer.getBoundingClientRect().top - (this.multiple ? 0 : 5) + 'px'
						scroller.style.top = null
					}
					scroller.style.width = boundingClient.width + 'px'
				})
			})
		},
		setHoveredValue(forceValue = undefined) {
			if(this.showList) {
				if(forceValue !== undefined) this.hoveredValue = forceValue
				else if(this.getPreferredChoices()) this.hoveredValue = this.getPreferredChoices()[0]
				else this.hoveredValue = this.modelValue && !Array.isArray(this.modelValue) && this.getDataValues().includes(this.modelValue) ? this.modelValue : this.getDataValues()[0]
				let index = 0
				for(let key in this.arrayData) {
					if(this.arrayData[key].value == this.hoveredValue) {
						index = key
						break
					}
				}
				index -= 3
				if(index < 0) index = 0
				setTimeout(() => {
					if(this.$refs.recycleScroller) this.$refs.recycleScroller.scrollToItem(index)
				})
			}
		},
		setValueLabel(value) {
			if(this.isCombo) {
				this.valueLabel = []
				for(let label of this.getDataKeys()) {
					if(value && value.includes(this.getDataByKey(label))) this.valueLabel.push(label)
				}
				this.valueLabel = this.valueLabel.join(', ')
			}
			else {
				this.valueLabel = null
				for(let label of this.getDataKeys()) {
					if(this.getDataByKey(label) == value) {
						this.valueLabel = label
						break
					}
				}
			}
		},
		handleVisibilityChange() {
			if(this.showList) {
				this.closeList()
				if(this.isAutocomplete && !this.isMultiple) this.setValue(this.search, false)
			}
		},
		handleBodyClick(event) {
			if(event.type == 'focusin' && event.target.tagName == 'DIALOG') return//Fix issue for click value in dialog
			if(this.$refs.blSelectContainer && !this.$refs.blSelectContainer.contains(event.target) && this.$refs.blSelectContainer != event.target && !event.target.classList.contains('emptyDataSet') && (!this.recycleScrollerElement || (event.target != this.recycleScrollerElement && !this.recycleScrollerElement.contains(event.target)))) {
				event.preventDefault()
				event.stopPropagation()
				this.closeList()
				if(this.isAutocomplete && !this.isMultiple) this.setValue(this.search, false)
				return false
			}
		},
		handleKeydown(event) {
			if(event.key == 'ArrowDown') this.hoverChange(event, 1)
			else if(event.key == 'ArrowUp') this.hoverChange(event, -1)
			else if(event.key == 'Enter') {
				event.preventDefault()
				event.stopPropagation()
				if(Variables.mobile) {
					if(this.arrayData.length) this.setValue(this.arrayData[0].value)
				}
				else if(this.showList) this.setValue(this.hoveredValue)
				else this.openList()
				return false
			}
			else if(event.key == 'Backspace' && this.isMultiple && this.modelValue && this.modelValue.length && !this.search.length) {
				this.removeValue(this.modelValue.length - 1)
			}
		},
		hoverChange(event, way) {
			if(!this.showList) this.openList()
			event.preventDefault()
			event.stopPropagation()

			let dataKeys = this.arrayData.map(i => i.value)
			if(this.hoveredValue === undefined) this.setHoveredValue(dataKeys[0])
			else {
				let hoveredValueIndex = null;
				for(let index in dataKeys) {
					if(this.hoveredValue == dataKeys[index]) {
						hoveredValueIndex = index
						break
					}
				}
				hoveredValueIndex = parseInt(hoveredValueIndex) + way
				if(hoveredValueIndex < 0) hoveredValueIndex = dataKeys.length - 1
				else if(hoveredValueIndex == dataKeys.length) hoveredValueIndex = 0
				this.setHoveredValue(dataKeys[hoveredValueIndex])
			}
		},
		filterData() {
			if(this.search) {
				if(!this.showList && !this.isAutocomplete) this.openList()
				let search = this.unaccent(this.search)
				if(this.lastSearch && search.substr(0, this.lastSearch.length) == this.lastSearch) {
					this.arrayData = this.arrayData.filter(item => this.unaccent(item.label).includes(search) || item.__s)
				}
				else {
					this.arrayData = []
					for(let label of this.getDataKeys()) {
						if(this.unaccent(label).includes(search)) {
							let value = this.getDataByKey(label)
							this.arrayData.push({value: value, label: label, key: value !== null ? value : '__null'})
						}
					}
				}
				this.handleSpecialChoices()
				this.setHoveredValue(this.arrayData.length ? this.arrayData[0].value : null)
				this.lastSearch = this.search
			}
			else {
				this.resetSearch(true)
				this.setHoveredValue()
			}
		},
		emptyResults() {
			return this.arrayData.length == 0
		},
		resetSearch(force = false) {
			if(!this.isAutocomplete || force) {
				this.setArrayData()
				this.search = ''
			}
		},
		unaccent(string) {
			return (string + '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
		},
		handleKeyboardShortcuts(event) {
			if(event.key == 'Escape' && this.showList) this.closeList()
		},
		getDisabled() {
			return this.disabled === true
		},
		mobileList() {
			if(this.hasMobileDialog) return
			this.$emit('focus')
			document.activeElement.blur()
			Dialog.custom({
				component: 'BlSelectMobile',
				componentProps: {s: this},
				fullScreen: true,
				closeButton: false,
				class: 'bl-select-mobile-dialog-container'
			}).catch(() => this.hasMobileDialog = false)
			this.hasMobileDialog = true
		},
		closeMobileDialog() {
			Dialog.close(false)
			this.hasMobileDialog = false
		}
	},
	created() {
		if(Variables.mobile) this.itemSize = 42
		this.isAutocomplete = this.autocomplete ? true : false
		this.setArrayData()
		if(this.multiple || this.combo) this.isMultiple = true
		if(this.combo) {
			this.isCombo = true
			if(this.modelValue && this.modelValue.length > 1) this.comboMode = true
		}
		this.setValueLabel(this.modelValue)
		document.addEventListener('keydown', this.handleKeyboardShortcuts)
		if(this.isAutocomplete && !this.isMultiple) this.search = this.modelValue
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
		this.closeList()
	}
}
</script>

<style scoped lang="scss">
.blSelectContainerSingle.errored:not(.opened) {
	input {
		box-shadow: 0 0 0 2px var(--bl-error);
	}

	label {
		color: var(--bl-error);
	}
}

.blSelectContainerMultiple.errored {
	label {
		color: var(--bl-error);
	}

	.bl-input-multiple-content {
		box-shadow: 0 0 0 2px var(--bl-error);
	}
}

label {
	color: var(--bl-legend);
}

.blSelectContainer {
	position: relative;
}

div.bl-input {
	width: calc(100% - 16px);
}

div.bl-input-multiple div.bl-input-multiple-content span.suffix {
	margin-top: 4px;
	margin-bottom: -1px;
	visibility: visible;
	cursor: pointer;
	margin-left: -25px;
}

.dropDown {
	animation: bl-select-dropdown-in 0.08s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	z-index: 100;
	background-color: var(--bl-surface);
	border: 2px solid var(--bl-primary);
	border-top: 1px solid var(--bl-border);
	border-bottom-left-radius: var(--bl-border-radius);
	border-bottom-right-radius: var(--bl-border-radius);
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;
	max-height: 300px;
	overflow: auto;
	margin-left: -2px;

	li {
		padding: 10px 8px;
		cursor: pointer;
		transition: background-color .2s;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: 18px;
		user-select: none;
		display: flex;
		align-items: center;

		> span {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		input.bl-checkbox {
			float: right;
			margin-top: -2px;
			margin-right: 6px;
		}
	}

	li:hover, li.hovered {
		background-color: var(--bl-background);
	}

	li.active {
		color: var(--bl-primary);
	}

	li.separator {
		border-top: 1px solid var(--bl-border);
	}
}

.dropDown.top {
	animation: bl-select-dropdown-in-top 0.08s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	border-top-left-radius: var(--bl-border-radius);
	border-top-right-radius: var(--bl-border-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border: 2px solid var(--bl-primary);
	border-bottom: 1px solid var(--bl-border);
	bottom: 33px;
}

.blSelectContainerMultiple .dropDown.top {
	bottom: 33px;
}

input.dropDownOpened {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

input.dropDownOpened.top {
	border-bottom-right-radius: var(--bl-border-radius);
	border-bottom-left-radius: var(--bl-border-radius);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.bl-input-multiple div.dropDownOpened.top {
	border-bottom-right-radius: var(--bl-border-radius);
	border-bottom-left-radius: var(--bl-border-radius);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

@keyframes bl-select-dropdown-in {
	0% {
		transform: scaleY(0.5);
		transform-origin: 50% 0%;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 50% 0%;
	}
}

@keyframes bl-select-dropdown-in-top {
	0% {
		transform: scaleY(.5);
		transform-origin: 50% 100%;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 50% 100%;
	}
}

span.modelValue {
	display: block;
	position: absolute;
	margin: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100% - 33px);
}

.emptyDataSet {
	z-index: 100;
	background-color: var(--bl-surface);
	border: 2px solid var(--bl-primary);
	border-top: 1px solid var(--bl-border);
	border-bottom-left-radius: var(--bl-border-radius);
	border-bottom-right-radius: var(--bl-border-radius);
	list-style-type: none;
	color: var(--bl-legend);
	font-style: italic;
	cursor: default;
	user-select: none;
	width: calc(100% - 16px);
	position: absolute;
	padding: 10px 8px;
	margin-left: -2px;
	margin-top: -4px;
}

.blSelectContainer.autocomplete.empty .bl-input input {
	border-bottom-right-radius: var(--bl-border-radius);
	border-bottom-left-radius: var(--bl-border-radius);
}

.blSelectContainer.autocomplete.empty > div:nth-child(2) {
	display: none;
}

.blSelectContainer.disabled .suffix.material-icons {
	visibility: hidden;
}

div.bl-input .suffix.material-icons {
	visibility: visible;
	cursor: pointer;
}

.vue-recycle-scroller {
	z-index: 100000;
}

.bl-loader-line {
	margin-top: -2px;
	position: absolute;
}

.bl-loader-line.top {
	margin-top: 0;
	top: 4px;
}
</style>
