<template>
	<div style="position: relative;">
		<BlSelect :data='data' v-model="value" @change="setFilter()" />
		<span v-if="value" class="suffix material-icons clear" @click="clear()" style="visibility: visible; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
export default {
	name: 'BlListFilterSelectChoiceList',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: null,
			data: {}
		}
	},
	created() {
		this.value = this.initialValue
		for(let index in this.metadata.field.options.choices) {
			let label = this.metadata.field.options.choices[index]
			if(this.metadata.field.options.translateChoices) label = this.$t(label)
			this.data[label] = index
		}
	},
	methods: {
		setFilter() {
			if(this.value) this.$emit('blListFilterChange', [[this.metadata.field.name, '=', this.value], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = null
			this.setFilter()
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
		cursor: pointer;
	}
</style>