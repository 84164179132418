import { Realtime, Dialog } from 'InterfaceBundle'

/**
 * Two factor authentication
 */
export const TwoFA = {
	initialize() {
		//Flow element
		Realtime.listen('authTwoFA').then(data => {
			let restoreFlow = data._restoreFlow
			delete data._restoreFlow
			Dialog.custom({
				component: 'AuthTwoFAFlowContainer',
				componentProps: data,
				required: true,
				class: 'authTwoFAFlowContainer'
			}).then(() => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {success: true, error: false}})
			}).catch(() => {
				Realtime.emit('__flow_restoreState', {stateId: restoreFlow, data: {success: false, error: true}})
			})
		})
	}
}

setTimeout(() => TwoFA.initialize())