<template>
	<div v-if="getField() && field.type != 'collection'" :class="{'bl-form-field-container': field.type != 'hidden'}">
		<component :is="field.component" :field="field" :tabindex="tabindex" ref="formField"><slot></slot></component>
		<ul class="messages" v-if="field.messages.length">
			<li v-for="message in field.messages" :key="message">{{ message }}</li>
		</ul>
	</div>
	<component v-if="getField() && field.type == 'collection'" :is="field.component" :field="field" ref="formField">
		<template v-slot:default="slotProps"><slot v-bind="slotProps"></slot></template>
	</component>
	<p class="tip" v-if="getField()"><slot name="tip"></slot></p>
</template>

<script>
export default {
	name: 'BlFormField',
	props: ['name', 'tabindex'],
	data() {
		return {
			field: null
		}
	},
	inject: ['blForm', 'blFormReady', 'blFormLiveReloaded'],
	created() {
		if(this.blFormReady === true) this.setField()
		else this.blFormReady.once(() => this.setField())
		this.liveReloadSub = this.blFormLiveReloaded.subscribe(() => this.setField())
	},
	mounted() {
		if(this.$el.parentElement.classList.contains('bl-card') && !this.$el.parentElement.classList.contains('bl-form-card')) this.$el.parentElement.classList.add('bl-form-card')
	},
	unmounted() {
		this.liveReloadSub.unsubscribe()
		if(this.field?.emitter) this.field.emitter.change.unsubscribe(this.fieldChangeEvent)
	},
	methods: {
		getField() {
			return this.field && this.field.show()
		},
		setField() {
			if(!this.field) {
				this.field = this.blForm.getChild(this.name)
				if(!this.field) return
				this.fieldChangeEvent = () => this.$emit('change', this.field)
				this.field.emitter.change.subscribe(this.fieldChangeEvent)
			}
		}
	}
}
</script>

<style scoped lang="scss">
ul.messages {
	list-style: none;
	margin: 0;
	padding: 0 0 0 8px;

	li {
		color: var(--bl-error);
		font-size: 12px;
	}
}

div.bl-form-field-container {
	margin-bottom: 10px;
}

p.tip {
	color: var(--bl-legend);
	font-size: 12px;
	margin: -5px 0 12px 0;
	padding: 0 5px;
}

p.tip:empty {
	display: none;
}
</style>