import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Components
 */
import BlFormFieldMailrecipient from './components/BlFormFieldMailrecipient.vue'
import BlMailWindow from './components/BlMailWindow.vue'

Registry.components(BlFormFieldMailrecipient, BlMailWindow)

/**
 * Directives
 */

/**
 * Services
 */
import { MailBridge as MailBridgeService } from './services/MailBridge.js'

export const MailBridge = MailBridgeService